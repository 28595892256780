import { alpha } from '@material-ui/core/styles'

export const theme = {
	mode: 'light' as 'light' | 'dark',
	primary: {
		main: '#7D6EF1',
		second: '#8C92F7',
		third: '#A1A6F7',
		alternative: alpha('#7D6EF1', 0.1)
	},
	secondary: {
		orange: '#FFA263',
		darkBlue: '#484CDB',
		ligherBlue: '#4D77EE',
		darkPurple: '#262358'
	},
	grey: {
		main: '#8D9091',
		second: '#CCCCCC',
		third: '#DEDEDE',
		fourth: '#EFEFEF',
		fifth: '#FBFBFB'
	},
	text: {
		main: '#323B4B',
		description: '#5E6C84',
		link: '#4A90E2'
	},
	tag: {
		purple: '#BE52F2',
		darkerBlue: '#6979F8',
		blue: '#0084F4',
		red: '#FF647C',
		yellow: '#FFCF5C',
		green: '#00C48C',
		black: '#000',
		white: '#FFF',
		orange: '#FFA263'
	},
	shadow: {
		first: '0 3px 10px rgba(0, 0, 0, 0.04)',
		second: '0 7px 60px rgba(0, 0, 0, 0.12)',
		third: '0 2px 5px rgba(112, 144, 176, 0.1)'
	},
	border: '#E9ECEE',
	background: {
		main: '#F8F9FA',
		light: '#FFF',
		calm: '#F9FAFF',
		plan: '#FAF1E1',
		summary: '#c5c4c4'
	},
	get progressBarBg() {
		return alpha((this as Theme).grey.main, 0.2)
	},
	social: {
		background: 'transparent'
	}
}

export type Theme = typeof theme

export const darkTheme: Theme = {
	...theme,
	mode: 'dark',
	grey: {
		main: '#8D9091',
		second: '#5A5A5A',
		third: '#3D3D3D',
		fourth: '#3E3D3D',
		fifth: '#333'
	},
	text: {
		...theme.text,
		main: '#FFF',
		description: '#EEEDED'
	},
	border: '#3e3e3e',
	background: {
		main: '#171717',
		light: '#222222',
		calm: '#252525',
		plan: '#3D3D3D',
		summary: '#505050'
	},
	social: {
		background: '#3E3E3E'
	}
}

declare module 'styled-components' {
	// eslint-disable-next-line
	interface DefaultTheme extends Theme {}
}
