import styled, { css } from 'styled-components'

export const Container = styled.div.withConfig<{ overflowText?: boolean }>({
	shouldForwardProp: prop => !['overflowText'].includes(prop)
})`
	${props =>
		props.overflowText &&
		css`
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		`}
`
