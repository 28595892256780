import React from 'react'
import { Container, Grid } from '@material-ui/core'
import ExamListTimeLine from './ExamListTimeLine/ExamListTimeLine'
import ExamsFilter from './ExamsFilter/ExamsFilter'

const Monitor = () => {
	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} lg={6}>
					<ExamListTimeLine />
				</Grid>

				<Grid item xs={12} lg={6}>
					<ExamsFilter />
				</Grid>
			</Grid>
		</>
	)
}

export default Monitor
