import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'

export const StyledButton = styled(Button)`
	height: 48px;
	text-align: center;
	background-color: ${props => props.theme.social.background};
	color: ${props => props.theme.text.main};
	font-size: ${pxToRem(14)};
	border: 0;
	box-shadow: 0 0 0 1px ${props => props.theme.secondary.darkPurple};
	border-radius: 4px;
	font-weight: 400 !important;

	&:hover {
		color: ${props => props.theme.tag.white};
		background: ${props => props.theme.secondary.darkPurple} !important;
	}
`
