import styled from 'styled-components'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'

export const StepIconContainer = styled.div`
	display: flex;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	border: 1px solid ${props => props.theme.grey.fourth};
	color: ${props => props.theme.grey.fourth};
	align-items: center;
	font-weight: 700;
	justify-content: center;
	font-size: ${pxToRem(16)};
	&.active {
		border: none;
		background-color: ${props => props.theme.primary.main};
		color: white;
	}
	.MuiSvgIcon-root {
		font-size: ${pxToRem(24)} !important;
	}
`
