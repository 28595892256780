export const enLocales = {
	switchTheme: 'Switch theme',
	next: 'Next',
	total: 'Total',
	stepOf: 'STEP {current} of {length}',
	industryBench: 'Industry Bench',
	industryComingSoon: 'Industry average coming soon',
	employees: 'employees',
	slogan: 'Trusted and loved by global teams',
	'estimated.time': 'Estimated time',
	'changePassword.title': 'Change password',
	'auth.createAccount': 'Create account',
	'auth.createAnAccount': 'Create an account',
	'auth.createYourAccount': 'Create your account',
	'auth.createProfile': 'Create your profile',
	'auth.signIn': 'Sign in',
	'auth.signUp': 'Sign up',
	'auth.login': 'Login',
	'auth.signInAccount': 'Sign in to your account',
	'auth.acceptTerms':
		'Creating an account means you’re okay with our {terms} and {privacy} <red>*</red>',
	'auth.terms': 'Terms of service',
	'auth.privacy': 'Privacy policy',
	'auth.dontHaveAnAccount': `Don't have an account?`,
	'auth.haveAccount': 'Already have an account?',
	'auth.signUpWith': 'Sign up with {name}',
	'auth.signInWith': 'Sign in with {name}',
	'auth.message.quote': `The results were phenomenal and we hired one of them.
	I wish I had started with them before going through close to 500 applications!`,
	'validation.lowercase':
		'{length, plural, =1 {One} other {#}} lowercase {length, plural, =1 {character} other {characters}}',
	'validation.uppercase':
		'{length, plural, =1 {One} other {#}} uppercase {length, plural, =1 {character} other {characters}}',
	'validation.special':
		'{length, plural, =1 {One} other {#}} special {length, plural, =1 {character} other {characters}}',
	'validation.number':
		'{length, plural, =1 {One} other {#}} {length, plural, =1 {number} other {numbers}}',
	'validation.minLength':
		'{length, plural, =0 {} =1 {One character minimum} other {# characters minimum}}',
	'validation.maxLength': 'Maximum length of {length, number}',
	'validation.emailIncluded': 'This email is already included',
	'auth.forgotPassword': 'Forgot password?',
	'nav.organizations': 'Organizations',
	'nav.addOrg': 'Add new organization',
	'nav.personalInformation': 'Account',
	'nav.campaigns': 'Campaigns',
	'nav.integrations': 'Integrations',
	'nav.company': 'Company',
	'nav.security': 'Security',
	'nav.billing': 'Billing',
	'statusButton.nextStatus': 'Next status:',
	'invite.challenge': 'Invite talent',
	'invite.challengeLink': 'Copy the challenge link and send it to {type}',
	'invite.challenge.success': 'Challenge link of {name} successfully copied to clipboard',
	'invite.challenge.uploadEmailFileDropZone': 'Drag & drop your file here',
	'talentGroup.menu.emailReminder': 'Send email reminder',
	'talentGroup.menu.copyChallenge': 'Copy challenge',
	'talentGroup.menu.deleteGroup': 'Delete group',
	'talentGroup.sendNotification':
		'Send notification email to invite new talents to take the challenge',
	'dashboard.welcome.hello': 'Hello, {name}',
	'dashboard.emailVerified': 'Email verified!',
	'dashboard.welcome.firstTime.welcome': 'Welcome to your dashboard!',
	'dashboard.welcome.firstTime.createTeam': 'Create a team to get started',
	'dashboard.welcome.createTeam': 'Click into a team or create a new one',
	'dashboard.welcome.box.title': `Let's get started!`,
	'dashboard.welcome.box.subText':
		'Create your first team to hire talent or benchmark employee skills.',
	'dashboard.welcome.box.buttonText': 'Create team',
	'dashboard.welcome.box.trialEnded':
		'Your free trial has ended or your plan has been cancelled. ',
	'dashboard.welcome.firstTime.naomi.welcome': 'Welcome to your dashboard!',
	'dashboard.welcome.firstTime.naomi.welcomeBalloon': 'Welcome!',
	'dashboard.welcome.firstTime.naomi.description': `I'm Naomi, the powerful AI engine behind the platform and I'll be here to help you create your teams, measure skills, and analyze insights over time.`,
	'dashboard.welcome.firstTime.seeDashboard': 'See my dashboard!',
	'dashboard.welcome.firstTime.welcomeMessage': `I'm Naomi, the powerful AI engine behind the platform and I'll be
	here to help you create your teams, measure skills, and analyse insights over time`,
	'dashboard.trialExpired.title': 'Your 14 free day trial has ended',
	'dashboard.trialExpired.desc':
		'Don’t worry you still have access to all of your data but your profile is now locked with limited actions. Select a plan to regain full access.',
	'teams.challengeDetails': 'View challenge details',
	'teams.addTalent': 'Invite talent',
	'talentGroup.createNewGroup': 'Create new group',
	'teams.createGroup': 'Create group',
	'teams.talentGroup': 'Talent group',
	'teams.attempts': 'attempts',
	'teams.totalTalent': 'Total {total, plural, =1 {talent: } other {talents: }}',
	'teams.totalGroup': 'Total {total, plural, =1 {group: } other {groups: }}',
	'teams.searchInputPlaceholder': 'search talent, talent group',
	'teams.searchGroupPlaceholder': 'Search by team name',
	'teams.profile.noTalentSelect': 'No talent selected',
	'teams.profile.noTalentFound': 'No talent found',
	'teams.profile.noTalentAdded': 'No talent has been added yet',
	'teams.profile.clickOnProfile':
		'Click the talent on {direction} to see their profiles here!',
	'teams.talent': 'Talent',
	'teams.talent.filter.progress': 'Progress',
	'teams.talent.filter.createdat': 'Last added',
	'teams.talent.filter.updatedat': 'Last updated',
	'teams.talent.filter.score': 'Score',
	'teams.talent.filter.name': 'Name',
	'teams.talentGroups.filter': 'Display:',
	'teams.talentGroups.filter.all': 'All',
	'teams.talentGroups.filter.internal': 'Employees',
	'teams.talentGroups.filter.external': 'Candidates',
	'teams.talentProfile': 'Talent profile',
	'teams.talentDynamic': 'Talent dynamic',
	'teams.talentProfile.profile': 'View nugget profile',
	'teams.talentProfile.profileTooltip':
		'{name} has a public nugget profile. Click to check it out',
	'teams.talentDynamic.talent.description':
		'Click on the talent tag below to pin tag to the graph above',
	'teams.talent.all': 'All ({total})',
	'teams.talent.employee': 'Employees ({total})',
	'teams.talent.candidate': 'Candidates ({total})',
	'jobs.notFound': 'No jobs found!',
	'jobs.assignTeamMate': 'Assign teammates',
	'jobs.assignTeamMate.placeholder': 'Type the name of the user, then press enter',
	'jobs.assignTeamMate.jobRole': 'Job role',
	'jobs.import': 'Import jobs',
	'jobs.noJobs': `You can start creating jobs by clicking on the 'Create new job' button on the top right!`,
	'jobs.entryLevel': 'Entry level - {jobType}',
	'jobs.closeDate': 'Close date - {closeDate}',
	'jobs.edit': 'Edit job',
	'jobs.edit.desc':
		'In this menu you can edit the job settings and submit for review again.',
	'jobs.assign': 'Assign',
	'jobs.assign.desc': 'Manage the teammates that are assigned to this job posting',
	'jobs.delete': 'Delete job',
	'jobs.delete.desc': 'Delete the job posting forever',
	'jobs.delete.body':
		'Are you sure you want to delete <bold>{name}</bold>? All of its content will be permanently deleted and cannot be restored',
	'jobs.public': 'Public',
	'jobs.public.desc': 'Turn on/off to control who in the team can view the job posting',
	'jobs.createdBy': 'Created by {name}',
	'jobs.draft': 'Draft',
	'jobs.active': 'Active',
	'jobs.permission.0': 'Editor',
	'jobs.permission.1': 'Viewer',
	'jobs.status': 'Job status',
	'jobs.type': 'Job type',
	'jobs.creator': 'Job creator',
	'accountSettings.title': 'Settings',
	'accountSettings.account': 'Account',
	'accountSettings.billing': 'Billing',
	'accountSettings.security': 'Security',
	'accountSettings.notification': 'Notification',
	'accountSettings.emailVerified': 'Email verified',
	'accountSettings.emailNotVerified': 'Email not verified',
	'accountSettings.placeholder.username': 'Your first name',
	'accountSettings.placeholder.lastname': 'Your last name',
	'accountSettings.billing.title': 'Available plans',
	'accountSettings.billing.bothPlansCancelled.title':
		'Your {plan1} plan and {plan2} has been cancelled',
	'accountSettings.billing.bothPlansCancelled.desc':
		'You will still be able to access {plan1} plan and {plan2} features until <bold>{date}</bold>.',
	'accountSettings.billing.bothPlansCancelled.subdesc':
		'<bold>Note</bold>: a final payment will be charged for talent benchmarking at the end of the billing cycle.',
	'accountSettings.billing.hiring.title': 'Your Talent acquisition plan has been cancelled',
	'accountSettings.billing.hiring.message':
		'You will still be able to access Talent acquisition plan features until <bold>{date}</bold>.',
	'accountSettings.billing.benchmarking.title':
		'Your Talent benchmarking plan has been cancelled',
	'accountSettings.billing.benchmarking.message':
		'You will still be able to access benchmarking plan features until <bold>{date}</bold>. Any additional employees will be charged on the final payment',
	'accountSettings.billing.myPlans': 'My Plans',
	'accountSettings.billing.nextPayment': 'Your next payment is scheduled on {date}',
	'accountSettings.billing.isCancelled': 'Cancellation scheduled',
	'accountSettings.billing.benchmarking.no.nextPayment': `Final payment is scheduled on {date}.`,
	'accountSettings.billing.hiring.no.nextPayment': `Active until {date}.`,
	'accountSettings.billing.benchmarking.note':
		'* Note: We only charge for employees that complete the entire challenge. You can gauge this when their progress reaches 100%',
	'accountSettings.billing.desc':
		'You can add an additional plan on top of the current plan you have.',
	'companySettings.drag&DropYourLogo': 'Drag & drop your logo here',
	'companySettings.dropYourFiles': 'Drop your files here',
	'org.placeholder.industry': 'Your company industry',
	'org.placeholder.companySize': 'Your company size',
	'org.placeholder.name': 'Your company name',
	'org.company': 'Company',
	'org.companyName': 'Company name',
	'org.companyLogo': 'Company logo',
	'org.yourRole': 'Your role',
	'org.companySize': 'Company size',
	'org.companyIndustry': 'Company industry',
	'changePassword.currentPassword': 'Current password',
	'changePassword.newPassword': 'New password',
	'changePassword.confirmNewPassword': 'Confirm new password',
	'changePassword.passwordError': 'Passwords do not match',
	'changePassword.success': 'Password successfully updated.',
	'addPassword.success': 'Password successfully added.',
	'accountSettings.security.deleteAccount.title': 'Delete account',
	'accountSettings.security.deleteAccount.description':
		'You will lose access to this account and all of the data. This cannot be undone.',
	'auth.forgotPassword.0.title': 'Forgot password?',
	'auth.forgotPassword.0.desc': 'Enter the email address associated with your account',
	'auth.forgotPassword.1.title': 'Verify your email',
	'auth.forgotPassword.1.desc': 'Please enter the 4 digit code sent to {email}',
	'auth.forgotPassword.2.title': 'Reset password',
	'auth.forgotPassword.2.desc': 'Please add your new password',
	'auth.forgotPassword.success.title': 'Congrats!',
	'auth.forgotPassword.success.msg': 'Your password has been successfully reset',
	'auth.forgotPassword.resetPassword': 'Reset password',
	'alert.trial.expired':
		'No plan active. Please activate a plan to continue to use Supertalent',
	'alert.benchmarking.plan.canceled':
		'Your {plan} plan will be <bold>cancelled and a final payment will be charged on {date}</bold>',
	'alert.hiring.plan.canceled': 'Your {plan} plan will be cancelled on <bold>{date}</bold>',
	'alert.plans.canceled': 'Your {first} & {second} plans will be cancelled on {date}',
	'alert.plan.failed':
		'Your last payment for the {plan} plan has failed. Please update your credit card from the customer billing portal in Settings > Billing. We will retry up to 4 times within the next 2 weeks before we cancel your plan.',
	'alert.bothPlans.failed':
		'Your last payment for the {benchmarking} and {hiring} plans has failed. Please update your credit card from the customer billing portal in Settings > Billing. We will retry up to 4 times within the next 2 weeks before we cancel your plans.',
	'alert.remaining.trial.days': 'Your trial ends in {value} days',
	'alert.trial.endToday': 'Your trial ends today',
	activatePlan: 'Activate plan',
	reactivatePlan: 'Reactivate plan',
	viewDashboard: 'View Dashboard',
	'header.settings': 'Settings',
	'header.logOut': 'Log Out',
	'mailVerification.verify': 'Please verify your email address',
	'mailVerification.emailSent':
		'A verification mail has been sent. Please check your inbox for the link to complete the process.',
	'mailVerification.verified': 'Your email has been verified!',
	'mailVerification.resendButton': 'Resend email',
	'mailVerification.resendButtonMobile': 'Resend email verification',
	'mailVerification.resendMessage': 'Email succesfully sent',
	'mailVerification.teams': 'You need to verify your email to create a team!',
	'tooltip.teamDynamic': `The spider graph below shows the skill levels of your talent groups and talent.
	Click on a talent group or individual talent to show or hide it in your graph.`,
	'tooltip.talentGroup': `It's best practice to separate talent into groups`,
	'tooltip.emailReminder': 'Send an email reminder to complete the challenge',
	continue: 'Continue',
	pin: 'Pin',
	shortlist: 'Shortlist',
	removeShortlist: 'Remove from shortlist',
	customFields: 'Custom fields',
	modifyTalent: 'Modify groups',
	deleteTalent: 'Delete talent',
	delete: 'Delete',
	settings: 'Settings',
	save: 'Save',
	back: 'Back',
	backTo: 'Back to {place}',
	clear: 'Clear',
	clearAll: 'Clear all',
	welcome: 'Welcome',
	fieldName: 'Field Name',
	value: 'Value',
	nodata: 'No Data',
	activate: 'Activate',
	'payment.startingAt': 'Starting at',
	'payment.simple.pricing': 'Simple pricing',
	'payment.monthly': 'month',
	'payment.yearly': 'year',
	'payment.calculate.cost.desc':
		'Your cost is calculated at the end of the month based on a graduated pricing plan that follows the number of active employee profiles in your account.',
	'payment.calculate.cost.desc.0':
		'For example, if you have {count} employee profiles, you’ll pay a fixed fee of $600.',
	'payment.calculate.cost.desc.1':
		'For example, if you have {count} employee profiles, you’ll pay a fixed fee of $600 for the first 20 employees and $30 each for the next {diff} employees',
	'payment.calculate.cost.desc.2':
		'For example, if you have {count} employee profiles, you’ll pay a fixed fee of $600 for the first 20 employees, $30 each for the next 30 employees, and $20 each for the next {diff} employees.',
	'payment.calculate.cost': 'Calculate estimated cost',
	'payment.calculate.label':
		'Move the slider or enter the number of employees in your organization:',
	'payment.calculate.desc':
		'Add the number of employees to help determine the monthly price',
	'payment.estimated.monthly': 'Estimated monthly cost:',
	'payment.show.pricingTable': 'Show pricing table',
	'payment.hide.pricingTable': 'Hide pricing table',
	'payment.pricing.table.numEmployee': '# of Employees',
	'payment.pricing.table.perEmployee': 'per Employees',
	'payment.pricing.table.flatFee': 'Flat fee',
	'payment.plan': 'Plan',
	'payment.plan.desc':
		'Select the plan that fits your need most. Need both? You can add the second plan to the same billing cycle once you’re set up.',
	payment: 'Payment',
	manage: 'Manage',
	'payment.plan.contact': 'Need more? Contact sales for enterprise solutions',
	'plans.freeTrial': 'Start 14 days free trial',
	'talentGroup.filters.talentGroup': 'Talent group',
	'talentGroup.filters.Bookmark': 'Bookmark',
	'talentGroup.filters.Shortlist': 'Shortlist',
	'talentProfile.empty.note': 'No notes found',
	'talentProfile.edit.note': 'Edit note',
	'teamCard.talent': '{count, plural, =1 {talent} other {talents}}',
	'teamCardMenu.edit.primary': 'Rename',
	'teamCardMenu.mask.primary': 'Mask talent',
	'teamCardMenu.editattempts.primary': 'Edit attempts',
	'teamCardMenu.editattempts.secondary': 'Change your attempts.',
	'teamCardMenu.delete.primary': 'Delete team',
	'teamCardMenu.edit.secondary': 'Change your team name.',
	'teamCardMenu.mask.secondary':
		'Give talent the opportunity to anonymize personal identifiable information, like their name, email, etc.',
	'teamCardMenu.delete.secondary': 'This will delete your team forever.',
	'createableSelect.title': 'Select an option or start typing to create one',
	'createableSelect.editWarning': 'This option already exists',
	'createableSelect.deleteOption': 'Delete option',
	'createableSelect.deleteOption.body': 'Are you sure you want to remove this option?',
	'createableSelect.deleteOption.okText': 'Remove',
	addTalent: 'Invite talent',
	talent: 'Talent',
	'addTalent.placeholder': 'Select talent group to import',
	'addTalent.talentGroup.error.required': 'Talent group field is required',
	'addTalent.bulkSuccess': 'Successfully bulk added users',
	'addTalent.toastSucess': 'Successfully invite users',
	'addTalent.helperText':
		'An invite email will automatically be send to the emails you provide',
	'addTalent.uploadCount': 'Users uploaded: {count}',
	'addTalent.remove': 'Remove',
	'talentGroup.createNew': 'Create new talent group',
	'talentGroup.edit': 'Edit group',
	'talentGroup.teamName.error.required': 'Team name field is required',
	'talentGroup.type.error.required': 'Type field is required',
	'talentGroup.color.error.required': 'Team colour field is required',
	'talentGroup.helperText':
		'An invite email will automatically be send to the emails you provide',
	'sendEmailReminder.title': 'Send email reminder',
	'sendEmailReminder.label': 'Emails',
	'sendEmailReminder.placeholder': 'Enter multiple emails seperated by a space',
	'sendEmailReminder.helperText':
		'You can only send email reminders to talent that exists in your talent group',
	'sendEmailReminder.checkboxLabel':
		'Send mass email reminder to everyone in the talent group who has not completed the challenge',
	'sendEmailReminder.btnLabel': 'Send',
	'sendEmailReminder.error.invalid': 'Invalid email address: {emails}',
	'sendEmailReminder.success':
		'Successfully sent {recipientCount} email {recipientCount, plural, =1 {reminder} other {reminders}}',
	'sendEmailReminder.success.unique': 'Successfully sent email reminder',
	'sendEmailReminderModal.title':
		'Send email reminder to {masked, plural, =1 {{skeleton}} other {{masked}}}?',
	'sendEmailReminderModal.desc': `By clicking 'Send', an email reminder will be sent to {masked, plural, =1 {{skeleton}} other {{masked}}} to complete the challenge.`,
	'sendEmailReminder.maskedTalent': 'masked talent',
	'editTalent.label': 'Talent group',
	'editTalent.desc':
		'Add or remove talent groups for {masked, plural, =1 {{skeleton}} other {{masked}}}',
	'editTalent.helper':
		'Talents cannot belong to Employee and Candidate groups at the same time',
	'challengeSubmission.title': 'has completed {value} challenges',
	'challengeSubmission.orderedListSubmission': 'Ordered list from talent',
	'challengeDetails.desc': 'See a list of challenges that talent will have to answer',
	'challengeSubmission.instruction':
		'Order these options based on how likely you are to engage in the behaviour, from top as most likely to bottom as least likely',
	'comment.placeholder': 'Write a comment...',
	answer: 'Answer',
	'challengeSubmission.prev': 'Interpersonal awareness',
	'challengeSubmission.next': 'Information seeking',
	totalDuration: 'Estimated time:',
	challengeDetails: 'Challenge details',
	preview: 'Preview',
	progress: 'Progress',
	mins: 'mins',
	spent: 'spent',
	outOf: 'out of',
	'legend.consulting': 'Consulting',
	'legend.Series': 'Series',
	completed: 'Completed',
	incomplete: 'Incomplete',
	filters: 'Filters',
	viewSubmission: 'View submission',
	badges: 'Badges',
	employee: 'Employee',
	candidate: 'Candidate',
	'addedAt.min': 'Added {min, plural, =0 {1 min ago} =1 {1 min ago} other {# mins ago}}',
	'addedAt.hour': 'Added {hour, plural, =1 {1 hour ago} other {# hours ago}}',
	'addedAt.day': 'Added {day, plural, =1 {1 day ago} other {# days ago}}',
	'addedAt.date': 'Added {date}',
	profile: 'Profile',
	roleInterests: 'Role interests',
	selfReportedSkills: 'Self-reported skills',
	roleFunction: 'Role function',
	roleLevel: 'Role level',
	experienceLevel: 'Experience level',
	education: 'Education',
	softSkillScore: 'Nugget soft skills score',
	challengeSubmission: 'Challenge submission',
	notes: 'Notes',
	challengeProgress: 'Challenge progress',
	talentGroups: 'Talent groups',
	sendEmailReminder: 'Send email reminder',
	deleteFormName: 'Delete {masked, plural, =1 {{skeleton}} other {{masked}}}?',
	deleteFormDesc:
		'Are you sure you want to delete {masked, plural, =1 {{skeleton}} other {{masked}}}? All of its content will be permanently deleted and cannot be restored',
	editForm: 'Edit {value}',
	editTalentForm: 'Modify groups for {masked, plural, =1 {{skeleton}} other {{masked}}}',
	EditAttempts: 'Edit Attempts {value}',
	edit: 'Edit',
	talentGroupName: 'Talent group name',
	talentType: 'Talent type',
	updatedUser: 'Account successfully updated.',
	updatedOrg: 'Company successfully updated.',
	copiedLink: 'Successfully copied link to clipboard',
	publicLink: 'Public link',
	copyLink: 'Copy link',
	link: 'Link',
	importVia: 'Import via',
	importUser: 'Import user',
	'importUser.desc': 'New talent will automatically be sent the challenge',
	type: 'Type',
	file: 'File',
	groupColor: 'Group colour',
	fileDesc:
		'An email will automatically be sent to new talent to complete the challenge once you import',
	import: 'Import',
	uploadFile: 'Upload file',
	loading: 'Loading',
	createOption: 'Create "{value}"',
	warning: 'Warning',
	dashboard: 'Dashboard',
	monitor: 'Monitor',
	name: 'Name',
	email: 'Email',
	nuggetScore: 'nugget score',
	score: 'Score',
	account: 'Account',
	password: 'Password',
	firstName: 'First name',
	lastName: 'Last name',
	finish: 'Finish',
	newSinceLastLogin: '{count} new talent',
	new: 'New',
	status: 'Status',
	skills: 'Skills',
	tags: 'Tags',
	location: 'Location',
	or: 'or',
	challengeCopied: 'Challenge link copied',
	linkedin: 'Linkedin',
	emailAddress: 'Email address',
	resume: 'Resume',
	sortBy: 'Sort by',
	createdAt: 'Created at',
	updatedAt: 'Updated at',
	questions: 'questions',

	// Onboarding
	'onboarding.skill.with.industry.benchmark': 'Industry benchmark is available!',
	'onboarding.skill.without.industry.benchmark': 'Industry benchmark is coming soon!',
	'onboarding.show.more.skills': 'Show more skills!',
	'onboarding.show.less.skills': 'Show less skills!',
	'onboarding.help.guide.header.title': 'Help guide',
	'onboarding.help.guide.title': 'General guide',
	'onboarding.help.guide.btn.label': 'Help guide',

	// Onboarding - challenge selection
	'onboarding.select.challenge.creation.title':
		'Select how you want to create a challenge pack',

	// Onboarding - challenges selection items
	'onboarding.select.role.based.challenges.title': 'Role-based',
	'onboarding.select.role.based.challenges.desc':
		'Select how you want to create a challenge pack',
	'onboarding.select.guided.challenges.title': 'Guided',
	'onboarding.select.guided.challenges.desc':
		'Answer a series of questions and we’ll recommend the best challenges for you',
	'onboarding.select.free.form.title': 'Free-form',
	'onboarding.select.free.form.desc':
		'It’s open season! Select from our entire library of skills',

	// GUIDED CHALLENGES
	// Onboarding - guided challenges steps
	'onboarding.guided.challenges.step.0': 'Questions',
	'onboarding.guided.challenges.step.1': 'Challenge pack preview',
	'onboarding.guided.challenges.step.2': 'Team',
	'onboarding.guided.challenges.subStep.0': 'Company values',
	'onboarding.guided.challenges.subStep.1': 'Top performer',
	'onboarding.guided.challenges.subStep.2': 'Culture fit',
	'onboarding.guided.challenges.subStep.3': 'Culture champion',

	// Onboarding - guided challenges (skill selection - root step 1)
	/* first step */
	'onboarding.guided.challenges.skillSelection.title.0':
		'Reflect on the values and objectives of your company, and select the soft skills categories that you think are most relevant.',
	'onboarding.guided.challenges.skillSelection.description.0':
		'Select a minimum of 3 skills',
	/* second step */
	'onboarding.guided.challenges.skillSelection.title.1':
		'Now, we’re showing the soft skills within the categories you selected. Which of these soft skills would you consider are most important for an employee to have to perform well in your company?',
	'onboarding.guided.challenges.skillSelection.description.1':
		'Select a minimum of 3 skills',
	/* third step */
	'onboarding.guided.challenges.skillSelection.title.2':
		'Sometimes even the employees who aren’t the best performers are the ones representing and championing the company’s values. Which of these skills are important for culture champions to have in your company?',
	'onboarding.guided.challenges.skillSelection.description.2':
		'Select a minimum of 3 skills',
	/* fourth step */
	'onboarding.guided.challenges.skillSelection.title.3': `Let’s switch gears from top performers and culture champions a little. Which of these skills do you think people on your team can develop further?`,
	'onboarding.guided.challenges.skillSelection.description.3':
		'Select a minimum of 3 skills',

	// Onboarding - guided challenges guides
	'onboarding.guided.challenges.help.guide.title.step.0': 'Company values',
	'onboarding.guided.challenges.help.guide.desc.step.0': `Though all the skill categories may be part of your company's values, consider prioritizing them to determine which truly represent your company's most important values. Also consider your goal for creating this challenge pack when determining which categories you want.`,
	'onboarding.guided.challenges.help.guide.title.step.1': 'Top performer',
	'onboarding.guided.challenges.help.guide.desc.step.1': `We want you to think about the skills that are necessary for an employee to excel in your company's work environment{linebreak}{linebreak}For example, if your company promotes a fast-paced work environment, what kind of skills would allow employees to perform at their best in this kind of environment? `,
	'onboarding.guided.challenges.help.guide.title.step.2': 'Culture fit',
	'onboarding.guided.challenges.help.guide.desc.step.2': `We want you to think about the values of your company and which skills would be important for an employee to demonstrate if they were to be a good match to the values of your company.{linebreak}{linebreak}If these are the same skills that you selected before, that is okay! Outline all the skills that are important to fit within your company.`,
	'onboarding.guided.challenges.help.guide.title.step.3': 'Culture champion',
	'onboarding.guided.challenges.help.guide.desc.step.3': `A culture champion is someone who exudes the values of your company. These employees may not necessarily be the best performers on the job (though they could still be!), but they live and breathe the company’s values.{linebreak}{linebreak}They take on extra work to reflect and promote the values of the company and they encourage others to adopt these values as well. `,
	'onboarding.guided.challenges.help.guide.title.step.4': 'Challenge pack preview',
	'onboarding.guided.challenges.help.guide.desc.step.4': `The shown skills are grouped into 'must-have' and 'nice-to-have' according to your answers to our questions. Each skill presents a challenge composed of a series of scenarios the challenge-taker will have to react to. Click "preview challenge" to view an example.{linebreak}{linebreak}Note: The estimated time is provided by real data generated by people who have taken our challenges in the past.`,
	'onboarding.guided.challenges.help.guide.title.step.5': 'Team',
	'onboarding.guided.challenges.help.guide.desc.step.5': `This final page allows you to name the talent group you are testing and fill in some more information to finalize the creation of your challenge pack. You can also invite challenge from this page, but it is not necessary to do so now - once the team is created, you will have access to the challenge pack link and be able to share the challenge then.{linebreak}{linebreak}They take on extra work to reflect and promote the values of the company and they encourage others to adopt these values as well. `,

	// Onboarding - guided challenges (root step)
	/* second step */
	'onboarding.guided.challenges.step.1.title': 'Skill review',
	'onboarding.guided.challenges.step.1.desc':
		'Your skills recommendations are here. Use the handles on the right to move to order them around and reflect most to least important.',
	/* third step */
	'onboarding.guided.challenges.step.2.title': 'Team details',

	// ROLE BASED CHALLENGES
	// Onboarding - role-based challenges steps
	'onboarding.role.based.challenges.step.0': 'Role',
	'onboarding.role.based.challenges.step.1': 'Challenges',
	'onboarding.role.based.challenges.step.2': 'Challenge pack preview',
	'onboarding.role.based.challenges.step.3': 'Team',

	// Onboarding - role-based challenges (root step)
	/* first step */
	'onboarding.role.based.challenges.step.0.title': 'Select a role to get started',
	/* second step */
	'onboarding.role.based.challenges.step.1.title':
		'Select the skills you want to include in the challenge pack for',
	'onboarding.role.based.challenges.step.1.description': 'Select a minimum of 3 skills',
	/* third step */
	'onboarding.role.based.challenges.step.2.title': 'Challenge pack preview',
	'onboarding.role.based.challenges.step.2.desc': `Here's a final look at the skills included in your challenge pack for Sales! Use the handles (=) on the right to move and order the skills from most (top) to least (bottom) important. You can also delete unwanted skills by pressing the red minus (-).`,
	/* fourth step */
	'onboarding.role.based.challenges.step.3.title': 'Team details',

	// Onboarding - role-based challenges guides
	'onboarding.role.based.challenges.help.guide.title.step.0': 'Role',
	'onboarding.role.based.challenges.help.guide.desc.step.0':
		'Select a role that best fits the group of people you would like to test. We will recommend a pack of challenges to you based on industry standards for the most relevant and important soft skills. If your role is not present, you may return to the dashboard and use the guided format and let us know what role we should add to our list.',
	'onboarding.role.based.challenges.help.guide.title.step.1': 'Challenges',
	'onboarding.role.based.challenges.help.guide.desc.step.1': `The default challenges are based off of industry standards for the most important soft skills required to succeed in that role. They are meant to help you, but you are not limited to them. You may add or remove challenges as you like. Be sure to check for the green indicator - it displays the presence of an industry benchmark, which will help you determine how the test-taker's score compares to the industry average.`,
	'onboarding.role.based.challenges.help.guide.title.step.2': 'Challenge pack preview',
	'onboarding.role.based.challenges.help.guide.desc.step.2': `The challenge pack preview allows you to take one last look at the challenges you've chosen. You can adjust the order in which they appear and remove ones you no longer want to test. You may also click the "preview challenge" button to see what kind of test the users would be taking.`,
	'onboarding.role.based.challenges.help.guide.title.step.3': 'Team',
	'onboarding.role.based.challenges.help.guide.desc.step.3': `This final page allows you to name the talent group you are testing and fill in some more information to finalize the creation of your challenge pack. You can also invite challenge from this page, but it is not necessary to do so now - once the team is created, you will have access to the challenge pack link and be able to share the challenge then.`,

	// FREE FORM
	// Onboarding - free-form steps
	'onboarding.free.form.step.0': 'Challenges',
	'onboarding.free.form.step.1': 'Challenge pack preview',
	'onboarding.free.form.step.2': 'Team',

	// Onboarding - free form steps
	/* first step */
	'onboarding.free.form.step.0.title': 'Select skills and build your own challenge pack',
	'onboarding.free.form.step.0.description': 'Select a minimum of 3 skills',
	/* second step */
	'onboarding.free.form.step.1.title': 'Challenge preview',
	'onboarding.free.form.step.1.desc': `Here's a final look at the skills included in your challenge pack ! Use the handles (=) on the right to move and order the skills from most (top) to least (bottom) important. You can also delete unwanted skills by pressing the red minus (-).`,
	/* fourth step */
	'onboarding.free.form.step.2.title': 'Team details',

	// Onboarding - free form guides
	'onboarding.free.form.help.guide.title.step.0': 'Challenges',
	'onboarding.free.form.help.guide.desc.step.0': `The default challenges are based off of industry standards for the most important soft skills required to succeed in that role. They are meant to help you, but you are not limited to them. You may add or remove challenges as you like. Be sure to check for the green indicator - it displays the presence of an industry benchmark, which will help you determine how the test-taker's score compares to the industry average.`,
	'onboarding.free.form.help.guide.title.step.1': 'Challenge pack preview',
	'onboarding.free.form.help.guide.desc.step.1': `The challenge pack preview allows you to take one last look at the challenges you've chosen. You can adjust the order in which they appear and remove ones you no longer want to test. You may also click the "preview challenge" button to see what kind of test the users would be taking.`,
	'onboarding.free.form.help.guide.title.step.2': 'Team',
	'onboarding.free.form.help.guide.desc.step.2': `This final page allows you to name the talent group you are testing and fill in some more information to finalize the creation of your challenge pack. You can also invite challenge from this page, but it is not necessary to do so now - once the team is created, you will have access to the challenge pack link and be able to share the challenge then.`,

	// -- old -- //
	// team onboarding/
	'teams.onboarding.title': 'Create new team',
	'teams.onboarding.learnMore': 'Learn more.',

	// team onboarding - objective/
	'teams.onboarding.objective.restricted.0':
		'A Hiring plan is required to access this feature.',
	'teams.onboarding.objective.restricted.1':
		'A benchmarking plan is required to access this feature.',
	'teams.onboarding.objective.stepTitle': 'Objective',
	'teams.onboarding.objective.nyomiMessage':
		'nugget.ai uses simulation-based assessments (what we call nugget.ai challenges) to help quantify the skills of candidates and employees. These tools can be used to help you hire the best talent or quantify the skills of your current employees.',
	'teams.onboarding.objective.title': 'Select an objective to focus on',
	'teams.onboarding.objective.description':
		'The objective will help determine how your skills are recommended.',
	'teams.onboarding.objective.learnMore':
		'nugget.ai uses simulation-based assessments, refered to as “challenges”, to help quantify the skills of candidates and employees. These tools can be used to help you hire the best talent or quantify the skills of your current employees. \n\nYou can only select one objective to start, but once your team is active, there’s nothing stopping you from creating groups to hire and benchmark at the same time.',
	// team onboarding - objective -> choices
	'teams.onboarding.objective.hiring.tooltip':
		'We can help you to quantity the skills of your talent pool, providing you with recommendations of who is the best fit for a job role to help you hire the best candidate for the job.',
	'teams.onboarding.objective.benchmarking.tooltip':
		'We can also help you measure the skills of employees in your company, allowing you to determine areas of strength for your employees as well as areas that might need improvement. You can examine the skills of individual employees, teams, as well as the entire company, finding out what makes people at your company unique.',
	// team onboarding - /objective

	// team onboarding - skill identification/
	'teams.onboarding.skillIdentification.stepTitle': 'Details',
	'teams.onboarding.skillIdentification.nyomiMessage.initial':
		'This is the start of our nugget.ai challenge selection process! You will be asked <bold>total of four questions that are meant to give us insight</bold> into what soft skills are valued, rewarded, and reinforced in your company.',
	'teams.onboarding.skillIdentification.title.0.tooltip':
		'Soft skills are transferable and adaptive skills of a candidate that support effective job performance, such as communication and time management. They are different from hard skills, which are the skills that are the technical skills necessary for the job.',
	'teams.onboarding.skillIdentification.description.0':
		'Below are a series of nugget.ai soft skills categories. Take your time to reflect on this, as it is important to get an accurate understanding of what makes a good candidate for this job. You will have the opportunity to identify more specific soft skills in the next few questions. Please select all that apply.',
	'teams.onboarding.skillIdentification.title.1':
		'Now, we’re showing the soft skills within the categories you selected. aWhich of these skills are necessary for an employee to excel in this role?',
	'teams.onboarding.skillIdentification.description.1': 'Select all that apply.',

	// team onboarding - skill identification - benchmarking/
	'teams.onboarding.skillIdentification.benchmarking.nyomiMessage.succeeding':
		'Using the skills categories you identified in the last question, we are now going to ask you questions to help determine the specific soft skills that are relevant to your company.',
	// first step
	'teams.onboarding.skillIdentification.benchmarking.modalTitle.0': 'Company Values',
	'teams.onboarding.skillIdentification.benchmarking.title.0':
		'Reflect on the values and objectives of your company, and select the soft skills categories that you think are most relevant.',
	'teams.onboarding.skillIdentification.benchmarking.description.0':
		'Select all that apply.',
	'teams.onboarding.skillIdentification.benchmarking.learnMore.0':
		'This is the start of the nugget.ai challenge selection process! You will be asked a total of four questions that are meant to give us insight into what soft skills are valued, rewarded, and reinforced in your company. \n\nSoft skills are transferable and adaptive skills of a candidate that support effective job performance, such as communication and time management. They are different from hard skills, which are the skills that are the technical skills necessary for the job. \n\nBelow are a series of nugget.ai soft skills categories. Take your time to refect on this, as it is important to get an accurate understanding of what makes your company unique!',
	// second step
	'teams.onboarding.skillIdentification.benchmarking.modalTitle.1': 'Top Performer',
	'teams.onboarding.skillIdentification.benchmarking.title.1':
		'Now, we’re showing the soft skills within the categories you selected. Which of these soft skills would you consider are most important for an employee to have to perform well in your company?',
	'teams.onboarding.skillIdentification.benchmarking.description.1':
		'Select all that apply.',
	'teams.onboarding.skillIdentification.benchmarking.learnMore.1': `We want you to think about the skills that are necessary for an employee to excel in your company's work environment.{linebreak}{linebreak}For example, if your company promotes a fast-paced work environment, what kinds of skills would allow employees to perform at their best in this kind of environment?`,
	// second step sub-steps
	'teams.onboarding.skillIdentification.benchmarking.sub.0': 'Company values',
	'teams.onboarding.skillIdentification.benchmarking.sub.1': 'Top performer',
	'teams.onboarding.skillIdentification.benchmarking.sub.2': 'Culture champion',
	'teams.onboarding.skillIdentification.benchmarking.sub.3': 'Skill gaps',
	// third step
	'teams.onboarding.skillIdentification.benchmarking.modalTitle.2': 'Culture champion',
	'teams.onboarding.skillIdentification.benchmarking.title.2':
		'Sometimes even the employees who aren’t the best performers are the ones representing and championing the company’s values. Which of these skills are important for culture champions to have in your company?',
	'teams.onboarding.skillIdentification.benchmarking.description.2':
		'Select all that apply.',
	'teams.onboarding.skillIdentification.benchmarking.learnMore.2': `A culture champion is someone who exudes the values of your company. These employees may not necessarily be the best performers on the job (though they could still be!), but they live and breathe the company’s values.
		\nThey put in the effort to reflect and promote the values of the company and they encourage others to adopt these values as well.`,
	// fourth step
	'teams.onboarding.skillIdentification.benchmarking.modalTitle.3': 'Skill gaps',
	'teams.onboarding.skillIdentification.benchmarking.title.3': `Let’s switch gears from top performers and culture champions a little. Which of these skills do you think people on your team can develop further?`,
	'teams.onboarding.skillIdentification.benchmarking.description.3':
		'Select all that apply.',
	'teams.onboarding.skillIdentification.benchmarking.learnMore.3': `Now that we've covered what skills you believe are needed for your top performing employees and your culture champions, let's cover what skills you believe can be honed and developed further on your team.`,

	'teams.onboarding.skillIdentification.benchmarking.title.1.tooltip': `We want you to think about the skills that are necessary for an employee to excel in your company's work environment.{linebreak}{linebreak}For example, if your company promotes a fast-paced work environment, what kinsd of skills would allow employees to perform at their best in this kind of environment?`,
	'teams.onboarding.skillIdentification.benchmarking.title.2.tooltip':
		'We want you to think about the values of your company and which skills would be important for an employee to demonstrate if they were to be a good match for the values of your company.',
	'teams.onboarding.skillIdentification.benchmarking.title.3.tooltip': `A culture champion is someone who exudes the values of your company.. These employees may not necessarily be the best performers on the job (though they could still be!), but they live and breathe the company's values.{linebreak}{linebreak}They put in the effort to reflect and promote the values of the company and they encourage others to adopt these values as well.`,
	// team onboarding - skill identification - /benchmarking

	// team onboarding - skill identification - hiring/
	'teams.onboarding.skillIdentification.hiring.nyomiMessage.succeeding':
		'Using the skills categories you identified in the last question, we are now going to ask you questions to help determine the specific soft skills that are relevant to the job you are hiring for.',
	// first step
	'teams.onboarding.skillIdentification.hiring.modalTitle.0': 'Candidate soft skills',
	'teams.onboarding.skillIdentification.hiring.title.0':
		'Which of these soft skills categories are you looking for in your ideal candidate?',
	'teams.onboarding.skillIdentification.hiring.description.0': 'Select all that apply.',
	'teams.onboarding.skillIdentification.hiring.learnMore.0':
		'This is the start of our nugget.ai challenge selection process! You will be asked total of four questions that are meant to give us insight into what soft skills are necessary for a candidate in this role. \n\nSoft skills are transferable and adaptive skills of a candidate that support effective job performance, such as communication and time management. They are different from hard skills, which are the technical skills necessary for the job.',
	// second step
	'teams.onboarding.skillIdentification.hiring.modalTitle.1': 'Necessary skills',
	'teams.onboarding.skillIdentification.hiring.title.1':
		'Now, we’re showing the soft skills within the categories you selected. Which of these skills are necessary for an employee to excel in this role?',
	'teams.onboarding.skillIdentification.hiring.description.1': 'Select all that apply.',
	'teams.onboarding.skillIdentification.hiring.learnMore.1':
		'Using the skills categories you identified in the last question, we are now going to ask you questions to help determine the specific soft skills that are relevant to the job you are hiring for. \n\nWe want you to think about the skills that are necessary for an employee to excel in this job. \n\nThese are skills you want an employee to have on their first day in the role so they can perform the job successfully.',
	// second step sub-steps
	'teams.onboarding.skillIdentification.hiring.sub.0': 'Candidate soft skills',
	'teams.onboarding.skillIdentification.hiring.sub.1': 'Necessary skills',
	'teams.onboarding.skillIdentification.hiring.sub.2': 'Trainable skills',
	'teams.onboarding.skillIdentification.hiring.sub.3': 'Company fit',
	// third step
	'teams.onboarding.skillIdentification.hiring.modalTitle.2': 'Trainable skills',
	'teams.onboarding.skillIdentification.hiring.title.2':
		'Which of these skills do you think are nice to have but not necessary for the role or are skills that can be trained on the job?',
	'teams.onboarding.skillIdentification.hiring.description.2': 'Select all that apply.',
	'teams.onboarding.skillIdentification.hiring.learnMore.2':
		'Select all the skills that could either be trained on the job, or you are willing to provide or suggest additional training to develop',
	// fourth step
	'teams.onboarding.skillIdentification.hiring.modalTitle.3': 'Company fit',
	'teams.onboarding.skillIdentification.hiring.title.3':
		'We’ve covered the skills that are specific to the role you’re hiring for. Let’s think about your company broadly now. Which of these skills would you want your ideal candidate to have in general because of your company’s values and culture?',
	'teams.onboarding.skillIdentification.hiring.description.3': 'Select all that apply.',
	'teams.onboarding.skillIdentification.hiring.learnMore.3':
		'Beyond the skills needed for the role, these are the skills that are generally valued in your company.\n\nIf these skills are similar to those you selected before, that is okay! Please highlight the skills that are important for a person to have to fit within your company. ',

	'teams.onboarding.skillIdentification.hiring.title.1.tooltip':
		'We want you to think about the skills that are necessary for an employee to excel in this job.{linebreak}{linebreak}These are skills you want an employee to have on their first day in the role so they can perform the job successfully.',
	'teams.onboarding.skillIdentification.hiring.title.2.tooltip':
		'These skills are the skills that could either be trained on the job, or you are willing to provide or suggest additional training to develop.',
	'teams.onboarding.skillIdentification.hiring.title.3.tooltip':
		'These are the skills that an employee would utiilize all the time on the job, making them very important to consider when hiring a new employee.{linebreak}{linebreak}For example, if the role requires someone to constantly work in an environment of uncertainty, then a skill they will likely use every day on the job is tolerance for ambiguity!',
	// team onboarding - skill identification - /hiring

	// team onboarding - /skill identification

	// team onboarding - skill importance/
	'teams.onboarding.skillImportance.stepTitle': 'Skills',
	'teams.onboarding.skillImportance.nyomiMessage': `Thanks for answering all of those questions! We have taken all the soft skills you identified in the previous step and ordered them in terms of importance. We have identified the 'must haves' and 'nice to haves' soft skills that will be used for the challenge.`,
	'teams.onboarding.skillImportance.title': 'Skill review',
	'teams.onboarding.skillImportance.modalTitle': 'Skills review',
	'teams.onboarding.skillImportance.learnMore':
		'The skills presented are a result of the answers you provided us. Our engine is trained to curate the best-fit skills to your use case. When we recommend skills, we group them into ‘must haves’ and ‘nice to have’ soft skills. Each skill is a challenge that talent will have to answer. \n\nNote: The estimated time is a metric provided by the Science Team from first hand expirements with real people. Each challenge has a total time limit that is different than the estimated time talent usully take to answer. We recommend selecting between 3-5 challenges at most, making it easy for talent to answer.',
	'teams.onboarding.skillImportance.description':
		'Your skills recommendations are here. Use the handles on the right to move to order them around and reflect most to least important.',
	'teams.onboarding.skillImportance.totalChallengeTime.title': 'Estimated total time:',
	'teams.onboarding.skillImportance.skillsRequired':
		'At least {count} skills are required. Go back and edit your preferences.',
	'teams.onboarding.skillImportance.totalChallengeTime.title.tooltip':
		'Taking all the skills identified as relevant to the job, we noted the amount of time it would take for the candidate to complete the full challenge if you assess each of these skills.',
	'teams.onboarding.skillImportance.totalChallengeTime.description':
		'Each skill chapter has a time associated with it on how long it would take employees to complete.',
	'teams.onboarding.skillImportance.mustHaveSkills.title': 'Must-have skills',
	'teams.onboarding.skillImportance.mustHaveSkills.description': `What's absolutely needed to get the job done.`,
	'teams.onboarding.skillImportance.niceToHaveSkills.title': 'Nice-to-have skills',
	'teams.onboarding.skillImportance.niceToHaveSkills.description':
		'These are the skills you would prefer employees in your company to have, but are not necessary.',
	// team onboarding - /skill importance

	// team onboarding - team details/
	'teams.onboarding.teamDetails.stepTitle': 'Team',
	'teams.onboarding.teamDetails.nyomiMessage':
		'Your challenge has been created! Now create your team and I will send over the challenge.',
	'teams.onboarding.teamDetails.title': 'Team details',
	'teams.onboarding.teamDetails.description':
		'Please fill out the information for employees in your company below.',
	'teams.onboarding.teamDetails.teamName': 'Team name',
	'teams.onboarding.teamDetails.maskChallenge': 'Mask the challenge',
	'teams.onboarding.teamDetails.maskChallenge.description': `Checking 'Yes' will give anyone taking the challenge the ability to anonymize their profile by hiding personal identifiable information, such as their name and email.`,
	'teams.onboarding.teamDetails.maskChallenge.tooltip':
		'This can help ensure that people feel comfortable answering the challenge. Studies show that this will increase your challenge completion rate by up to 50%.',
	'teams.onboarding.teamDetails.talentGroupDetails': 'Talent group details',
	'teams.onboarding.teamDetails.talentGroupDetails.description':
		'You can create a group to identify this group of candidates inside this team.',
	'teams.onboarding.teamDetails.talentGroupDetails.talentType.tooltip':
		'Talent type is used to help distinguish between employees and candidates.',
	'teams.onboarding.teamDetails.talentGroupDetails.talentColor': 'Talent colour',
	'teams.onboarding.teamDetails.talentGroupDetails.inviteTeammates': 'Invite talent',
	'teams.onboarding.teamDetails.talentGroupDetails.inviteTeammates.description':
		'Input emails to send them the challenge link. You can always re-send or send it later.',
	// team onboarding - /team details
	// -- old -- //

	all: 'All',
	faq: 'FAQ',
	yes: 'Yes',
	no: 'No',
	multiEmailFieldPlaceholder: 'Type an email and press enter to send or to add another one',
	browseFiles: 'Browse files',
	fileFormat: 'File format',
	maximumSize: 'Maximum size',

	// tour
	'tour.title': 'Take a tour!',
	'tour.naomi': `Hi, I'm Naomi, your AI Assistant, here{linebreak}to help you!`,
	'tour.description': 'Need some help navigating? Explore our help guides.',
	'tour.seeVideoDemo': 'See video demo',
	'tour.viewDemoData': 'Demo data',
	'tour.learnAboutOurScience': 'Our science',
	'tour.closingMessage': `Don't worry, you can still find the guide links from the top navigation pane.`,

	// tours
	'tours.team.addTalent': 'Invite Talent',
	'tours.team.addTalent.desc':
		'Click here to add talent. You can import a csv file or generate a link to send offline.',
	'tours.team.groupCard': 'Talent Group Card',
	'tours.team.groupCard.desc':
		'Organize your talent in groups. We created the first one for you.',
	'tours.team.addTalentGroup': 'Add Talent Group',
	'tours.team.addTalentGroup.desc': 'Click here to create a new talent group. ',
	'tours.team.talent': 'Talent',
	'tours.team.talent.desc':
		'Any talent you import or invite with your team link will appear here.',
	'tours.team.talentDynamic': 'Talent Dynamic',
	'tours.team.talentDynamic.desc': `Once talent complete challenges, you'll be able to visualize their skills and compare them with others.`,
	'tours.team.finish': 'Finish'
}
