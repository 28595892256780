/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import { ThemeOptions, createMuiTheme, alpha } from '@material-ui/core/styles'
import '@material-ui/lab/themeAugmentation'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { theme as lightTheme, darkTheme, Theme } from './sc-theme'
import { pxToRem } from './global-styles'
import DotsLoader from 'packages/nuggetai.ui-kit/atoms/dots-loader'
import { StepIcon } from 'components/StepIcon'
import Highlighted from 'packages/nuggetai.ui-kit/atoms/highlighted'
import { CheckboxChecked, CheckboxIcon } from 'packages/nuggetai.ui-kit/icons/checkbox'

const baseTheme = createMuiTheme()

declare module '@material-ui/core/Chip/Chip' {
	interface ChipPropsVariantOverrides {
		square: true
		squareFilled: true
		modern: true
		squareOutlined: true
	}
}

declare module '@material-ui/core/styles/createTypography' {
	interface Typography {
		title: React.CSSProperties
		paragraph1: React.CSSProperties
		paragraph2: React.CSSProperties
		paragraph3: React.CSSProperties
	}

	// allow configuration using `createMuiTheme`
	interface TypographyOptions {
		title?: React.CSSProperties
		paragraph1?: React.CSSProperties
		paragraph2?: React.CSSProperties
		paragraph3?: React.CSSProperties
	}
}

declare module '@material-ui/core/styles' {
	interface Palette {
		other: Palette['primary']
		link: Palette['primary']
	}
	interface PaletteOptions {
		other: PaletteOptions['primary']
		link: PaletteOptions['primary']
	}
}

// Update the Typography's variant prop options
declare module '@material-ui/core/Typography/Typography' {
	interface TypographyPropsVariantOverrides {
		title: true
		paragraph1: true
		paragraph2: true
		paragraph3: true
	}
}

const materialBaseTheme = (theme: Theme): ThemeOptions => ({
	palette: {
		mode: 'light',
		primary: {
			main: theme.primary.main
		},
		secondary: {
			main: theme.grey.main
		},
		text: {
			primary: theme.text.main,
			secondary: theme.text.description
		},
		other: {
			main: theme.secondary.orange
		},
		link: {
			main: theme.text.link
		},
		success: {
			main: theme.tag.green
		},
		error: {
			main: theme.tag.red
		},
		info: {
			main: theme.tag.blue
		},
		warning: {
			main: theme.tag.yellow
		},
		common: {
			black: theme.tag.black
		},
		grey: {
			'500': theme.border,
			'600': theme.grey.second
		},
		background: {
			paper: theme.background.light
		}
	},
	shadows: [
		'none',
		'',
		'',
		'',
		'',
		'',
		'',
		theme.shadow.first,
		theme.shadow.second,
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		'',
		''
	],
	typography: {
		title: {
			fontSize: pxToRem(50),
			fontWeight: 700
		},
		h1: {
			fontSize: pxToRem(30),
			lineHeight: '48px'
		},
		h2: {
			fontSize: pxToRem(24),
			lineHeight: '40px'
		},
		h3: {
			fontSize: pxToRem(22),
			lineHeight: '40px'
		},
		h4: {
			fontSize: pxToRem(18),
			lineHeight: '32px'
		},
		h5: {
			fontSize: pxToRem(16),
			lineHeight: '27px'
		},
		paragraph1: {
			fontSize: pxToRem(14),
			lineHeight: '24px'
		},
		paragraph2: {
			fontSize: pxToRem(13),
			lineHeight: '24px'
		},
		paragraph3: {
			fontSize: pxToRem(12),
			lineHeight: '20px'
		}
	},
	components: {
		MuiButton: {
			defaultProps: {
				disableElevation: true
			},
			styleOverrides: {
				root: {
					textTransform: 'initial',
					paddingRight: 12,
					paddingLeft: 12,
					lineHeight: '22px',
					minHeight: 'none'
				},
				contained: {
					'&.Mui-disabled': {
						backgroundColor: alpha(theme.grey.fourth, 0.5),
						color: theme.grey.second
					}
				},
				fullWidth: {
					'&$contained:not($containedSizeSmall):not($containedSizeLarge)': {
						height: '100%'
					}
				},
				containedSecondary: {
					backgroundColor: theme.primary.alternative,
					color: theme.primary.main,
					'&:hover': {
						backgroundColor: alpha(theme.primary.main, 0.4)
					},
					'&:active': {
						backgroundColor: alpha(theme.primary.main, 0.6)
					}
				},
				outlined: {
					borderWidth: '2px !important'
				},
				text: {
					fontWeight: 400,
					'& .MuiButton-label': {
						justifyContent: 'space-between'
					}
				},
				sizeSmall: {
					fontSize: pxToRem(12)
					// height: 32
				},
				outlinedSizeSmall: {
					borderWidth: '1px !important'
				},
				outlinedSizeMedium: {
					padding: '7px 15px'
				},
				outlinedSizeLarge: {
					padding: '11px 15px'
				},
				sizeMedium: {
					fontSize: pxToRem(14),
					padding: '9px 16px'
					// height: 38
				},
				sizeLarge: {
					fontSize: pxToRem(16),
					padding: '13px 15px',
					// height: 48,
					lineHeight: '22px'
				}
			}
		},
		MuiSpeedDialAction: {
			styleOverrides: {
				fab: {
					'&.Mui-disabled': {
						color: theme.grey.second,
						backgroundColor: alpha(theme.grey.fourth, 0.5)
					}
				}
			}
		},
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					paragraph1: 'small'
				}
			}
		},
		MuiIconButton: {
			styleOverrides: {
				edgeEnd: {
					marginRight: '0 !important'
				}
			}
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					textTransform: 'initial'
				}
			}
		},
		MuiLoadingButton: {
			defaultProps: {
				pendingIndicator: <DotsLoader />
			},
			styleOverrides: {
				pending: {
					backgroundColor: theme.primary.main,
					color: 'white !important'
				}
			}
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					padding: '24px 24px 8px'
				}
			}
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					padding: '24px',
					overflowY: undefined
				}
			}
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					padding: '24px',
					paddingTop: '16px'
				}
			}
		},
		MuiDialog: {
			defaultProps: {
				fullWidth: true
			},
			styleOverrides: {
				paper: {
					borderRadius: '12px'
				},
				paperScrollBody: {
					'& .MuiDialogContent-root': {
						overflowY: 'hidden'
					}
				},
				paperWidthMd: {
					maxWidth: '700px'
				},
				paperWidthXs: {
					maxWidth: '500px'
				}
			}
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					padding: '8px 25px',
					fontSize: pxToRem(14),
					fontWeight: 400,
					textTransform: 'none',
					borderColor: theme.border,
					color: theme.text.main,
					borderWidth: 1.5,
					letterSpacing: 'normal',
					lineHeight: 'normal',
					'&.Mui-selected': {
						borderWidth: 2,
						color: theme.primary.main,
						backgroundColor: alpha(theme.primary.main, 0.35),
						borderColor: alpha(theme.primary.main, 0.5),
						fontWeight: 500,
						'&:hover': {
							backgroundColor: alpha(theme.primary.main, 0.25)
						}
					}
				}
			}
		},
		MuiToggleButtonGroup: {
			styleOverrides: {
				root: {
					display: undefined
				},
				groupedHorizontal: {
					marginBottom: '10px !important',
					'&:not(:last-child)': {
						borderTopRightRadius: 4,
						borderBottomRightRadius: 4,
						marginRight: 20
					},
					'&:not(:first-child)': {
						borderTopLeftRadius: 4,
						borderBottomLeftRadius: 4,
						borderLeft: undefined
					},
					'&:not(:first-child):not(.Mui-selected)': {
						borderLeft: `1px solid ${theme.border}`
					},
					'&.Mui-selected + &.Mui-selected': {
						borderLeft: undefined
					}
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: theme.secondary.darkPurple,
					color: theme.tag.white,
					fontSize: pxToRem(12),
					padding: 12,
					borderRadius: 8,
					width: 'auto'
				},
				arrow: {
					color: theme.secondary.darkPurple
				}
			}
		},
		MuiCircularProgress: {
			defaultProps: {
				color: 'inherit',
				size: 24
			}
		},
		MuiStepper: {
			defaultProps: {
				alternativeLabel: true
			},
			styleOverrides: {
				horizontal: {
					'@media only screen and (min-width: 960px)': {
						width: '139%',
						marginLeft: '-19%'
					}
				},
				vertical: {
					width: 'fit-content'
				}
			}
		},
		MuiAppBar: {
			defaultProps: {
				position: 'fixed',
				elevation: 0
			},
			styleOverrides: {
				root: {
					borderBottom: '1px solid',
					borderBottomColor: theme.grey.third
				},
				positionFixed: {
					zIndex: baseTheme.zIndex.drawer + 1
				},
				positionAbsolute: {
					zIndex: baseTheme.zIndex.drawer + 1
				},
				colorPrimary: {
					backgroundColor: theme.background.light,
					color: theme.text.main
				}
			}
		},
		MuiBadge: {
			defaultProps: {
				color: 'error',
				invisible: false
			},
			styleOverrides: {
				colorError: {
					color: '#fff',
					backgroundColor: theme.tag.red
				}
			}
		},
		// MuiPickersDateRangePickerInput: {
		// 	styleOverrides: {
		// 		root: {
		// 			alignItems: 'center'
		// 		}
		// 	}
		// },
		MuiTimelineItem: {
			styleOverrides: {
				missingOppositeContent: {
					'&:before': {
						content: null
					}
				}
			}
		},
		MuiTimelineDot: {
			defaultProps: {
				color: 'grey'
			},
			styleOverrides: {
				root: {
					boxShadow: undefined
				},
				filledGrey: {
					backgroundColor: theme.border
				},
				outlinedGrey: {
					borderColor: theme.border
				}
			}
		},
		MuiTimelineConnector: {
			styleOverrides: {
				root: {
					backgroundColor: theme.grey.fourth
				}
			}
		},
		MuiTimelineContent: {
			styleOverrides: {
				root: {
					padding: '8px 16px 6px 24px'
				}
			}
		},
		MuiDrawer: {
			defaultProps: {
				variant: 'permanent'
			},
			styleOverrides: {
				root: {
					flexShrink: 0,
					width: 255
				},
				paper: {
					width: 255
				},
				paperAnchorDockedLeft: {
					borderRight: 0
				}
			}
		},
		MuiAlert: {
			defaultProps: {
				variant: 'filled',
				severity: 'error'
			},
			styleOverrides: {
				filledError: {
					alignItems: 'center',
					justifyContent: 'center',
					fontSize: pxToRem(14),
					borderRadius: 0
				}
			}
		},
		MuiStepLabel: {
			defaultProps: {
				StepIconComponent: StepIcon
			},
			styleOverrides: {
				label: {
					fontSize: pxToRem(18),
					fontWeight: 400,
					'&.Mui-active': {
						fontWeight: 400
					}
				},
				horizontal: {
					'& .MuiStepLabel-labelContainer': {
						maxWidth: 110
					}
				},
				alternativeLabel: {
					'& .MuiStepLabel-label': {
						marginTop: 5
					}
				}
			}
		},
		MuiTextField: {
			defaultProps: {
				fullWidth: true,
				variant: 'standard'
			},
			styleOverrides: {
				root: { label: pxToRem(14) }
			}
		},
		MuiSelect: {
			styleOverrides: {
				icon: {
					right: 10
				},
				selectMenu: {
					borderRadius: 'inherit'
				},
				select: {
					'&:focus': {
						borderRadius: 'inherit'
					}
				}
			}
		},
		MuiInputLabel: {
			defaultProps: {
				shrink: true
			},
			styleOverrides: {
				root: {
					fontSize: pxToRem(14),
					color: theme.text.main
				},
				shrink: {
					marginBottom: 8,
					position: 'initial',
					transform: 'unset',
					transformOrigin: 'unset'
				},
				asterisk: {
					color: theme.tag.red
				}
			}
		},
		MuiRadio: {
			defaultProps: {
				color: 'primary'
			},
			styleOverrides: {
				root: {
					// remove when migrate radio to emotion
					'&&': {
						color: theme.grey.fourth
					}
				}
			}
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontSize: pxToRem(12)
				}
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					paddingTop: 12
				}
			}
		},
		MuiInput: {
			defaultProps: {
				disableUnderline: true
			},
			styleOverrides: {
				root: {
					borderRadius: 6,
					border: '1px solid',
					borderColor: theme.border,
					fontSize: pxToRem(14),
					marginTop: '0 !important',
					'&.Mui-focused': {
						borderColor: theme.primary.main
					},
					'&.Mui-error': {
						borderColor: theme.tag.red
					},
					'&.Mui-disabled': {
						borderColor: theme.border,
						backgroundColor: alpha(theme.grey.fourth, 0.5),
						color: theme.grey.second
					}
				},
				input: {
					padding: '10px 15px',
					height: 'unset',
					minHeight: 'unset',
					'&::placeholder': {
						fontSize: pxToRem(12),
						color: theme.text.description
					}
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					'&.Mui-disabled': {
						WebkitTextFillColor: null
					}
				},
				multiline: {
					height: 'auto !important'
				},
				input: {
					fontSize: pxToRem(12),
					'&::placeholder': {
						fontSize: pxToRem(12),
						color: theme.text.description
					}
				}
			}
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					backgroundColor: theme.grey.fifth,
					borderBottom: 'none',
					borderTopLeftRadius: undefined,
					borderTopRightRadius: undefined,
					borderRadius: 10,
					'&::before': {
						display: 'none'
					},
					'&:hover': {
						backgroundColor: 'rgba(0, 0, 0, 0.06)'
					}
				},
				inputHiddenLabel: {
					paddingTop: 10,
					paddingBottom: 10
				},
				adornedStart: {
					display: 'flex',
					alignItems: 'center'
				},
				underline: {
					'&::before': {
						display: 'none'
					},
					'&::after': {
						display: 'none'
					},
					'&:hover:not(.Mui-disabled):before': {
						borderBottom: 'none'
					}
				}
			}
		},
		MuiAutocomplete: {
			defaultProps: {
				renderOption: (props, option, { inputValue }) => (
					<Highlighted text={option} search={inputValue} {...props} />
				),
				autoHighlight: true,
				handleHomeEndKeys: true,
				ChipProps: {
					style: {
						maxWidth: '75%'
					}
				}
			},
			styleOverrides: {
				// root: {
				// 	'& .MuiFormHelperText-root': {
				// 		color: theme.grey.main
				// 	}
				// },
				endAdornment: {
					right: 10,
					top: undefined
				},
				inputRoot: {
					paddingLeft: 4,
					paddingTop: 6.5,
					'&.MuiInput-root': {
						paddingBottom: 6.5,
						'& .MuiInput-input': {
							padding: '3.5px 11px'
						},
						'& .MuiInput-input:first-child': {
							padding: '3.5px 11px'
						}
					},
					// TODO: remove when launch version alpha 26
					'& .MuiAutocomplete-clearIndicator': {
						padding: 2,
						marginRight: 2
					},
					'& .MuiAutocomplete-popupIndicator': {
						padding: 2,
						marginRight: -2
					}
				},
				tag: {
					margin: '3px !important'
				},
				paper: {
					padding: '2px 8px',
					border: `1px solid ${theme.border}`,
					boxShadow: `0px 2px 5px rgba(112, 144, 176, 0.1)`
				},
				option: {
					borderRadius: 4,
					'&[data-focus="true"]': {
						backgroundColor: `${alpha(theme.primary.main, 0.1)} !important`,
						color: theme.primary.main
					}
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				elevation7: {
					boxShadow: theme.shadow.first,
					backgroundColor: theme.background.light
				},
				elevation8: {
					boxShadow: theme.shadow.second
				},
				elevation9: {
					boxShadow: '0 5px 15px 0 rgba(68, 68, 79, 0.1)'
				},
				rounded: {
					borderRadius: 6
				},
				outlined: {
					border: `1px solid ${theme.border}`
				}
			}
		},
		MuiMenu: {
			styleOverrides: {
				paper: {
					border: `1px solid ${theme.border}`,
					boxShadow: theme.shadow.third
				}
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					// TODO: Remove important when launch version alpha 26 of material ui
					'&&': {
						paddingTop: 8,
						paddingBottom: 8
					},
					fontSize: pxToRem(14),
					outline: 'none',
					paddingRight: '16px',
					paddingLeft: '16px',
					'&.Mui-selected': {
						fontWeight: 500,
						backgroundColor: alpha(theme.primary.main, 0.1)
					}
				}
			}
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderColor: theme.border
				},
				wrapper: {
					color: theme.grey.second,
					fontSize: pxToRem(14),
					paddingLeft: 20,
					paddingRight: 20,
					border: theme.border
				},
				wrapperVertical: {
					paddingTop: 20,
					paddingBottom: 20
				},
				withChildren: {
					'&::after, &::before': {
						borderColor: theme.border
					}
				},
				withChildrenVertical: {
					'&::after, &::before': {
						borderColor: theme.border
					}
				}
			}
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						color: theme.primary.main,
						backgroundColor: null,
						'& .MuiListItemIcon-root': {
							color: 'inherit'
						},
						'& .MuiListItemText-root .MuiTypography-root': {
							fontWeight: 500
						}
					}
				},
				secondaryAction: {
					paddingRight: undefined,
					paddingBottom: 40
				}
			}
		},
		MuiListItemText: {
			defaultProps: {
				secondaryTypographyProps: {
					variant: 'body1'
				}
			},
			styleOverrides: {
				secondary: {
					paddingRight: 5
				}
			}
		},
		MuiAccordion: {
			defaultProps: {
				elevation: 0
			},
			styleOverrides: {
				root: {
					'&:before': {
						content: '',
						display: 'block !important',
						backgroundColor: theme.grey.fourth
					}
				}
			}
		},
		MuiAccordionSummary: {
			defaultProps: {
				expandIcon: <ExpandMoreIcon />
			}
		},
		MuiSlider: {
			defaultProps: {
				color: 'primary'
			},
			styleOverrides: {
				root: {
					height: 3,
					padding: '13px 0',
					[`& *[class*=MuiSlider-markLabel][style="left: 100%;"]`]: {
						left: 'unset !important',
						right: 0
					},
					[`& *[class*=MuiSlider-markLabel]`]: {
						transform: 'unset'
					}
				},
				thumb: {
					height: 30,
					width: 30,
					padding: 2,
					border: '2px solid #fff',
					marginTop: -13,
					'&:last-child': {
						marginLeft: -30
					},
					boxShadow: '0 7px 24px 0 rgba(0, 0, 0, 0.07), 0 2px 6px 0 rgba(0, 0, 0, 0.1)',
					'&:focus, &:hover, &.Mui-active': {
						boxShadow: 'rgba(0, 0, 0, 0.07) 0 2px 3px 1px'
					}
				},
				mark: {
					display: 'none'
				},
				valueLabel: {
					marginLeft: 15
				},
				rail: {
					color: theme.grey.fourth,
					opacity: 1,
					height: 3
				},
				markLabel: {
					color: theme.text.main,
					fontSize: pxToRem(14),
					top: 30,
					transform: undefined
				},
				track: {
					height: 3
				}
			}
		},
		MuiTabs: {
			defaultProps: {
				indicatorColor: 'primary'
			},
			styleOverrides: {
				indicator: {
					right: undefined,
					left: 0,
					width: 3,
					borderRadius: '0 100px 100px 0'
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: 'initial',
					fontSize: pxToRem(14),
					padding: '12px 14px',
					fontWeight: 400,
					'&.Mui-selected': {
						color: theme.primary.main,
						fontWeight: 500,
						'& .MuiTab-wrapper .MuiListItemIcon-root': {
							color: 'inherit'
						}
					},
					color: theme.text.main,
					minHeight: '60px'
				},
				labelIcon: {
					'& .MuiTab-wrapper > *:first-child': {
						marginBottom: 0
					}
				},
				wrapper: {
					flexDirection: 'row',
					justifyContent: 'flex-start',
					display: 'flex',
					'& .MuiListItemIcon-root': {
						minWidth: 55
					}
				}
			}
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					fontSize: pxToRem(24),
					color: theme.text.main
				}
			}
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					fontSize: '1rem'
				},
				square: {
					borderRadius: 12
				},
				colorDefault: {
					color: theme.tag.white,
					backgroundColor: theme.grey.third
				}
			}
		},
		MuiPagination: {
			styleOverrides: {
				root: {
					'& li:first-child .MuiPaginationItem-root, & li:last-child .MuiPaginationItem-root': {
						border: `1px solid ${theme.grey.fourth}`,
						borderRadius: 8,
						color: theme.mode === 'light' ? theme.grey.main : theme.tag.white
					},
					'& li:first-child .MuiPaginationItem-root': {
						marginRight: 15
					},
					'& li:last-child .MuiPaginationItem-root': {
						marginLeft: 15
					}
				}
			}
		},
		MuiPaginationItem: {
			defaultProps: {
				variant: 'text'
			},
			styleOverrides: {
				root: {
					fontSize: pxToRem(14)
				},
				page: {
					borderRadius: '8px !important',
					'&.Mui-selected': {
						backgroundColor: 'unset',
						color: theme.text.link,
						fontWeight: 'bold'
					}
				}
			}
		},
		MuiStepConnector: {
			styleOverrides: {
				alternativeLabel: {
					top: 0,
					transform: 'translateY(700%)',
					left: 'calc(-50% + 32px)',
					right: 'calc(50% + 32px)'
				},
				root: {
					'&.Mui-active': {
						'& .MuiStepConnector-line': {
							borderColor: theme.primary.main
						}
					}
				},
				completed: {
					'& .MuiStepConnector-line': {
						borderColor: theme.primary.main
					}
				},
				line: {
					borderColor: theme.border,
					borderRadius: 3
				},
				vertical: {
					marginLeft: 18,
					flex: 'unset'
				},
				lineVertical: {
					minHeight: 60,
					width: 1,
					borderLeftWidth: 3
				},
				lineHorizontal: {
					borderTopWidth: 3
				}
			}
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					fontSize: pxToRem(14),
					'&.Mui-disabled': {
						color: lightTheme.grey.second
					}
				}
			}
		},
		MuiCheckbox: {
			defaultProps: {
				icon: <CheckboxIcon />,
				checkedIcon: <CheckboxChecked />
			}
		},
		MuiSwitch: {
			styleOverrides: {
				switchBase: {
					'&&': {
						color: '#fafafa',
						position: 'absolute',
						transition:
							'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
					}
				}
			}
		},
		MuiChip: {
			defaultProps: {
				color: 'primary',
				variant: 'modern',
				deleteIcon: <CloseIcon />
			},
			styleOverrides: {
				root: {
					height: 24,
					'&:hover': {
						backgroundColor: alpha(theme.primary.main, 0.2)
					}
				},
				sizeSmall: {
					fontWeight: 500,
					padding: '5px 0'
				},
				label: {
					fontSize: pxToRem(10)
				},
				deleteIconColorPrimary: {
					color: theme.primary.main,
					fontSize: pxToRem(14),
					'&:hover, &:active': {
						color: alpha(theme.primary.main, 0.7)
					}
				}
			},
			variants: [
				{
					props: {
						variant: 'square'
					},
					style: {
						borderRadius: 4
					}
				},
				{
					props: {
						variant: 'square',
						color: 'primary'
					},
					style: {
						color: theme.primary.main,
						backgroundColor: alpha(theme.primary.main, 0.2)
					}
				},
				{
					props: {
						variant: 'squareFilled'
					},
					style: {
						borderRadius: 4
					}
				},
				{
					props: {
						variant: 'square',
						size: 'small'
					},
					style: {
						'& .MuiChip-label': {
							fontSize: pxToRem(10)
						}
					}
				},
				{
					props: {
						variant: 'modern',
						color: 'primary'
					},
					style: {
						color: theme.primary.main,
						backgroundColor: alpha(theme.primary.main, 0.2)
					}
				},
				{
					props: {
						variant: 'squareOutlined'
					},
					style: {
						borderRadius: 4,
						backgroundColor: 'transparent',
						paddingLeft: 8,
						paddingRight: 8
					}
				}
			]
		}
	}
})

export const materialTheme = createMuiTheme(materialBaseTheme(lightTheme))

const materialBaseDarkTheme = materialBaseTheme(darkTheme)!
const materialDarkThemeComponents = materialBaseDarkTheme.components!

export const materialDarkTheme = createMuiTheme({
	...materialBaseDarkTheme,
	// @ts-ignore
	palette: {
		...materialBaseDarkTheme.palette,
		mode: 'dark',
		text: {
			...materialBaseDarkTheme.palette!.text
		}
	},
	components: {
		...materialBaseDarkTheme.components,
		MuiStepConnector: {
			...materialDarkThemeComponents.MuiStepConnector,
			styleOverrides: {
				...materialDarkThemeComponents.MuiStepConnector!.styleOverrides,
				lineHorizontal: {
					...materialDarkThemeComponents.MuiStepConnector!.styleOverrides!.lineHorizontal,
					borderColor: alpha(darkTheme.text.description, 0.2)
				}
			}
		},
		MuiInput: {
			...materialBaseDarkTheme.components!.MuiInput,
			styleOverrides: {
				...materialDarkThemeComponents.MuiInput!.styleOverrides,
				root: {
					...materialDarkThemeComponents.MuiInput!.styleOverrides!.root,
					'&.Mui-disabled': {
						...materialDarkThemeComponents.MuiInput!.styleOverrides!.root![
							'&.Mui-disabled'
						],
						borderColor: alpha(darkTheme.grey.fourth, 0.5),
						color: darkTheme.grey.third,
						backgroundColor: alpha(darkTheme.grey.fourth, 0.5)
					}
				}
			}
		},
		MuiButton: {
			...materialDarkThemeComponents.MuiButton,
			styleOverrides: {
				...materialDarkThemeComponents.MuiButton!.styleOverrides,
				contained: {
					...materialDarkThemeComponents.MuiButton!.styleOverrides!.contained,
					'&.Mui-disabled': {
						...materialDarkThemeComponents.MuiButton!.styleOverrides!.contained![
							'&.Mui-disabled'
						],
						backgroundColor: darkTheme.grey.third,
						color: darkTheme.grey.second
					}
				}
			}
		}
	}
})
