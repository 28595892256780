import React from 'react'
import { Grid, Typography, useMediaQuery, Theme } from '@material-ui/core'
import { useStoreon } from 'storeon/react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'
import { useUpdateUserMutation } from 'hooks/useApollo'
import HandsWavingIcon from 'assets/icons/HandsWavingIcon'
import useIntl from 'hooks/useIntl'
import { FilterProvider } from 'hooks/useFilter'
import NyomiModal from 'components/NyomiModal'
import NyomiSadIcon from 'assets/icons/NyomiIcons/NyomiSadIcon'
import AlertStack from 'components/AlertStack'
import useUserSubscriptions from 'hooks/useUserSubscriptions'
import { DashboardWelcome } from './Dashboard.style'
import { FirstAccessDashboard, MainDashboard } from './containers'

export const Dashboard = () => {
	const intl = useIntl()
	const navigate = useNavigate()
	const theme = useTheme()
	const { dispatch, user: _user } = useStoreon('user')
	const user = _user as NonNullable<typeof _user>
	const [updateUser] = useUpdateUserMutation()
	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
	const [{ isOnBenchmarkingPlan, isOnHiringPlan }] = useUserSubscriptions()

	const handleCloseTrialExpiredModal = () => {
		if (!user?.isFreeTrialAcknowledged) {
			dispatch('user/setUser', { ...user, isFreeTrialAcknowledged: true })
		}
		updateUser({ variables: { userInput: { isFreeTrialAcknowledged: true } } })
	}

	return (
		<>
			<AlertStack />
			<DashboardWelcome>
				<Grid container spacing={2} alignItems="center" sx={{ marginBottom: '12px' }}>
					<Grid item>
						<Typography variant="h2" fontWeight={500}>
							{intl.formatMessage(
								{ id: 'dashboard.welcome.hello' },
								{ name: user?.firstName }
							)}
						</Typography>
					</Grid>
					<Grid item>
						<HandsWavingIcon style={{ marginTop: 5, fontSize: pxToRem(24) }} />
					</Grid>
				</Grid>
				<Typography variant="paragraph1" sx={{ color: theme.text.description }}>
					{intl.formatMessage({
						id: 'dashboard.welcome.firstTime.welcome'
					})}{' '}
					{intl.formatMessage({
						id: user?.isFirstAccess
							? 'dashboard.welcome.firstTime.createTeam'
							: 'dashboard.welcome.createTeam'
					})}
				</Typography>
			</DashboardWelcome>
			{user?.isFirstAccess ? (
				<FirstAccessDashboard />
			) : (
				<FilterProvider hasMenu={!!smUp}>
					<MainDashboard />
				</FilterProvider>
			)}
			<NyomiModal
				// @ts-ignore
				open={
					isOnBenchmarkingPlan || isOnHiringPlan
						? false
						: !user?.isFreeTrialAcknowledged && user?.trialDetails?.hasExpired
				}
				onClose={handleCloseTrialExpiredModal}
				title={intl.formatMessage({ id: 'dashboard.trialExpired.title' })}
				desc={intl.formatMessage({
					id: 'dashboard.trialExpired.desc'
				})}
				onOk={() => navigate('/settings/billing')}
				nyomiIcon={NyomiSadIcon}
				btnLabel={intl.formatMessage({ id: 'activatePlan' })}
				subBtnLabel={intl.formatMessage({ id: 'viewDashboard' })}
			/>
		</>
	)
}

export default Dashboard
