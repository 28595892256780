import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

export interface GoogleProps extends SvgIconProps {}

export const Google = (props: SvgIconProps) => (
	<SvgIcon {...props} width="21" height="21" viewBox="0 0 21 21" fill="none">
		<path
			d="M10.4 4.02133C12.3529 4.02133 13.6703 4.86489 14.4214 5.56978L17.3565 2.704C15.5538 1.02844 13.208 0 10.4 0C6.33249 0 2.8196 2.33422 1.10938 5.73156L4.47204 8.34311C5.3156 5.83556 7.64982 4.02133 10.4 4.02133Z"
			fill="#EA4335"
		/>
		<path
			d="M20.3844 10.6321C20.3844 9.77697 20.3151 9.15297 20.1648 8.50586H10.4004V12.3654H16.1319C16.0164 13.3245 15.3924 14.769 14.0057 15.7396L17.2875 18.2819C19.2519 16.4676 20.3844 13.7983 20.3844 10.6321Z"
			fill="#4285F4"
		/>
		<path
			d="M4.48356 12.4578C4.264 11.8106 4.13689 11.1173 4.13689 10.4009C4.13689 9.68442 4.264 8.99109 4.472 8.34398L1.10933 5.73242C0.404444 7.1422 0 8.72531 0 10.4009C0 12.0764 0.404444 13.6595 1.10933 15.0693L4.48356 12.4578Z"
			fill="#FBBC05"
		/>
		<path
			d="M10.4002 20.8001C13.2082 20.8001 15.5655 19.8757 17.2873 18.281L14.0055 15.7388C13.1273 16.3513 11.9486 16.7788 10.4002 16.7788C7.64998 16.7788 5.31576 14.9646 4.48376 12.457L1.12109 15.0686C2.83132 18.4659 6.33265 20.8001 10.4002 20.8001Z"
			fill="#34A853"
		/>
	</SvgIcon>
)

export default Google
