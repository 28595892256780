export const ur = {
  switchTheme: "تھیم تبدیل کریں",
  next: "اگلا",
  total: "مجموعی",
  stepOf: "مرحلہ {current} میں سے {length}",
  industryBench: "صنعتی معیار",
  industryComingSoon: "صنعتی اوسط جلد آرہا ہے",
  employees: "ملازمین",
  slogan: "عالمی ٹیموں کے ذریعہ پسندیدہ اور قابل اعتماد",
  "estimated.time": "مقامی وقت",
  "changePassword.title": "پاس ورڈ تبدیل کریں",
  "auth.createAccount": "اکاؤنٹ بنائیں",
  "auth.createAnAccount": "ایک اکاؤنٹ بنائیں",
  "auth.createYourAccount": "اپنا اکاؤنٹ بنائیں",
  "auth.createProfile": "پروفائل بنائیں",
  "auth.signIn": "سائن ان کریں",
  "auth.signUp": "سائن اپ کریں",
  "auth.login": "لاگ ان کریں",
  "auth.signInAccount": "اپنے اکاؤنٹ میں سائن ان کریں",
  "auth.acceptTerms":
    "اکاؤنٹ بنانے کا مطلب ہے کہ آپ ہمارے {terms} اور {privacy} سے اتفاق کرتے ہیں <red>*</red>",
  "auth.terms": "سروس کی شرائط",
  "auth.privacy": "پرائیویسی پالیسی",
  "auth.dontHaveAnAccount": `کیا آپ کے پاس اکاؤنٹ نہیں ہے؟`,
  "auth.haveAccount": "کیا آپ کا پہلے سے اکاؤنٹ ہے؟",
  "auth.signUpWith": "{name} کے ساتھ سائن اپ کریں",
  "auth.signInWith": "{name} کے ساتھ سائن ان کریں",
  "auth.message.quote": `نتائج شاندار تھے اور ہم نے ان میں سے ایک کو ملا لیا۔
      مجھے امید ہے کہ میں نے ان کے ساتھ شروع کیا ہوتا، اس سے پہلے کہ میں تقریباً 500 درخواستوں سے گزرتا!`,
  "validation.lowercase":
    "{length, plural, =1 {ایک} other {#}} چھوٹے حروف {length, plural, =1 {حرف} other {حروف}}",
  "validation.uppercase":
    "{length, plural, =1 {ایک} other {#}} بڑے حروف {length, plural, =1 {حرف} other {حروف}}",
  "validation.special":
    "{length, plural, =1 {ایک} other {#}} خصوصی {length, plural, =1 {حرف} other {حروف}}",
  "validation.number":
    "{length, plural, =1 {ایک} other {#}} {length, plural, =1 {عدد} other {اعداد}}",
  "validation.minLength":
    "{length, plural, =0 {} =1 {ایک حرف کم سے کم} other {# حروف کم سے کم}}",
  "validation.maxLength": "زیادہ سے زیادہ لمبائی {length, number}",
  "validation.emailIncluded": "یہ ای میل پہلے ہی شامل ہے",
  "auth.forgotPassword": "پاس ورڈ بھول گئے؟",
  "nav.organizations": "تنظیمیں",
  "nav.addOrg": "نئی تنظیم شامل کریں",
  "nav.personalInformation": "اکاؤنٹ",
  "nav.campaigns": "مہمات",
  "nav.integrations": "انضمام",
  "nav.company": "کمپنی",
  "nav.security": "سیکورٹی",
  "nav.billing": "بلنگ",
  "statusButton.nextStatus": "اگلا اسٹیٹس:",
  "invite.challenge": "ٹیلنٹ کو مدعو کریں",
  "invite.challengeLink": "چیلنج لنک کو {type} کو بھیجیں",
  "invite.challenge.success":
    "{name} کا چیلنج لنک کامیابی سے کلپ بورڈ پر کاپی کر لیا گیا",
  "invite.challenge.uploadEmailFileDropZone":
    "اپنا فائل یہاں ڈریگ اور ڈراپ کریں",
  "talentGroup.menu.emailReminder": "ای میل یاد دہانی بھیجیں",
  "talentGroup.menu.copyChallenge": "چیلنج کو کاپی کریں",
  "talentGroup.menu.deleteGroup": "گروپ کو حذف کریں",
  "talentGroup.sendNotification":
    "نیا ٹیلنٹ مدعو کرنے کے لئے ای میل نوٹیفیکیشن بھیجیں",
  "dashboard.welcome.hello": "ہیلو، {name}",
  "dashboard.emailVerified": "ای میل کی تصدیق ہو گئی ہے!",
  "dashboard.welcome.firstTime.welcome": "آپ کے ڈیش بورڈ میں خوش آمدید!",
  "dashboard.welcome.firstTime.createTeam": "شروع کرنے کے لئے ایک ٹیم بنائیں",
  "dashboard.welcome.createTeam": "ایک ٹیم میں کلک کریں یا ایک نئی ٹیم بنائیں",
  "dashboard.welcome.box.title": `شروع کریں!`,
  "dashboard.welcome.box.subText":
    "اپنی پہلی ٹیم بنائیں تاکہ ٹیلنٹ کو ہائر کریں یا ملازمین کی مہارتوں کا جائزہ لیں",
  "dashboard.welcome.box.buttonText": "ٹیم بنائیں",
  "dashboard.welcome.box.trialEnded":
    "آپ کی مفت ٹرائل ختم ہو گئی ہے یا آپ کا منصوبہ منسوخ کر دیا گیا ہے۔",
  "dashboard.welcome.firstTime.naomi.welcome": "آپ کے ڈیش بورڈ میں خوش آمدید!",
  "dashboard.welcome.firstTime.naomi.welcomeBalloon": "خوش آمدید!",
  "dashboard.welcome.firstTime.naomi.description": `میں ناعومی ہوں، پلیٹ فارم کے پیچھے طاقتور اے آئی انجن، اور میں آپ کی ٹیمیں بنانے، مہارتوں کا اندازہ کرنے اور وقت کے ساتھ تجزیہ کرنے میں مدد کرنے کے لیے یہاں ہوں۔`,
  "dashboard.welcome.firstTime.seeDashboard": "میرا ڈیش بورڈ دیکھیں!",
  "dashboard.welcome.firstTime.welcomeMessage": `میں ناعومی ہوں، پلیٹ فارم کے پیچھے طاقتور اے آئی انجن، اور میں آپ کی ٹیمیں بنانے، مہارتوں کا اندازہ کرنے اور وقت کے ساتھ تجزیہ کرنے میں مدد کرنے کے لیے یہاں ہوں`,
  "dashboard.trialExpired.title": "آپ کی 14 دن کی مفت ٹرائل ختم ہو گئی ہے",
  "dashboard.trialExpired.desc":
    "فکر نہ کریں، آپ اب بھی اپنے تمام ڈیٹا تک رسائی حاصل کر سکتے ہیں لیکن آپ کا پروفائل اب لاک ہو گیا ہے اور اس میں محدود ایکشنز ہیں۔ مکمل رسائی کے لیے ایک منصوبہ منتخب کریں۔",
  "teams.challengeDetails": "چیلنج کی تفصیلات دیکھیں",
  "teams.addTalent": "ٹیلنٹ کو مدعو کریں",
  "talentGroup.createNewGroup": "نیا گروپ بنائیں",
  "teams.createGroup": "گروپ بنائیں",
  "teams.talentGroup": "ٹیلنٹ گروپ",
  "teams.attempts": "کوششیں",
  "teams.totalTalent": "مجموعی {total, plural, =1 {ٹیلنٹ: } other {ٹیلنٹس: }}",
  "teams.totalGroup": "مجموعی {total, plural, =1 {گروپ: } other {گروپس: }}",
  "teams.searchInputPlaceholder": "ٹیلنٹ، ٹیلنٹ گروپ تلاش کریں",
  "teams.searchGroupPlaceholder": "ٹیم کے نام سے تلاش کریں",
  "teams.profile.noTalentSelect": "کوئی ٹیلنٹ منتخب نہیں کیا گیا",
  "teams.profile.noTalentFound": "کوئی ٹیلنٹ نہیں ملا",
  "teams.profile.noTalentAdded": "اب تک کوئی ٹیلنٹ نہیں شامل کیا گیا",
  "teams.profile.clickOnProfile":
    "ان کے پروفائل دیکھنے کے لئے {direction} پر ٹیلنٹ پر کلک کریں!",
  "teams.talent": "ٹیلنٹ",
  "teams.talent.filter.progress": "ترقی",
  "teams.talent.filter.createdat": "آخری مرتبہ شامل کیا گیا",
  "teams.talent.filter.updatedat": "آخری مرتبہ اپ ڈیٹ کیا گیا",
  "teams.talent.filter.score": "اسکور",
  "teams.talent.filter.name": "نام",
  "teams.talentGroups.filter": "دکھائیں:",
  "teams.talentGroups.filter.all": "سب",
  "teams.talentGroups.filter.internal": "ملازمین",
  "teams.talentGroups.filter.external": "امیدوار",
  "teams.talentProfile": "ٹیلنٹ پروفائل",
  "teams.talentDynamic": "ٹیلنٹ ڈائنامک",
  "teams.talentProfile.profile": "نگٹ پروفائل دیکھیں",
  "teams.talentProfile.profileTooltip":
    "{name} کا عوامی نگٹ پروفائل ہے۔ دیکھنے کے لئے کلک کریں",
  "teams.talentDynamic.talent.description":
    "اوپر والے گراف میں ٹیگ کو پن کرنے کے لئے نیچے والے ٹیلنٹ ٹیگ پر کلک کریں",
  "teams.talent.all": "سب ({total})",
  "teams.talent.employee": "ملازمین ({total})",
  "teams.talent.candidate": "امیدوار ({total})",
  "jobs.notFound": "کوئی ملازمتیں نہیں ملیں!",
  "jobs.assignTeamMate": "ٹیم میٹ کو تفویض کریں",
  "jobs.assignTeamMate.placeholder": "صارف کا نام ٹائپ کریں، پھر انٹر دبائیں",
  "jobs.assignTeamMate.jobRole": "ملازمت کا کردار",
  "jobs.import": "ملازمتیں درآمد کریں",
  "jobs.noJobs":
    "آپ 'نئی ملازمت تخلیق کریں' بٹن پر کلک کر کے ملازمتیں تخلیق کرنا شروع کر سکتے ہیں!",
  "jobs.entryLevel": "داخلہ سطح - {jobType}",
  "jobs.closeDate": "بند ہونے کی تاریخ - {closeDate}",
  "jobs.edit": "ملازمت کو ایڈٹ کریں",
  "jobs.edit.desc":
    "اس مینو میں آپ ملازمت کی ترتیبات کو ایڈٹ کر سکتے ہیں اور دوبارہ جائزے کے لیے پیش کر سکتے ہیں۔",
  "jobs.assign": "تفویض کریں",
  "jobs.assign.desc":
    "اس ملازمت کی پوسٹنگ کے لیے تفویض کردہ ٹیم میٹس کو منظم کریں",
  "jobs.delete": "ملازمت کو حذف کریں",
  "jobs.delete.desc": "ملازمت کی پوسٹنگ کو ہمیشہ کے لیے حذف کریں",
  "jobs.delete.body":
    "کیا آپ کو یقین ہے کہ آپ <bold>{name}</bold> کو حذف کرنا چاہتے ہیں؟ اس کا تمام مواد مستقل طور پر حذف ہو جائے گا اور اسے بحال نہیں کیا جا سکے گا",
  "jobs.public": "عوامی",
  "jobs.public.desc":
    "اسے آن/آف کریں تاکہ یہ کنٹرول کر سکیں کہ ٹیم میں کون اس ملازمت کی پوسٹنگ کو دیکھ سکتا ہے",
  "jobs.createdBy": "تخلیق کیا گیا {name} کے ذریعے",
  "jobs.draft": "مسودہ",
  "jobs.active": "فعال",
  "jobs.permission.0": "ایڈیٹر",
  "jobs.permission.1": "ناظر",
  "jobs.status": "ملازمت کی حیثیت",
  "jobs.type": "ملازمت کی قسم",
  "jobs.creator": "ملازمت کا تخلیق کنندہ",
  "accountSettings.title": "سیٹنگز",
  "accountSettings.account": "اکاؤنٹ",
  "accountSettings.billing": "بلنگ",
  "accountSettings.security": "سیکیورٹی",
  "accountSettings.notification": "اطلاع",
  "accountSettings.emailVerified": "ای میل کی تصدیق ہو گئی",
  "accountSettings.emailNotVerified": "ای میل کی تصدیق نہیں ہو سکی",
  "accountSettings.placeholder.username": "آپ کا پہلا نام",
  "accountSettings.placeholder.lastname": "آپ کا آخری نام",
  "accountSettings.billing.title": "دستیاب منصوبے",
  "accountSettings.billing.bothPlansCancelled.title":
    "آپ کا {plan1} منصوبہ اور {plan2} منسوخ کر دیا گیا ہے",
  "accountSettings.billing.bothPlansCancelled.desc":
    "آپ {plan1} منصوبہ اور {plan2} کی خصوصیات {date} تک استعمال کر سکتے ہیں۔",
  "accountSettings.billing.bothPlansCancelled.subdesc":
    "<bold>نوٹ</bold>: بلنگ سائیکل کے آخر میں ٹیلنٹ بینچ مارکنگ کے لیے آخری ادائیگی وصول کی جائے گی۔",
  "accountSettings.billing.hiring.title":
    "آپ کا ٹیلنٹ اکوزیشن منصوبہ منسوخ کر دیا گیا ہے",
  "accountSettings.billing.hiring.message":
    "آپ {date} تک ٹیلنٹ اکوزیشن کے منصوبے کی خصوصیات استعمال کر سکتے ہیں۔",
  "accountSettings.billing.benchmarking.title":
    "آپ کا ٹیلنٹ بینچ مارکنگ منصوبہ منسوخ کر دیا گیا ہے",
  "accountSettings.billing.benchmarking.message":
    "آپ {date} تک بینچ مارکنگ کے منصوبے کی خصوصیات استعمال کر سکتے ہیں۔ اضافی ملازمین پر آخری ادائیگی کی جائے گی",
  "accountSettings.billing.myPlans": "میرے منصوبے",
  "accountSettings.billing.nextPayment":
    "آپ کی اگلی ادائیگی {date} کو شیڈول کی گئی ہے",
  "accountSettings.billing.isCancelled": "منسوخی شیڈول کی گئی ہے",
  "accountSettings.billing.benchmarking.no.nextPayment":
    "آخری ادائیگی {date} کو شیڈول کی گئی ہے۔",
  "accountSettings.billing.hiring.no.nextPayment": "فعال {date} تک۔",
  "accountSettings.billing.benchmarking.note":
    "* نوٹ: ہم صرف ان ملازمین سے چارج کرتے ہیں جو مکمل چیلنج مکمل کرتے ہیں۔ آپ اس کا اندازہ اس وقت لگا سکتے ہیں جب ان کی پیشرفت 100٪ تک پہنچ جائے۔",
  "accountSettings.billing.desc":
    "آپ اپنے موجودہ منصوبے کے اوپر اضافی منصوبہ شامل کر سکتے ہیں۔",
  "companySettings.drag&DropYourLogo": "اپنا لوگو یہاں ڈریگ اور ڈراپ کریں",
  "companySettings.dropYourFiles": "اپ فائلیں یہاں ڈراپ کریں",
  "org.placeholder.industry": "آپ کی کمپنی کی صنعت",
  "org.placeholder.companySize": "آپ کی کمپنی کا سائز",
  "org.placeholder.name": "آپ کی کمپنی کا نام",
  "org.company": "کمپنی",
  "org.companyName": "کمپنی کا نام",
  "org.companyLogo": "کمپنی کا لوگو",
  "org.yourRole": "آپ کا کردار",
  "org.companySize": "کمپنی کا سائز",
  "org.companyIndustry": "کمپنی کی صنعت",
  "changePassword.currentPassword": "موجودہ پاس ورڈ",
  "changePassword.newPassword": "نیا پاس ورڈ",
  "changePassword.confirmNewPassword": "نیا پاس ورڈ تصدیق کریں",
  "changePassword.passwordError": "پاس ورڈ میل نہیں کھاتے",
  "changePassword.success": "پاس ورڈ کامیابی سے اپ ڈیٹ کر دیا گیا۔",
  "addPassword.success": "پاس ورڈ کامیابی سے شامل کر دیا گیا۔",
  "accountSettings.security.deleteAccount.title": "اکاؤنٹ حذف کریں",
  "accountSettings.security.deleteAccount.description":
    "آپ اس اکاؤنٹ اور اس کے تمام ڈیٹا تک رسائی کھو دیں گے۔ یہ عمل واپس نہیں کیا جا سکتا۔",
  "auth.forgotPassword.0.title": "پاس ورڈ بھول گئے؟",
  "auth.forgotPassword.0.desc": "اپنے اکاؤنٹ سے منسلک ای میل ایڈریس درج کریں",
  "auth.forgotPassword.1.title": "اپنی ای میل کی تصدیق کریں",
  "auth.forgotPassword.1.desc":
    "براہ کرم {email} پر بھیجے گئے 4 ہندسوں کا کوڈ درج کریں",
  "auth.forgotPassword.2.title": "پاس ورڈ ری سیٹ کریں",
  "auth.forgotPassword.2.desc": "براہ کرم اپنا نیا پاس ورڈ درج کریں",
  "auth.forgotPassword.success.title": "مبارک ہو!",
  "auth.forgotPassword.success.msg":
    "آپ کا پاس ورڈ کامیابی سے ری سیٹ ہو چکا ہے",
  "auth.forgotPassword.resetPassword": "پاس ورڈ ری سیٹ کریں",
  "alert.trial.expired":
    "کوئی فعال منصوبہ نہیں۔ براہ کرم سوپرٹیلنٹ استعمال جاری رکھنے کے لیے منصوبہ فعال کریں",
  "alert.benchmarking.plan.canceled":
    "آپ کا {plan} منصوبہ <bold>{date}</bold> کو منسوخ کر دیا جائے گا",
  "alert.hiring.plan.canceled":
    "آپ کا {plan} منصوبہ <bold>{date}</bold> کو منسوخ ہو جائے گا",
  "alert.plans.canceled":
    "آپ کے {first} اور {second} منصوبے {date} کو منسوخ ہو جائیں گے",
  "alert.plan.failed":
    "آپ کے {plan} منصوبے کی آخری ادائیگی ناکام ہو گئی۔ براہ کرم سیٹنگز > بلنگ میں گاہک کے بلنگ پورٹل سے اپنے کریڈٹ کارڈ کو اپ ڈیٹ کریں۔ ہم اگلے 2 ہفتوں میں 4 بار تک دوبارہ کوشش کریں گے اس سے پہلے کہ ہم آپ کا منصوبہ منسوخ کر دیں۔",
  "alert.bothPlans.failed":
    "آپ کے {benchmarking} اور {hiring} منصوبوں کی آخری ادائیگی ناکام ہو گئی۔ براہ کرم سیٹنگز > بلنگ میں گاہک کے بلنگ پورٹل سے اپنے کریڈٹ کارڈ کو اپ ڈیٹ کریں۔ ہم اگلے 2 ہفتوں میں 4 بار تک دوبارہ کوشش کریں گے اس سے پہلے کہ ہم آپ کے منصوبوں کو منسوخ کر دیں۔",
  "alert.remaining.trial.days": "آپ کی آزمائش {value} دن میں ختم ہو جائے گی",
  "alert.trial.endToday": "آپ کی آزمائش آج ختم ہو رہی ہے",

  activatePlan: "پلان فعال کریں",
  reactivatePlan: "پلان کو دوبارہ فعال کریں",
  viewDashboard: "ڈیش بورڈ دیکھیں",
  "header.settings": "سیٹنگز",
  "header.logOut": "لاگ آؤٹ",
  "mailVerification.verify": "براہ کرم اپنے ای میل ایڈریس کی تصدیق کریں",
  "mailVerification.emailSent":
    "ایک تصدیقی ای میل بھیج دی گئی ہے۔ براہ کرم اپنے ان باکس میں لنک چیک کریں تاکہ عمل مکمل کیا جا سکے۔",
  "mailVerification.verified": "آپ کا ای میل تصدیق شدہ ہے!",
  "mailVerification.resendButton": "ای میل دوبارہ بھیجیں",
  "mailVerification.resendButtonMobile": "ای میل کی تصدیق دوبارہ بھیجیں",
  "mailVerification.resendMessage": "ای میل کامیابی سے بھیجی گئی",
  "mailVerification.teams":
    "آپ کو ٹیم بنانے کے لیے اپنے ای میل کی تصدیق کرنی ہوگی!",
  "tooltip.teamDynamic":
    "نیچے کا اسپائیڈر گراف آپ کی ٹیلنٹ گروپوں اور ٹیلنٹ کی مہارت کی سطحوں کو دکھاتا ہے۔ گراف میں کوئی گروپ یا انفرادی ٹیلنٹ دکھانے یا چھپانے کے لیے اس پر کلک کریں۔",
  "tooltip.talentGroup": "یہ بہتر ہے کہ ٹیلنٹ کو گروپوں میں تقسیم کیا جائے",
  "tooltip.emailReminder": "چیلنج مکمل کرنے کی یاد دہانی کے لیے ای میل بھیجیں",
  continue: "جاری رکھیں",
  pin: "پِن کریں",
  shortlist: "شارٹ لسٹ کریں",
  removeShortlist: "شارٹ لسٹ سے ہٹائیں",
  customFields: "حسب ضرورت فیلڈز",
  modifyTalent: "گروپ میں تبدیلی کریں",
  deleteTalent: "ٹیلنٹ کو حذف کریں",
  delete: "حذف کریں",
  settings: "سیٹنگز",
  save: "محفوظ کریں",
  back: "واپس",
  backTo: "{place} پر واپس",
  clear: "صاف کریں",
  clearAll: "سب صاف کریں",
  welcome: "خوش آمدید",
  fieldName: "فیلڈ کا نام",
  value: "ویلیو",
  nodata: "کوئی ڈیٹا نہیں",
  activate: "فعال کریں",
  "payment.startingAt": "سے شروع ہوتا ہے",
  "payment.simple.pricing": "سادہ قیمتوں کا تعین",
  "payment.monthly": "مہینہ",
  "payment.yearly": "سال",
  "payment.calculate.cost.desc":
    "آپ کی لاگت اس مہینے کے آخر میں اس بنیاد پر حساب کی جائے گی کہ آپ کے اکاؤنٹ میں کتنے فعال ملازمین کی پروفائلز ہیں۔",
  "payment.calculate.cost.desc.0":
    "مثال کے طور پر، اگر آپ کے پاس {count} ملازمین کی پروفائلز ہیں، تو آپ کو $600 کا فکسڈ فیس ادا کرنا ہوگا۔",
  "payment.calculate.cost.desc.1":
    "مثال کے طور پر، اگر آپ کے پاس {count} ملازمین کی پروفائلز ہیں، تو آپ کو پہلے 20 ملازمین کے لیے $600 اور اگلے {diff} ملازمین کے لیے ہر ایک $30 ادا کرنا ہوگا",
  "payment.calculate.cost.desc.2":
    "مثال کے طور پر، اگر آپ کے پاس {count} ملازمین کی پروفائلز ہیں، تو آپ کو پہلے 20 ملازمین کے لیے $600، اگلے 30 ملازمین کے لیے ہر ایک $30، اور اگلے {diff} ملازمین کے لیے ہر ایک $20 ادا کرنا ہوگا۔",
  "payment.calculate.cost": "متوقع لاگت کا حساب لگائیں",
  "payment.calculate.label":
    "سلائیڈر کو حرکت دیں یا اپنے ادارے میں ملازمین کی تعداد درج کریں:",
  "payment.calculate.desc":
    "مہینے کی قیمت کا تعین کرنے میں مدد کے لیے ملازمین کی تعداد شامل کریں",
  "payment.estimated.monthly": "متوقع ماہانہ لاگت:",
  "payment.show.pricingTable": "قیمتوں کا جدول دکھائیں",
  "payment.hide.pricingTable": "قیمتوں کا جدول چھپائیں",
  "payment.pricing.table.numEmployee": "# ملازمین",
  "payment.pricing.table.perEmployee": "فی ملازم",
  "payment.pricing.table.flatFee": "فلیٹ فیس",
  "payment.plan": "پلان",
  "payment.plan.desc":
    "وہ پلان منتخب کریں جو آپ کی ضروریات کے مطابق ہو۔ کیا آپ دونوں چاہتے ہیں؟ آپ سیٹ اپ ہونے کے بعد دوسرے پلان کو ایک ہی بلنگ سائیکل میں شامل کر سکتے ہیں۔",
  payment: "ادائیگی",
  manage: "انتظام کریں",
  "payment.plan.contact":
    "زیادہ ضرورت ہے؟ انٹرپرائز حل کے لیے سیلز سے رابطہ کریں",
  "plans.freeTrial": "14 دن کا مفت ٹرائل شروع کریں",
  "talentGroup.filters.talentGroup": "ٹیلنٹ گروپ",
  "talentGroup.filters.Bookmark": "بک مارک",
  "talentGroup.filters.Shortlist": "شارٹ لسٹ",
  "talentProfile.empty.note": "کوئی نوٹس نہیں ملے",
  "talentProfile.edit.note": "نوٹ ایڈٹ کریں",
  "teamCard.talent": "{count, plural, =1 {ٹیلنٹ} other {ٹیلنٹس}}",
  "teamCardMenu.edit.primary": "نام تبدیل کریں",
  "teamCardMenu.mask.primary": "ٹیلنٹ کو ماسک کریں",
  "teamCardMenu.editattempts.primary": "ترمیم کے مواقع",
  "teamCardMenu.editattempts.secondary": "اپنے مواقع تبدیل کریں۔",
  "teamCardMenu.delete.primary": "ٹیم حذف کریں",
  "teamCardMenu.edit.secondary": "ٹیم کا نام تبدیل کریں۔",
  "teamCardMenu.mask.secondary":
    "ٹیلنٹ کو ان کی ذاتی شناختی معلومات کو گمنام بنانے کا موقع دیں، جیسے ان کا نام، ای میل وغیرہ۔",
  "teamCardMenu.delete.secondary":
    "اسے حذف کرنے سے آپ کی ٹیم ہمیشہ کے لیے ختم ہو جائے گی۔",
  "createableSelect.title":
    "ایک آپشن منتخب کریں یا ٹائپ کرنا شروع کریں تاکہ ایک نیا آپشن تخلیق کریں",
  "createableSelect.editWarning": "یہ آپشن پہلے سے موجود ہے",
  "createableSelect.deleteOption": "آپشن حذف کریں",
  "createableSelect.deleteOption.body":
    "کیا آپ واقعی اس آپشن کو ہٹانا چاہتے ہیں؟",
  "createableSelect.deleteOption.okText": "ہٹائیں",
  addTalent: "ٹیلنٹ کو مدعو کریں",
  talent: "ٹیلنٹ",
  "addTalent.placeholder": "ٹیلنٹ گروپ منتخب کریں تاکہ درآمد کریں",
  "addTalent.talentGroup.error.required": "ٹیلنٹ گروپ کا فیلڈ ضروری ہے",
  "addTalent.bulkSuccess": "یوزرز کو کامیابی سے بیچ میں شامل کیا گیا",
  "addTalent.toastSucess": "یوزرز کو کامیابی سے مدعو کیا گیا",
  "addTalent.helperText":
    "ایک دعوتی ای میل خود بخود ان ای میلز کو بھیج دی جائے گی جو آپ فراہم کرتے ہیں",
  "addTalent.uploadCount": "یوزرز اپ لوڈ ہوئے: {count}",
  "addTalent.remove": "ہٹائیں",
  "talentGroup.createNew": "نیا ٹیلنٹ گروپ بنائیں",
  "talentGroup.edit": "گروپ میں ترمیم کریں",
  "talentGroup.teamName.error.required": "ٹیم کا نام فیلڈ ضروری ہے",
  "talentGroup.type.error.required": "ٹائپ کا فیلڈ ضروری ہے",
  "talentGroup.color.error.required": "ٹیم کے رنگ کا فیلڈ ضروری ہے",
  "talentGroup.helperText":
    "ایک دعوتی ای میل خود بخود ان ای میلز کو بھیج دی جائے گی جو آپ فراہم کرتے ہیں",
  "sendEmailReminder.title": "ای میل یاد دہانی بھیجیں",
  "sendEmailReminder.label": "ای میلز",
  "sendEmailReminder.placeholder": "ایک یا زیادہ ای میلز اسپیس سے علیحدہ کریں",
  "sendEmailReminder.helperText":
    "آپ صرف ان ٹیلنٹس کو ای میل یاد دہانی بھیج سکتے ہیں جو آپ کے ٹیلنٹ گروپ میں موجود ہیں",
  "sendEmailReminder.checkboxLabel":
    "چیلنج مکمل نہ کرنے والوں کو ٹیلنٹ گروپ میں ای میل یاد دہانی بھیجیں",
  "sendEmailReminder.btnLabel": "بھیجیں",
  "sendEmailReminder.error.invalid": "غلط ای میل ایڈریس: {emails}",
  "sendEmailReminder.success":
    "کامیابی سے {recipientCount} ای میل {recipientCount, plural, =1 {یاد دہانی} other {یاد دہانیاں}} بھیجی گئیں",
  "sendEmailReminder.success.unique": "کامیابی سے ای میل یاد دہانی بھیجی گئی",
  "sendEmailReminderModal.title":
    "{masked, plural, =1 {{skeleton}} other {{masked}}} کو ای میل یاد دہانی بھیجیں؟",
  "sendEmailReminderModal.desc":
    "جب آپ 'بھیجیں' پر کلک کریں گے، {masked, plural, =1 {{skeleton}} other {{masked}}} کو چیلنج مکمل کرنے کے لیے ای میل یاد دہانی بھیجی جائے گی۔",
  "sendEmailReminder.maskedTalent": "ماسک کیا گیا ٹیلنٹ",
  "editTalent.label": "ٹیلنٹ گروپ",
  "editTalent.desc":
    "{masked, plural, =1 {{skeleton}} other {{masked}}} کے لیے ٹیلنٹ گروپ شامل یا ہٹائیں",
  "editTalent.helper":
    "ٹیلنٹس ایک ساتھ ملازم اور امیدوار گروپوں کا حصہ نہیں بن سکتے",
  "challengeSubmission.title": "نے {value} چیلنجز مکمل کیے ہیں",
  "challengeSubmission.orderedListSubmission": "ٹیلنٹ سے آرڈر شدہ فہرست",
  "challengeDetails.desc":
    "چیلنجز کی فہرست دیکھیں جن کا جواب ٹیلنٹ کو دینا ہوگا",
  "challengeSubmission.instruction":
    "ان آپشنز کو ترتیب دیں جو آپ کو اس سلوک میں ملوث ہونے کے لحاظ سے زیادہ سے کم متوقع ہیں",
  "comment.placeholder": "ایک تبصرہ لکھیں...",

  answer: "جواب",
  "challengeSubmission.prev": "بین الشخصی آگاہی",
  "challengeSubmission.next": "معلومات کی تلاش",
  totalDuration: "تخمینہ وقت:",
  challengeDetails: "چیلنج کی تفصیلات",
  preview: "پیش نظارہ",
  progress: "ترقی",
  mins: "منٹ",
  spent: "صرف کیا گیا",
  outOf: "میں سے",
  "legend.consulting": "مشاورت",
  "legend.Series": "سیریز",
  completed: "مکمل",
  incomplete: "غیر مکمل",
  filters: "فلٹرز",
  viewSubmission: "سبمیشن دیکھیں",
  badges: "بیجز",
  employee: "ملازم",
  candidate: "امیدوار",
  "addedAt.min":
    "شامل کیا گیا {min, plural, =0 {1 منٹ پہلے} =1 {1 منٹ پہلے} other {# منٹ پہلے}}",
  "addedAt.hour":
    "شامل کیا گیا {hour, plural, =1 {1 گھنٹہ پہلے} other {# گھنٹے پہلے}}",
  "addedAt.day": "شامل کیا گیا {day, plural, =1 {1 دن پہلے} other {# دن پہلے}}",
  "addedAt.date": "شامل کیا گیا {date}",
  profile: "پروفائل",
  roleInterests: "رول کی دلچسپیاں",
  selfReportedSkills: "خود کی رپورٹ کردہ مہارتیں",
  roleFunction: "رول کا فعل",
  roleLevel: "رول کی سطح",
  experienceLevel: "تجربے کی سطح",
  education: "تعلیم",
  softSkillScore: "نگٹ سافٹ اسکلز اسکور",
  challengeSubmission: "چیلنج سبمیشن",
  notes: "نوٹس",
  challengeProgress: "چیلنج کی ترقی",
  talentGroups: "ٹیلنٹ گروپس",
  sendEmailReminder: "ای میل یاد دہانی بھیجیں",
  deleteFormName:
    "حذف کریں {masked, plural, =1 {{skeleton}} other {{masked}}}؟",
  deleteFormDesc:
    "کیا آپ کو یقین ہے کہ آپ {masked, plural, =1 {{skeleton}} other {{masked}}} کو حذف کرنا چاہتے ہیں؟ اس کا سارا مواد مستقل طور پر حذف کر دیا جائے گا اور بحال نہیں کیا جا سکتا",
  editForm: "ترمیم کریں {value}",
  editTalentForm:
    "گروپ کو ترمیم کریں {masked, plural, =1 {{skeleton}} other {{masked}}}",
  EditAttempts: "کوششوں کی ترمیم کریں {value}",
  edit: "ترمیم کریں",
  talentGroupName: "ٹیلنٹ گروپ کا نام",
  talentType: "ٹیلنٹ کی قسم",
  updatedUser: "اکاؤنٹ کامیابی سے اپ ڈیٹ ہو گیا۔",
  updatedOrg: "کمپنی کامیابی سے اپ ڈیٹ ہو گئی۔",
  copiedLink: "لنک کامیابی سے کلپ بورڈ پر کاپی ہو گیا",
  publicLink: "عوامی لنک",
  copyLink: "لنک کاپی کریں",
  link: "لنک",
  importVia: "درآمد کے ذریعے",
  importUser: "ملازم درآمد کریں",
  "importUser.desc": "نیا ٹیلنٹ چیلنج خود بخود بھیجا جائے گا",
  type: "قسم",
  file: "فائل",
  groupColor: "گروپ کا رنگ",
  fileDesc:
    "نیا ٹیلنٹ چیلنج مکمل کرنے کے لیے خود بخود ای میل وصول کرے گا جب آپ درآمد کریں گے",
  import: "درآمد کریں",
  uploadFile: "فائل اپ لوڈ کریں",
  loading: "لوڈ ہو رہا ہے",
  createOption: 'بنائیں "{value}"',
  warning: "انتباہ",
  dashboard: "ڈیش بورڈ",
  monitor: "مانیٹر",
  name: "نام",
  email: "ای میل",
  nuggetScore: "نگٹ اسکور",
  score: "اسکور",
  account: "اکاؤنٹ",
  password: "پاس ورڈ",
  firstName: "پہلا نام",
  lastName: "آخری نام",
  finish: "مکمل کریں",
  newSinceLastLogin: "{count} نیا ٹیلنٹ",
  new: "نیا",
  status: "حیثیت",
  skills: "مہارتیں",
  tags: "ٹیگ",
  location: "مقام",
  or: "یا",
  challengeCopied: "چیلنج کا لنک کاپی ہو گیا",
  linkedin: "لنکڈ ان",
  emailAddress: "ای میل ایڈریس",
  resume: "سی وی",
  sortBy: "کے ذریعے ترتیب دیں",
  createdAt: "تاریخ تخلیق",
  updatedAt: "تاریخ اپ ڈیٹ",
  questions: "سوالات",

  // Onboarding
  "onboarding.skill.with.industry.benchmark": "صنعتی معیار دستیاب ہے!",
  "onboarding.skill.without.industry.benchmark": "صنعتی معیار جلد آ رہا ہے!",
  "onboarding.show.more.skills": "مزید مہارتیں دکھائیں!",
  "onboarding.show.less.skills": "کم مہارتیں دکھائیں!",
  "onboarding.help.guide.header.title": "مدد گائیڈ",
  "onboarding.help.guide.title": "عمومی گائیڈ",
  "onboarding.help.guide.btn.label": "مدد گائیڈ",

  // Onboarding - challenge selection
  "onboarding.select.challenge.creation.title":
    "چیلنج پیک بنانے کا طریقہ منتخب کریں",

  // Onboarding - challenges selection items
  "onboarding.select.role.based.challenges.title": "رول پر مبنی",
  "onboarding.select.role.based.challenges.desc":
    "چیلنج پیک بنانے کا طریقہ منتخب کریں",
  "onboarding.select.guided.challenges.title": "رہنمائی شدہ",
  "onboarding.select.guided.challenges.desc":
    "ایک سلسلہ سوالات کا جواب دیں اور ہم آپ کے لیے بہترین چیلنج تجویز کریں گے",
  "onboarding.select.free.form.title": "آزاد شکل",
  "onboarding.select.free.form.desc":
    "یہ کھلا موسم ہے! ہماری مکمل مہارتوں کی لائبریری سے منتخب کریں",

  // GUIDED CHALLENGES
  // Onboarding - guided challenges steps
  "onboarding.guided.challenges.step.0": "سوالات",
  "onboarding.guided.challenges.step.1": "چیلنج پیک کا پیش نظارہ",
  "onboarding.guided.challenges.step.2": "ٹیم",
  "onboarding.guided.challenges.subStep.0": "کمپنی کی قدریں",
  "onboarding.guided.challenges.subStep.1": "اعلیٰ کارکردگی دکھانے والا",
  "onboarding.guided.challenges.subStep.2": "کلچر فٹ",
  "onboarding.guided.challenges.subStep.3": "کلچر چیمپئن",

  // Onboarding - guided challenges (skill selection - root step 1)
  "onboarding.guided.challenges.skillSelection.title.0":
    "اپنی کمپنی کی قدروں اور مقاصد پر غور کریں، اور ان نرم مہارتوں کے زمرے منتخب کریں جو آپ کے خیال میں سب سے زیادہ متعلقہ ہیں۔",
  "onboarding.guided.challenges.skillSelection.description.0":
    "کم از کم 3 مہارتیں منتخب کریں",
  "onboarding.guided.challenges.skillSelection.title.1":
    "اب ہم ان نرم مہارتوں کو دکھا رہے ہیں جو آپ نے منتخب کردہ زمرے میں ہیں۔ آپ کے خیال میں کون سی نرم مہارتیں ایک ملازم کے لیے ضروری ہیں تاکہ وہ آپ کی کمپنی میں اچھا کام کر سکے؟",
  "onboarding.guided.challenges.skillSelection.description.1":
    "کم از کم 3 مہارتیں منتخب کریں",
  "onboarding.guided.challenges.skillSelection.title.2":
    "کبھی کبھار وہ ملازمین جو بہترین کارکردگی نہیں دکھا رہے ہوتے، وہ کمپنی کی قدروں کی نمائندگی اور ان کا پرچار کر رہے ہوتے ہیں۔ آپ کے خیال میں ان ملازمین کے لیے کون سی مہارتیں اہم ہیں؟",
  "onboarding.guided.challenges.skillSelection.description.2":
    "کم از کم 3 مہارتیں منتخب کریں",
  "onboarding.guided.challenges.skillSelection.title.3":
    "آئیے اب تھوڑا سا رخ بدلتے ہیں۔ آپ کے خیال میں آپ کی ٹیم کے کون سے ارکان مزید ترقی کر سکتے ہیں؟",
  "onboarding.guided.challenges.skillSelection.description.3":
    "کم از کم 3 مہارتیں منتخب کریں",

  // Onboarding - guided challenges guides
  "onboarding.guided.challenges.help.guide.title.step.0": "کمپنی کی قدریں",
  "onboarding.guided.challenges.help.guide.desc.step.0":
    "اگرچہ تمام مہارت کے زمرے آپ کی کمپنی کی قدروں کا حصہ ہو سکتے ہیں، ان کو ترجیح دینے پر غور کریں تاکہ آپ ان قدروں کا تعین کر سکیں جو واقعی آپ کی کمپنی کی سب سے اہم قدریں ہیں۔",
  "onboarding.guided.challenges.help.guide.title.step.1":
    "اعلیٰ کارکردگی دکھانے والا",
  "onboarding.guided.challenges.help.guide.desc.step.1":
    "ہم چاہتے ہیں کہ آپ وہ مہارتیں سوچیں جو کسی ملازم کو آپ کی کمپنی کے کام کے ماحول میں بہترین کارکردگی کے لیے ضروری ہیں۔ مثال کے طور پر، اگر آپ کی کمپنی تیز رفتار کام کے ماحول کو فروغ دیتی ہے، تو وہ کون سی مہارتیں ہیں جو ملازمین کو اس ماحول میں بہترین کارکردگی کے لیے مدد فراہم کریں گی؟",
  "onboarding.guided.challenges.help.guide.title.step.2": "کلچر فٹ",
  "onboarding.guided.challenges.help.guide.desc.step.2":
    "ہم چاہتے ہیں کہ آپ اپنی کمپنی کی قدروں پر غور کریں اور ان مہارتوں کا تعین کریں جو کسی ملازم میں آپ کی کمپنی کی قدروں سے ہم آہنگ ہونے کے لیے ضروری ہوں۔",
  "onboarding.guided.challenges.help.guide.title.step.3": "کلچر چیمپئن",
  "onboarding.guided.challenges.help.guide.desc.step.3":
    "کلچر چیمپئن وہ شخص ہوتا ہے جو آپ کی کمپنی کی قدروں کو اپناتا ہے۔ یہ ملازمین ضروری نہیں کہ بہترین کارکردگی دکھا رہے ہوں (اگرچہ وہ ایسا کر سکتے ہیں!)، لیکن وہ کمپنی کی قدروں کو زندہ رکھتے ہیں۔",
  "onboarding.guided.challenges.help.guide.title.step.4":
    "چیلنج پیک کا پیش نظارہ",
  "onboarding.guided.challenges.help.guide.desc.step.4":
    "دکھائی گئی مہارتوں کو آپ کے جوابات کی بنیاد پر 'ضروری' اور 'اچھا ہے' کے گروپوں میں تقسیم کیا گیا ہے۔ ہر مہارت میں ایک چیلنج ہوتا ہے جس میں چیلنج لینے والے کو مختلف منظرناموں پر ردعمل ظاہر کرنا ہوتا ہے۔ 'چیلنج کا پیش نظارہ' پر کلک کریں تاکہ ایک مثال دیکھ سکیں۔",
  "onboarding.guided.challenges.help.guide.title.step.5": "ٹیم",
  "onboarding.guided.challenges.help.guide.desc.step.5":
    "یہ آخری صفحہ آپ کو اس ٹیلنٹ گروپ کا نام رکھنے کی اجازت دیتا ہے جسے آپ ٹیسٹ کر رہے ہیں اور مزید معلومات کو پُر کر کے اپنے چیلنج پیک کی تخلیق کو حتمی شکل دے سکتے ہیں۔ آپ اس صفحے سے چیلنج کو مدعو بھی کر سکتے ہیں، لیکن اب ایسا کرنا ضروری نہیں ہے - ایک بار جب ٹیم بن جائے گی، آپ کو چیلنج پیک کا لنک مل جائے گا اور آپ پھر چیلنج کو شیئر کر سکیں گے۔",

  "onboarding.guided.challenges.step.1.title": "مہارت کا جائزہ",
  "onboarding.guided.challenges.step.1.desc":
    "آپ کی مہارت کی تجاویز یہاں ہیں۔ ان کو ترتیب دینے کے لیے دائیں طرف ہینڈلز کا استعمال کریں تاکہ انہیں سب سے زیادہ سے کم اہمیت کے مطابق ترتیب دیا جا سکے۔",
  "onboarding.guided.challenges.step.2.title": "ٹیم کی تفصیلات",

  "onboarding.role.based.challenges.step.0": "رول",
  "onboarding.role.based.challenges.step.1": "چیلنجز",
  "onboarding.role.based.challenges.step.2": "چیلنج پیک کا پیش نظارہ",
  "onboarding.role.based.challenges.step.3": "ٹیم",

  "onboarding.role.based.challenges.step.0.title":
    "شروع کرنے کے لیے ایک رول منتخب کریں",
  "onboarding.role.based.challenges.step.1.title":
    "چیلنج پیک میں شامل کرنے کے لیے وہ مہارتیں منتخب کریں",
  "onboarding.role.based.challenges.step.1.description":
    "کم از کم 3 مہارتیں منتخب کریں",
  "onboarding.role.based.challenges.step.2.title": "چیلنج پیک کا پیش نظارہ",
  "onboarding.role.based.challenges.step.2.desc":
    "یہ آپ کے چیلنج پیک میں شامل مہارتوں پر آخری نظر ہے! دائیں طرف ہینڈلز (=) کا استعمال کرکے مہارتوں کو سب سے زیادہ (اوپر) سے کم (نیچے) اہمیت کے مطابق ترتیب دیں۔ آپ غیر ضروری مہارتوں کو سرخ مائنس (-) کے بٹن سے حذف بھی کر سکتے ہیں۔",
  "onboarding.role.based.challenges.step.3.title": "ٹیم کی تفصیلات",

  "onboarding.role.based.challenges.help.guide.title.step.0": "رول",
  "onboarding.role.based.challenges.help.guide.desc.step.0":
    "ایک ایسا رول منتخب کریں جو ان افراد کے گروپ سے ہم آہنگ ہو جنہیں آپ ٹیسٹ کرنا چاہتے ہیں۔ ہم صنعت کے معیارات کی بنیاد پر آپ کو سب سے زیادہ متعلقہ اور اہم نرم مہارتوں کے چیلنج پیک کی تجویز دیں گے۔ اگر آپ کا رول فہرست میں نہیں ہے، تو آپ واپس ڈیش بورڈ پر جا کر گائیڈڈ فارمیٹ کا استعمال کر سکتے ہیں اور ہمیں بتا سکتے ہیں کہ ہمیں کون سا رول اپنی فہرست میں شامل کرنا چاہیے۔",
  "onboarding.role.based.challenges.help.guide.title.step.1": "چیلنجز",
  "onboarding.role.based.challenges.help.guide.desc.step.1":
    "ڈیفالٹ چیلنجز صنعت کے معیارات پر مبنی ہیں جو اس رول میں کامیابی کے لیے سب سے اہم نرم مہارتوں پر مشتمل ہیں۔ یہ آپ کی مدد کے لیے ہیں، لیکن آپ ان تک محدود نہیں ہیں۔ آپ چیلنجز کو حسب ضرورت شامل یا حذف کر سکتے ہیں۔ سبز انڈیکیٹر کی موجودگی کو چیک کرنا نہ بھولیں - یہ آپ کو اس بات کا اندازہ لگانے میں مدد دے گا کہ ٹیسٹ لینے والے کا اسکور صنعت کے اوسط سے کس طرح موازنہ کرتا ہے۔",
  "onboarding.role.based.challenges.help.guide.title.step.2":
    "چیلنج پیک کا پیش نظارہ",
  "onboarding.role.based.challenges.help.guide.desc.step.2":
    "چیلنج پیک کا پیش نظارہ آپ کو آخری مرتبہ ان چیلنجز پر نظر ڈالنے کا موقع فراہم کرتا ہے جو آپ نے منتخب کی ہیں۔ آپ انہیں اس ترتیب میں ایڈجسٹ کر سکتے ہیں جس میں وہ ظاہر ہوں گے اور آپ ان چیلنجز کو ہٹا سکتے ہیں جنہیں آپ مزید ٹیسٹ میں شامل نہیں کرنا چاہتے۔ آپ 'پری ویو چیلنج' بٹن پر کلک کر کے یہ بھی دیکھ سکتے ہیں کہ صارفین کو کس قسم کا ٹیسٹ دیا جائے گا۔",
  "onboarding.role.based.challenges.help.guide.title.step.3": "ٹیم",
  "onboarding.role.based.challenges.help.guide.desc.step.3":
    "یہ آخری صفحہ آپ کو اس بات کا موقع فراہم کرتا ہے کہ آپ جو ٹیلنٹ گروپ ٹیسٹ کر رہے ہیں اس کا نام منتخب کریں اور کچھ اضافی معلومات درج کریں تاکہ آپ کا چیلنج پیک مکمل ہو سکے۔ آپ اس صفحے سے چیلنج کی دعوت بھی دے سکتے ہیں، لیکن ایسا کرنا ضروری نہیں ہے - ایک بار ٹیم بن جانے کے بعد، آپ کو چیلنج پیک کا لنک مل جائے گا اور آپ اس چیلنج کو اس کے بعد شیئر کر سکیں گے۔",

  "onboarding.free.form.step.0": "چیلنجز",
  "onboarding.free.form.step.1": "چیلنج پیک کا پیش نظارہ",
  "onboarding.free.form.step.2": "ٹیم",

  "onboarding.free.form.step.0.title":
    "مہارتیں منتخب کریں اور اپنا چیلنج پیک بنائیں",
  "onboarding.free.form.step.0.description": "کم از کم 3 مہارتیں منتخب کریں",
  "onboarding.free.form.step.1.title": "چیلنج کا پیش نظارہ",
  "onboarding.free.form.step.1.desc":
    "یہاں آپ کے چیلنج پیک میں شامل مہارتوں پر آخری نظر ہے! دائیں طرف ہینڈلز (=) کا استعمال کرکے مہارتوں کو سب سے زیادہ (اوپر) سے کم (نیچے) اہمیت کے مطابق ترتیب دیں۔ آپ غیر ضروری مہارتوں کو سرخ مائنس (-) کے بٹن سے حذف بھی کر سکتے ہیں۔",
  "onboarding.free.form.step.2.title": "ٹیم کی تفصیلات",

  "onboarding.free.form.help.guide.title.step.0": "چیلنجز",
  "onboarding.free.form.help.guide.desc.step.0":
    "ڈیفالٹ چیلنجز صنعت کے معیارات پر مبنی ہیں جو اس رول میں کامیابی کے لیے سب سے اہم نرم مہارتوں پر مشتمل ہیں۔ یہ آپ کی مدد کے لیے ہیں، لیکن آپ ان تک محدود نہیں ہیں۔ آپ چیلنجز کو حسب ضرورت شامل یا حذف کر سکتے ہیں۔ سبز انڈیکیٹر کی موجودگی کو چیک کرنا نہ بھولیں - یہ آپ کو اس بات کا اندازہ لگانے میں مدد دے گا کہ ٹیسٹ لینے والے کا اسکور صنعت کے اوسط سے کس طرح موازنہ کرتا ہے۔",
  "onboarding.free.form.help.guide.title.step.1": "چیلنج پیک کا پیش نظارہ",
  "onboarding.free.form.help.guide.desc.step.1":
    "چیلنج پیک کا پیش نظارہ آپ کو آخری مرتبہ ان چیلنجز پر نظر ڈالنے کا موقع فراہم کرتا ہے جو آپ نے منتخب کی ہیں۔ آپ انہیں اس ترتیب میں ایڈجسٹ کر سکتے ہیں جس میں وہ ظاہر ہوں گے اور آپ ان چیلنجز کو ہٹا سکتے ہیں جنہیں آپ مزید ٹیسٹ میں شامل نہیں کرنا چاہتے۔ آپ 'پری ویو چیلنج' بٹن پر کلک کر کے یہ بھی دیکھ سکتے ہیں کہ صارفین کو کس قسم کا ٹیسٹ دیا جائے گا۔",
  "onboarding.free.form.help.guide.title.step.2": "ٹیم",
  "onboarding.free.form.help.guide.desc.step.2":
    "یہ آخری صفحہ آپ کو اس بات کا موقع فراہم کرتا ہے کہ آپ جو ٹیلنٹ گروپ ٹیسٹ کر رہے ہیں اس کا نام منتخب کریں اور کچھ اضافی معلومات درج کریں تاکہ آپ کا چیلنج پیک مکمل ہو سکے۔ آپ اس صفحے سے چیلنج کی دعوت بھی دے سکتے ہیں، لیکن ایسا کرنا ضروری نہیں ہے - ایک بار ٹیم بن جانے کے بعد، آپ کو چیلنج پیک کا لنک مل جائے گا اور آپ اس چیلنج کو اس کے بعد شیئر کر سکیں گے.",
  // -- old -- //
  // ٹیم کی آن بورڈنگ/
  "teams.onboarding.title": "نیا ٹیم بنائیں",
  "teams.onboarding.learnMore": "مزید جانیں۔",

  // ٹیم کی آن بورڈنگ - مقصد/
  "teams.onboarding.objective.restricted.0":
    "اس خصوصیت تک رسائی کے لیے ہائرنگ پلان کی ضرورت ہے۔",
  "teams.onboarding.objective.restricted.1":
    "اس خصوصیت تک رسائی کے لیے بینچ مارکنگ پلان کی ضرورت ہے۔",
  "teams.onboarding.objective.stepTitle": "مقصد",
  "teams.onboarding.objective.nyomiMessage":
    "nugget.ai امیدواروں اور ملازمین کی مہارت کو مقدار میں مدد دینے کے لیے سمولیشن پر مبنی تشخیصات (جو ہم nugget.ai چیلنجز کہتے ہیں) کا استعمال کرتا ہے۔ یہ ٹولز آپ کو بہترین ٹیلنٹ کو ہائر کرنے یا اپنے موجودہ ملازمین کی مہارت کی مقدار کو جانچنے میں مدد دے سکتے ہیں۔",
  "teams.onboarding.objective.title": "ایک مقصد منتخب کریں جس پر آپ مرکوز ہوں",
  "teams.onboarding.objective.description":
    "مقصد یہ فیصلہ کرنے میں مدد دے گا کہ آپ کی مہارتیں کس طرح تجویز کی جائیں گی۔",
  "teams.onboarding.objective.learnMore":
    "nugget.ai سمولیشن پر مبنی تشخیصات کا استعمال کرتا ہے، جنہیں ہم “چیلنجز” کہتے ہیں، جو امیدواروں اور ملازمین کی مہارتوں کی مقدار کو جانچنے میں مدد کرتے ہیں۔ ان ٹولز کا استعمال آپ کو بہترین ٹیلنٹ کو ہائر کرنے یا اپنے موجودہ ملازمین کی مہارت کو جانچنے میں مدد دے سکتا ہے۔\n\nآپ صرف ایک مقصد منتخب کر سکتے ہیں، لیکن جب آپ کی ٹیم فعال ہو جائے، تو آپ ایک ہی وقت میں گروپس بنانے سے نہیں رک سکتے تاکہ آپ ہائرنگ اور بینچ مارکنگ دونوں کر سکیں۔",

  // ٹیم کی آن بورڈنگ - مقصد -> انتخاب
  "teams.onboarding.objective.hiring.tooltip":
    "ہم آپ کی ٹیلنٹ پول کی مہارتوں کی مقدار میں مدد کر سکتے ہیں، آپ کو یہ تجویز فراہم کر کے کہ کون سی مہارتیں ملازمت کے کردار کے لیے سب سے زیادہ موزوں ہیں تاکہ آپ بہترین امیدوار کو ہائر کر سکیں۔",
  "teams.onboarding.objective.benchmarking.tooltip":
    "ہم آپ کے کمپنی کے ملازمین کی مہارتوں کو بھی ماپنے میں مدد کر سکتے ہیں، تاکہ آپ اپنے ملازمین کی طاقتور صلاحیتوں کے ساتھ ساتھ ان کے ترقی کے علاقوں کا تعین کر سکیں۔ آپ فردی ملازمین، ٹیموں، اور پوری کمپنی کی مہارتوں کا جائزہ لے سکتے ہیں، اور یہ معلوم کر سکتے ہیں کہ آپ کی کمپنی کے افراد کو کیا منفرد بناتا ہے۔",

  // ٹیم کی آن بورڈنگ - /مقصد

  // ٹیم کی آن بورڈنگ - مہارت کی شناخت/
  "teams.onboarding.skillIdentification.stepTitle": "تفصیلات",
  "teams.onboarding.skillIdentification.nyomiMessage.initial":
    "یہ ہمارا nugget.ai چیلنج انتخابی عمل کا آغاز ہے! آپ سے <bold>کل چار سوالات پوچھے جائیں گے جو ہمیں یہ جاننے میں مدد دیں گے</bold> کہ آپ کی کمپنی میں کون سی سافٹ اسکلز کو اہمیت دی جاتی ہے، انعام دیا جاتا ہے، اور مضبوط کیا جاتا ہے۔",
  "teams.onboarding.skillIdentification.title.0.tooltip":
    "سافٹ اسکلز وہ منتقلی پذیر اور موافق مہارتیں ہیں جو امیدوار کی ملازمت کی کارکردگی کی حمایت کرتی ہیں، جیسے کہ بات چیت اور وقت کا انتظام۔ یہ ہارڈ اسکلز سے مختلف ہیں، جو وہ مہارتیں ہیں جو ملازمت کے لیے ضروری تکنیکی مہارتیں ہیں۔",
  "teams.onboarding.skillIdentification.description.0":
    "نیچے nugget.ai سافٹ اسکلز کے زمرے ہیں۔ اس پر غور کرنے کے لیے وقت نکالیں، کیونکہ یہ جاننا ضروری ہے کہ اس کام کے لیے اچھے امیدوار کے لیے کیا ضروری ہے۔ آپ کو اگلے سوالات میں مزید مخصوص سافٹ اسکلز کی شناخت کرنے کا موقع ملے گا۔ براہ کرم تمام متعلقہ مہارتیں منتخب کریں۔",
  "teams.onboarding.skillIdentification.title.1":
    "اب ہم آپ کے منتخب کردہ زمرے میں سافٹ اسکلز دکھا رہے ہیں۔ ان میں سے کون سی مہارتیں اس کردار میں ایک ملازم کے لیے کامیاب ہونے کے لیے ضروری ہیں؟",
  "teams.onboarding.skillIdentification.description.1":
    "تمام متعلقہ مہارتیں منتخب کریں۔",

  // ٹیم کی آن بورڈنگ - مہارت کی شناخت - بینچ مارکنگ/
  "teams.onboarding.skillIdentification.benchmarking.nyomiMessage.succeeding":
    "پچھلے سوال میں آپ نے جو مہارتوں کے زمرے شناخت کیے تھے، اب ہم آپ سے ایسے سوالات پوچھیں گے جو اس بات کا تعین کرنے میں مدد دیں گے کہ آپ کی کمپنی کے لیے کون سی مخصوص سافٹ اسکلز اہم ہیں۔",

  // پہلا قدم
  "teams.onboarding.skillIdentification.benchmarking.modalTitle.0":
    "کمپنی کی قدریں",
  "teams.onboarding.skillIdentification.benchmarking.title.0":
    "اپنی کمپنی کی قدروں اور مقاصد پر غور کریں، اور ان سافٹ اسکلز کے زمرے منتخب کریں جو آپ کو لگتے ہیں سب سے زیادہ متعلق ہیں۔",
  "teams.onboarding.skillIdentification.benchmarking.description.0":
    "تمام متعلقہ مہارتیں منتخب کریں۔",
  "teams.onboarding.skillIdentification.benchmarking.learnMore.0":
    "یہ ہمارا nugget.ai چیلنج انتخابی عمل کا آغاز ہے! آپ سے کل چار سوالات پوچھے جائیں گے جو ہمیں یہ جاننے میں مدد دیں گے کہ آپ کی کمپنی میں کون سی سافٹ اسکلز کو اہمیت دی جاتی ہے، انعام دیا جاتا ہے، اور مضبوط کیا جاتا ہے۔\n\nسافٹ اسکلز وہ منتقلی پذیر اور موافق مہارتیں ہیں جو امیدوار کی ملازمت کی کارکردگی کی حمایت کرتی ہیں، جیسے کہ بات چیت اور وقت کا انتظام۔ یہ ہارڈ اسکلز سے مختلف ہیں، جو وہ مہارتیں ہیں جو ملازمت کے لیے ضروری تکنیکی مہارتیں ہیں۔\n\nنیچے nugget.ai سافٹ اسکلز کے زمرے ہیں۔ اس پر غور کرنے کے لیے وقت نکالیں، کیونکہ یہ جاننا ضروری ہے کہ آپ کی کمپنی کو کیا منفرد بناتا ہے!",

  // دوسرا قدم
  "teams.onboarding.skillIdentification.benchmarking.modalTitle.1":
    "اعلی کارکردگی",
  "teams.onboarding.skillIdentification.benchmarking.title.1":
    "اب ہم آپ کے منتخب کردہ زمرے میں سافٹ اسکلز دکھا رہے ہیں۔ آپ کے خیال میں یہ کون سی سافٹ اسکلز ہیں جو ایک ملازم کے لیے آپ کی کمپنی میں اچھا پرفارم کرنے کے لیے سب سے زیادہ ضروری ہیں؟",
  "teams.onboarding.skillIdentification.benchmarking.description.1":
    "تمام متعلقہ مہارتیں منتخب کریں۔",
  "teams.onboarding.skillIdentification.benchmarking.learnMore.1": `ہم چاہتے ہیں کہ آپ ان مہارتوں کے بارے میں سوچیں جو آپ کی کمپنی کے کام کے ماحول میں ایک ملازم کے لیے کامیاب ہونے کے لیے ضروری ہیں۔\n\nمثال کے طور پر، اگر آپ کی کمپنی ایک تیز رفتار کام کا ماحول فروغ دیتی ہے، تو آپ کو کیا مہارتیں اس ماحول میں ملازمین کو بہترین کارکردگی کے لیے معاونت فراہم کریں گی؟`,

  // دوسرے قدم کے ذیلی مراحل
  "teams.onboarding.skillIdentification.benchmarking.sub.0": "کمپنی کی قدریں",
  "teams.onboarding.skillIdentification.benchmarking.sub.1": "اعلی کارکردگی",
  "teams.onboarding.skillIdentification.benchmarking.sub.2":
    "ثقافت کا علمبردار",
  "teams.onboarding.skillIdentification.benchmarking.sub.3": "مہارت میں کمی",

  // تیسرا قدم
  "teams.onboarding.skillIdentification.benchmarking.modalTitle.2":
    "ثقافت کا علمبردار",
  "teams.onboarding.skillIdentification.benchmarking.title.2":
    "کبھی کبھی وہ ملازمین جو بہترین کارکردگی نہیں دکھاتے، وہ کمپنی کی قدروں کو نمائندگی اور حمایت فراہم کرتے ہیں۔ آپ کی کمپنی میں ثقافت کے علمبرداروں کو کیا مہارتیں حاصل ہونی چاہئیں؟",
  "teams.onboarding.skillIdentification.benchmarking.description.2":
    "تمام متعلقہ مہارتیں منتخب کریں۔",
  "teams.onboarding.skillIdentification.benchmarking.learnMore.2": `ایک ثقافت کا علمبردار وہ شخص ہوتا ہے جو آپ کی کمپنی کی قدروں کو پورے جذبے سے اپناتا ہے۔ یہ ملازمین ضروری نہیں کہ کام پر بہترین پرفارمر ہوں (اگرچہ وہ ہو سکتے ہیں!)، لیکن وہ کمپنی کی قدروں کو جیتے ہیں۔\nوہ ان قدروں کی عکاسی اور فروغ دینے کے لیے محنت کرتے ہیں اور دوسروں کو ان قدروں کو اپنا لینے کی ترغیب دیتے ہیں۔`,

  // چوتھا قدم
  "teams.onboarding.skillIdentification.benchmarking.modalTitle.3":
    "مہارت میں کمی",
  "teams.onboarding.skillIdentification.benchmarking.title.3": `اب ہم اعلی کارکردگی اور ثقافت کے علمبرداروں سے کچھ ہٹ کر بات کرتے ہیں۔ آپ کے خیال میں آپ کی ٹیم کے لوگ کون سی مہارتوں کو مزید بہتر بنا سکتے ہیں؟`,
  "teams.onboarding.skillIdentification.benchmarking.description.3":
    "تمام متعلقہ مہارتیں منتخب کریں۔",
  "teams.onboarding.skillIdentification.benchmarking.learnMore.3": `اب جبکہ ہم نے وہ مہارتیں شامل کی ہیں جو آپ کو اپنے بہترین کارکردگی والے ملازمین اور ثقافت کے علمبرداروں کے لیے ضروری لگتی ہیں، تو آئیے وہ مہارتیں دیکھیں جو آپ کی ٹیم پر مزید سیکھنے اور بہتر بنانے کے لیے موزوں ہیں۔`,

  "teams.onboarding.skillIdentification.benchmarking.title.1.tooltip": `ہم چاہتے ہیں کہ آپ ان مہارتوں کے بارے میں سوچیں جو آپ کی کمپنی کے کام کے ماحول میں ایک ملازم کے لیے کامیاب ہونے کے لیے ضروری ہیں۔\n\nمثال کے طور پر، اگر آپ کی کمپنی ایک تیز رفتار کام کا ماحول فروغ دیتی ہے، تو آپ کو کیا مہارتیں اس ماحول میں ملازمین کو بہترین کارکردگی کے لیے معاونت فراہم کریں گی؟`,
  "teams.onboarding.skillIdentification.benchmarking.title.2.tooltip":
    "ہم چاہتے ہیں کہ آپ اپنی کمپنی کی قدروں کے بارے میں سوچیں اور وہ کون سی مہارتیں ہیں جو ملازم کے لیے آپ کی کمپنی کی قدروں سے ہم آہنگ ہونے کے لیے ضروری ہیں۔",
  "teams.onboarding.skillIdentification.benchmarking.title.3.tooltip": `ثقافت کا علمبردار وہ شخص ہوتا ہے جو آپ کی کمپنی کی قدروں کو پورے جذبے سے اپناتا ہے۔ یہ ملازمین ضروری نہیں کہ کام پر بہترین پرفارمر ہوں (اگرچہ وہ ہو سکتے ہیں!)، لیکن وہ کمپنی کی قدروں کو جیتے ہیں۔\n\nوہ ان قدروں کی عکاسی اور فروغ دینے کے لیے محنت کرتے ہیں اور دوسروں کو ان قدروں کو اپنا لینے کی ترغیب دیتے ہیں۔`,

  // ٹیم کی آن بورڈنگ - مہارت کی شناخت - /بینچ مارکنگ

  "teams.onboarding.skillIdentification.hiring.nyomiMessage.succeeding":
    "پچھلے سوال میں آپ نے جو اسکل کیٹیگریز شناخت کی تھیں، اب ہم آپ سے سوالات پوچھیں گے تاکہ ہم اس کام کے لیے ضروری مخصوص سافٹ اسکلز کی تعیین کر سکیں جن کی آپ بھرتی کر رہے ہیں۔",
  "teams.onboarding.skillIdentification.hiring.modalTitle.0":
    "امیدوار کی سافٹ اسکلز",
  "teams.onboarding.skillIdentification.hiring.title.0":
    "آپ اپنے مثالی امیدوار میں کون سی سافٹ اسکلز کیٹیگریز تلاش کر رہے ہیں؟",
  "teams.onboarding.skillIdentification.hiring.description.0":
    "جواب دینے کے لیے تمام متعلقہ آپشن منتخب کریں۔",
  "teams.onboarding.skillIdentification.hiring.learnMore.0":
    "یہ ہمارا نگیٹ.ai چیلنج سلیکشن پروسیس کا آغاز ہے! آپ سے کل چار سوالات پوچھے جائیں گے جن کا مقصد ہمیں یہ سمجھنے میں مدد کرنا ہے کہ اس کام کے لیے کون سی سافٹ اسکلز ضروری ہیں۔ \n\nسافٹ اسکلز وہ منتقل اور قابل تطبیق صلاحیتیں ہیں جو کسی امیدوار کی مؤثر کام کی کارکردگی میں معاونت کرتی ہیں، جیسے کہ بات چیت اور وقت کا انتظام۔ یہ ہارڈ اسکلز سے مختلف ہیں، جو کام کے لیے ضروری تکنیکی مہارتیں ہیں۔",
  "teams.onboarding.skillIdentification.hiring.modalTitle.1": "ضروری اسکلز",
  "teams.onboarding.skillIdentification.hiring.title.1":
    "اب ہم آپ کے منتخب کردہ اسکلز کی کیٹیگریز میں سے سافٹ اسکلز دکھا رہے ہیں۔ ان میں سے کون سی اسکلز آپ کے خیال میں اس کام میں کامیاب ہونے کے لیے ضروری ہیں؟",
  "teams.onboarding.skillIdentification.hiring.description.1":
    "جواب دینے کے لیے تمام متعلقہ آپشن منتخب کریں۔",
  "teams.onboarding.skillIdentification.hiring.learnMore.1":
    "پچھلے سوال میں آپ نے جو اسکلز کی کیٹیگریز منتخب کی تھیں، ہم آپ سے سوالات پوچھیں گے تاکہ ہم اس کام کے لیے ضروری مخصوص سافٹ اسکلز کی تعیین کر سکیں۔ \n\nہم چاہتے ہیں کہ آپ ان اسکلز کے بارے میں سوچیں جو کسی ملازم کو اس کام میں کامیاب ہونے کے لیے ضروری ہیں۔ \n\nیہ وہ اسکلز ہیں جو آپ چاہتے ہیں کہ ایک ملازم اپنے پہلے دن سے اس کام کو کامیابی کے ساتھ انجام دینے کے لیے رکھے۔",
  "teams.onboarding.skillIdentification.hiring.sub.0": "امیدوار کی سافٹ اسکلز",
  "teams.onboarding.skillIdentification.hiring.sub.1": "ضروری اسکلز",
  "teams.onboarding.skillIdentification.hiring.sub.2": "ٹرین ایبل اسکلز",
  "teams.onboarding.skillIdentification.hiring.sub.3": "کمپنی کے مطابق فٹ",
  "teams.onboarding.skillIdentification.hiring.modalTitle.2": "ٹرین ایبل اسکلز",
  "teams.onboarding.skillIdentification.hiring.title.2":
    "آپ کے خیال میں کون سی اسکلز ایسی ہیں جو اس کام کے لیے ضروری نہیں ہیں یا وہ اسکلز ہیں جو کام پر تربیت دی جا سکتی ہیں؟",
  "teams.onboarding.skillIdentification.hiring.description.2":
    "جواب دینے کے لیے تمام متعلقہ آپشن منتخب کریں۔",
  "teams.onboarding.skillIdentification.hiring.learnMore.2":
    "ان اسکلز کا انتخاب کریں جو یا تو کام پر سیکھے جا سکتے ہیں یا آپ اضافی تربیت فراہم کرنے یا تجویز دینے کے لیے تیار ہیں۔",
  "teams.onboarding.skillIdentification.hiring.modalTitle.3":
    "کمپنی کے مطابق فٹ",
  "teams.onboarding.skillIdentification.hiring.title.3":
    "ہم نے اس کام کے لیے ضروری اسکلز پر بات کی ہے۔ اب آئیے کمپنی کے بارے میں سوچیں۔ آپ اپنے مثالی امیدوار میں کون سی اسکلز چاہتے ہیں جو آپ کی کمپنی کی اقدار اور ثقافت کی بنا پر ہوں؟",
  "teams.onboarding.skillIdentification.hiring.description.3":
    "جواب دینے کے لیے تمام متعلقہ آپشن منتخب کریں۔",
  "teams.onboarding.skillIdentification.hiring.learnMore.3":
    "کام کے لیے ضروری اسکلز سے ہٹ کر، یہ وہ اسکلز ہیں جو آپ کی کمپنی میں عموماً اہمیت رکھتی ہیں۔\n\nاگر یہ اسکلز وہی ہیں جو آپ نے پہلے منتخب کی تھیں تو کوئی بات نہیں! ان اسکلز کو اجاگر کریں جو آپ کی کمپنی میں کسی فرد کے لیے ضروری ہیں۔",
  "teams.onboarding.skillIdentification.hiring.title.1.tooltip":
    "ہم چاہتے ہیں کہ آپ ان اسکلز کے بارے میں سوچیں جو کسی ملازم کو اس کام میں کامیاب ہونے کے لیے ضروری ہیں۔{linebreak}{linebreak}یہ وہ اسکلز ہیں جو آپ چاہتے ہیں کہ ایک ملازم اپنے پہلے دن سے اس کام کو کامیابی کے ساتھ انجام دینے کے لیے رکھے۔",
  "teams.onboarding.skillIdentification.hiring.title.2.tooltip":
    "یہ وہ اسکلز ہیں جو یا تو کام پر سیکھے جا سکتے ہیں، یا آپ اضافی تربیت فراہم کرنے یا تجویز دینے کے لیے تیار ہیں۔",
  "teams.onboarding.skillIdentification.hiring.title.3.tooltip":
    "یہ وہ اسکلز ہیں جو ملازم کو روزانہ کام کے دوران استعمال کرنی پڑیں گی، اس لیے یہ نئے ملازم کو بھرتی کرتے وقت بہت اہمیت رکھتی ہیں۔{linebreak}{linebreak}مثال کے طور پر، اگر اس کام میں کسی کو غیر یقینی صورتحال میں مسلسل کام کرنے کی ضرورت ہے، تو اس کام میں روزانہ کے لیے جو مہارت لازمی ہوگی وہ 'ابہام کے لیے رواداری' ہو سکتی ہے۔",
  "teams.onboarding.skillImportance.stepTitle": "اسکلز",
  "teams.onboarding.skillImportance.nyomiMessage":
    "تمام سوالات کے جوابات دینے کا شکریہ! ہم نے پچھلے مرحلے میں آپ کی شناخت کی گئی سافٹ اسکلز کو اہمیت کے مطابق ترتیب دیا ہے۔ ہم نے 'ضروری' اور 'چاہیے' سافٹ اسکلز کو چیلنج کے لیے شناخت کیا ہے۔",
  "teams.onboarding.skillImportance.title": "اسکلز کا جائزہ",
  "teams.onboarding.skillImportance.modalTitle": "اسکلز کا جائزہ",
  "teams.onboarding.skillImportance.learnMore":
    "یہ اسکلز آپ کی فراہم کردہ جوابات کے نتیجے میں سامنے آئی ہیں۔ ہمارا انجن آپ کے کیس کے مطابق بہترین اسکلز کو ترتیب دینے کے لیے تربیت یافتہ ہے۔ جب ہم اسکلز کی سفارش کرتے ہیں، تو ہم انہیں 'ضروری' اور 'چاہیے' سافٹ اسکلز میں گروپ کرتے ہیں۔ ہر اسکل ایک چیلنج ہے جس کا امیدوار کو جواب دینا ہوگا۔ \n\nنوٹ: تخمینہ شدہ وقت ایک میٹرک ہے جو سائنس ٹیم کی جانب سے حقیقی افراد کے ساتھ تجربات کی بنیاد پر فراہم کیا گیا ہے۔ ہر چیلنج کا کل وقت مختلف ہوتا ہے جو امیدوار عام طور پر جواب دینے کے لیے لیتے ہیں۔ ہم تجویز کرتے ہیں کہ آپ زیادہ سے زیادہ 3-5 چیلنجز منتخب کریں تاکہ امیدوار کے لیے جواب دینا آسان ہو۔",
  "teams.onboarding.skillImportance.description":
    "آپ کی اسکلز کی سفارشات یہاں ہیں۔ دائیں طرف موجود ہینڈلز کا استعمال کریں تاکہ انہیں اہمیت کے مطابق ترتیب دے سکیں۔",
  "teams.onboarding.skillImportance.totalChallengeTime.title":
    "تخمینہ شدہ کل وقت:",
  "teams.onboarding.skillImportance.skillsRequired":
    "کم از کم {count} اسکلز ضروری ہیں۔ براہ کرم واپس جائیں اور اپنی ترجیحات میں ترمیم کریں۔",
  "teams.onboarding.skillImportance.totalChallengeTime.title.tooltip":
    "جو اسکلز کام کے لیے ضروری ہیں ان کی بنیاد پر ہم نے اس بات کا اندازہ لگایا ہے کہ امیدوار کو مکمل چیلنج کرنے میں کتنا وقت لگے گا۔",
  "teams.onboarding.skillImportance.totalChallengeTime.description":
    "ہر اسکل چپٹر کے ساتھ ایک وقت منسلک ہوتا ہے کہ ملازمین کو مکمل کرنے میں کتنا وقت لگے گا۔",
  "teams.onboarding.skillImportance.mustHaveSkills.title": "ضروری اسکلز",
  "teams.onboarding.skillImportance.mustHaveSkills.description":
    "جو کام کو مکمل کرنے کے لیے ضروری ہیں۔",
  "teams.onboarding.skillImportance.niceToHaveSkills.title": "چاہیے اسکلز",
  "teams.onboarding.skillImportance.niceToHaveSkills.description":
    "یہ وہ اسکلز ہیں جو آپ چاہتے ہیں کہ آپ کی کمپنی میں ملازمین کے پاس ہوں، لیکن یہ ضروری نہیں ہیں۔",
  "teams.onboarding.teamDetails.stepTitle": "ٹیم",
  "teams.onboarding.teamDetails.nyomiMessage":
    "آپ کا چیلنج تیار کر لیا گیا ہے! اب اپنی ٹیم بنائیں اور میں چیلنج بھیج دوں گا۔",
  "teams.onboarding.teamDetails.title": "ٹیم کی تفصیلات",
  "teams.onboarding.teamDetails.description":
    "براہ کرم نیچے دی گئی معلومات سے اپنے کمپنی کے ملازمین کی تفصیلات پر کریں۔",
  "teams.onboarding.teamDetails.teamName": "ٹیم کا نام",
  "teams.onboarding.teamDetails.maskChallenge": "چیلنج کو ماسک کریں",
  "teams.onboarding.teamDetails.maskChallenge.description":
    "اگر آپ 'ہاں' منتخب کرتے ہیں تو چیلنج لینے والوں کو اپنی ذاتی شناختی معلومات جیسے نام اور ای میل چھپانے کی اجازت ملے گی۔",
  "teams.onboarding.teamDetails.maskChallenge.tooltip":
    "اس سے لوگوں کو چیلنج کا جواب دیتے وقت احساس راحت ملتا ہے۔ مطالعہ سے ثابت ہوا ہے کہ اس سے چیلنج مکمل کرنے کی شرح 50٪ تک بڑھ جاتی ہے۔",
  "teams.onboarding.teamDetails.talentGroupDetails": "ٹیلنٹ گروپ کی تفصیلات",
  "teams.onboarding.teamDetails.talentGroupDetails.description":
    "آپ اس گروپ کو تشکیل دے سکتے ہیں تاکہ اس ٹیم میں اس گروپ کے امیدواروں کی شناخت کی جا سکے۔",
  "teams.onboarding.teamDetails.talentGroupDetails.talentType.tooltip":
    "ٹیلنٹ کی قسم ملازمین اور امیدواروں کے درمیان فرق کرنے میں مدد دیتی ہے۔",
  "teams.onboarding.teamDetails.talentGroupDetails.talentColor": "ٹیلنٹ کا رنگ",
  "teams.onboarding.teamDetails.talentGroupDetails.inviteTeammates":
    "ٹیلنٹ کو مدعو کریں",
  "teams.onboarding.teamDetails.talentGroupDetails.inviteTeammates.description":
    "ای میلز درج کریں تاکہ ان کو چیلنج کا لنک بھیجا جا سکے۔ آپ اسے بعد میں دوبارہ بھیج سکتے ہیں یا بھیج سکتے ہیں۔",
  all: "سب",
  faq: "FAQ",
  yes: "ہاں",
  no: "نہیں",
  multiEmailFieldPlaceholder:
    "ای میل درج کریں اور انٹر دبائیں یا دوسرا شامل کرنے کے لیے",
  browseFiles: "فائلیں تلاش کریں",
  fileFormat: "فائل کا فارمیٹ",
  maximumSize: "زیادہ سے زیادہ سائز",
  "tour.title": "ٹور لیں!",
  "tour.naomi":
    "ہیلو، میں نومی ہوں، آپ کی AI اسسٹنٹ، یہاں آپ کی مدد کے لیے ہوں!",
  "tour.description":
    "کیا آپ کو نیویگیشن میں مدد چاہیے؟ ہمارے مدد گائیڈز کو دریافت کریں۔",
  "tour.seeVideoDemo": "ویڈیو ڈیمو دیکھیں",
  "tour.viewDemoData": "ڈیمو ڈیٹا",
  "tour.learnAboutOurScience": "ہماری سائنس",
  "tour.closingMessage":
    "فکر نہ کریں، آپ ابھی بھی گائیڈ لنکس اوپر کے نیویگیشن پین سے حاصل کر سکتے ہیں۔",
  "tours.team.addTalent": "ٹیلنٹ کو مدعو کریں",
  "tours.team.addTalent.desc":
    "اب آپ اپنی ٹیم میں ٹیلنٹ شامل کر سکتے ہیں، ان کو دعوت دے سکتے ہیں، اور انھیں ٹیم میں شامل کرنے کے بعد چیلنج تک رسائی دے سکتے ہیں۔",
  "tours.team.addTalent.readMore":
    "ہم مزید اس بارے میں معلومات فراہم کرتے ہیں۔",
  "tours.team.addTalent.readLess": "کم پڑھیں",
};
