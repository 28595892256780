import React, { useState } from 'react'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { useTheme } from '@material-ui/core/styles'

import { Box, Typography, useMediaQuery } from '@material-ui/core'

import {
	StyledBox,
	ButtonGroupBox,
	ButtonContainer,
	StyledButton,
	StyledToggleButtonGroup,
	StyledToggleButton,
	RecentActivityBox,
	DividerStyled,
	StyledTextField,
	ListWrapper,
	ListTitleIcon
} from './ExamsFilter.style'

const ExamsFilter: React.FC = () => {
	const [activeButton, setActiveButton] = useState<string | null>(null)
	const [selected, setSelected] = useState<string | null>(null)
	const theme = useTheme()
	const isMobile = useMediaQuery('(max-width:600px)')

	const handleClick = (button: string) => {
		setActiveButton(button)
	}

	const buttonStyles = (button: string) => ({
		borderColor: 'lightgrey',
		color:
			activeButton === button
				? theme.palette.primary.contrastText
				: theme.palette.text.primary,
		borderWidth: 2,
		borderStyle: 'solid',
		backgroundColor: activeButton === button ? theme.palette.primary.main : 'transparent',
		'&:hover': {
			backgroundColor:
				activeButton === button ? theme.palette.primary.main : theme.palette.action.hover,
			borderColor: 'lightgrey'
		},
		'&:focus': {
			outline: 'none'
		},
		width: isMobile ? '100%' : 'auto' // Full width on mobile
	})

	const handleChange = (
		event: React.MouseEvent<HTMLElement>,
		newSelection: string | null
	) => {
		setSelected(newSelection)
	}

	const data = [
		{ count: 19, label: 'All Attempts' },
		{ count: 0, label: 'Taking', color: 'orange' },
		{ count: 18, label: 'Finished', color: 'green' },
		{ count: 0, label: 'Dropped', color: 'blue' },
		{ count: 1, label: 'Terminated', color: 'red' }
	]

	return (
		<StyledBox>
			<ButtonGroupBox>
				<ButtonContainer>
					<StyledButton
						variant="outlined"
						active={activeButton === 'today'}
						sx={buttonStyles('today')}
						onClick={() => handleClick('today')}
					>
						Today
					</StyledButton>
					<StyledButton
						variant="outlined"
						active={activeButton === 'last7days'}
						sx={buttonStyles('last7days')}
						onClick={() => handleClick('last7days')}
					>
						Last 7 Days
					</StyledButton>
					<StyledButton
						variant="outlined"
						active={activeButton === 'last30days'}
						sx={buttonStyles('last30days')}
						onClick={() => handleClick('last30days')}
					>
						Last 30 Days
					</StyledButton>
				</ButtonContainer>
			</ButtonGroupBox>

			<StyledToggleButtonGroup
				value={selected}
				exclusive
				onChange={handleChange}
				aria-label="status"
			>
				{data.map(({ count, label, color }, index) => (
					<StyledToggleButton
						key={index}
						value={label}
						aria-label={label}
						active={label === 'All Attempts' && activeButton === 'last30days'}
						style={{
							backgroundColor:
								label === 'All Attempts' && activeButton === 'last30days'
									? theme.palette.primary.main
									: 'transparent',
							color:
								label === 'All Attempts' && activeButton === 'last30days'
									? theme.palette.primary.contrastText
									: theme.palette.text.primary
						}}
					>
						<Box display="flex" flexDirection="column" alignItems="center">
							<Typography variant="h6">{count}</Typography>
							<Typography variant="body2">{label}</Typography>
						</Box>
						{color && (
							<div
								style={{
									backgroundColor: color,
									width: 8,
									height: 8,
									borderRadius: '50%',
									marginTop: 35,
									marginLeft: 5
								}}
							/>
						)}
					</StyledToggleButton>
				))}
			</StyledToggleButtonGroup>

			<RecentActivityBox>
				<Typography style={{ color: theme.palette.text.secondary }}>
					Recent Activity
				</Typography>
			</RecentActivityBox>
			<DividerStyled />
			<ListWrapper>
				<ListTitleIcon>
					<ErrorOutlineIcon style={{ color: 'orange' }} />
					<Typography variant="body2">Hello all</Typography>
				</ListTitleIcon>
				<Typography variant="body2" color="textSecondary">
					3 minutes ago
				</Typography>
			</ListWrapper>
			<DividerStyled />
			<ListWrapper>
				<ListTitleIcon>
					<PlayCircleOutlineIcon style={{ color: 'green' }} />
					<Typography variant="body2">Hello all</Typography>
				</ListTitleIcon>
				<Typography variant="body2" color="textSecondary">
					3 minutes ago
				</Typography>
			</ListWrapper>
			<DividerStyled />
			<ListWrapper>
				<ListTitleIcon>
					<ArrowUpwardIcon style={{ color: 'green' }} />
					<Typography variant="body2">Hello all</Typography>
				</ListTitleIcon>
				<Typography variant="body2" color="textSecondary">
					16 minutes ago
				</Typography>
			</ListWrapper>
			<DividerStyled />
			<ListWrapper>
				<ListTitleIcon>
					<CheckCircleOutlineIcon style={{ color: 'green' }} />
					<Typography variant="body2">Yaswanth</Typography>
				</ListTitleIcon>
				<Typography variant="body2" color="textSecondary">
					16 minutes ago
				</Typography>
			</ListWrapper>
			<DividerStyled />
			<StyledTextField
				variant="outlined"
				placeholder="Search exam activity...."
				fullWidth={isMobile}
			/>
		</StyledBox>
	)
}

export default ExamsFilter
