import { hexToRgb, darken } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'
import { getColor } from 'packages/nuggetai.ui-kit/themes/utils'
import { CheckboxIconProps } from './Checkbox'

export const CheckboxIcon = styled.span<CheckboxIconProps>`
	border-radius: ${props => (props.shape === 'squared' ? '6px' : '50%')};
	border: 2px solid ${props => props.theme.grey.fourth};
	width: 1em;
	height: 1em;
	display: flex;
	margin-top: -0.5px;
	font-size: ${props => (props.fontSize === 'small' ? '16px' : '18px')};
	align-items: center;
	justify-content: center;
	box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07);
	input:disabled ~ & {
		border: none;
		background-color: ${props => props.theme.grey.fourth};
		color: ${props => props.theme.background.summary};
		fill: ${props => props.theme.background.summary};
	}
	input:focus ~ & {
		border-color: ${() => darken(hexToRgb('#ecebed'), 0.2)};
	}
`

CheckboxIcon.defaultProps = {
	shape: 'squared'
}

interface CheckboxCheckedIconProps extends CheckboxIconProps {
	checked?: boolean
}

const uncheckedCss = css<CheckboxCheckedIconProps>`
	background-color: transparent;
	border-width: 1px;
	border-color: #ebeff2;
`

export const CheckboxCheckedIcon = styled(CheckboxIcon).attrs<CheckboxCheckedIconProps>(
	props => ({
		color: 'primary',
		checked: true,
		...props
	})
)`
	border-width: 0;
	background-color: ${({ theme, color = 'primary' }) => getColor(color, theme)};
	color: white;
	fill: white;

	${props => (!props.checked ? uncheckedCss : undefined)}
`
