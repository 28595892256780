import React from 'react'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'

import { Typography, LinearProgress, useMediaQuery, Theme } from '@material-ui/core'

import {
	WrapperDiv,
	ArrowPointer,
	ProgressWrapper,
	ProgressText,
	ProgressPercentage,
	CustomTimeline,
	CustomTimelineDot,
	CustomTimelineConnector,
	DateTimeWrapper,
	DateTimeText
} from './ExamListTimeLine.style'

const colors = ['#FFABAB', '#90EE90', '#90EE90', '#90EE90', '#90EE90']

const ExamListTimeLine: React.FC = () => {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

	const data = [
		{
			date: 'Jun 04,2024',
			time: '12:00 PM',
			title: 'Hello All',
			description: 'TechLeaders-Assessment',
			progress: 50
		},
		{
			date: 'Jul 02,2024',
			time: '02:00 PM',
			title: 'Yaswanth Sai',
			description: 'Aptitude & Reasoning',
			progress: 70
		},
		{
			date: 'Aug 08,2024',
			time: '4:00 pm',
			title: 'Vasudeva',
			description: 'Aptitude & Reasoning',
			progress: 80
		},
		{
			date: 'Sep 06,2024',
			time: '3:00 pm',
			title: 'Sai Kumar',
			description: 'Aptitude & Reasoning',
			progress: 40
		},
		{
			date: 'Jun 04,2024',
			time: '12:00 PM',
			title: 'Hello All',
			description: 'TechLeaders-Assessment',
			progress: 50
		},
		{
			date: 'Jul 02,2024',
			time: '02:00 PM',
			title: 'Yaswanth Sai',
			description: 'Aptitude & Reasoning',
			progress: 70
		},
		{
			date: 'Aug 08,2024',
			time: '4:00 pm',
			title: 'Vasudeva',
			description: 'Aptitude & Reasoning',
			progress: 80
		},
		{
			date: 'Sep 06,2024',
			time: '3:00 pm',
			title: 'Sai Kumar',
			description: 'Aptitude & Reasoning',
			progress: 40
		}
	]

	return (
		<CustomTimeline>
			{data.map((event, index) => (
				<TimelineItem key={index} style={{ marginTop: '20px' }}>
					<TimelineOppositeContent
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
							marginTop: '-34px',
							flex: '0 !important',
							whiteSpace: 'nowrap',
							'@media (max-width: 350px)': {
								display: 'none'
							}
						}}
						color="textSecondary"
					>
						<div>
							<Typography
								variant="body2"
								sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem', color: 'gray' }}
							>
								{event.date}
							</Typography>
							<Typography
								variant="body2"
								sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem', color: 'gray' }}
							>
								{event.time}
							</Typography>
						</div>
					</TimelineOppositeContent>
					<TimelineSeparator>
						<CustomTimelineDot color={colors[index % colors.length]} />
						<CustomTimelineConnector
							style={index === data.length - 1 ? { display: 'none' } : {}}
						/>
					</TimelineSeparator>
					<TimelineContent>
						<WrapperDiv color={colors[index % colors.length]}>
							<ArrowPointer color={colors[index % colors.length]} />
							<div style={{ flex: 1, paddingRight: '60px' }}>
								<Typography variant="body2" sx={{ fontWeight: '500' }}>
									{event.title}
								</Typography>
								<Typography variant="body2" sx={{ color: 'gray' }}>
									{event.description}
								</Typography>
							</div>
							<ProgressWrapper>
								<ProgressText>Progress</ProgressText>
								<div style={{ position: 'relative', width: '100%' }}>
									<LinearProgress
										style={{
											width: '100%',
											height: '4px',
											borderRadius: '4px'
										}}
										variant="determinate"
										value={event.progress}
									/>
									<ProgressPercentage>{event.progress}%</ProgressPercentage>
								</div>
							</ProgressWrapper>

							<DateTimeWrapper>
								<DateTimeText sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}>
									{event.date}
								</DateTimeText>
								<DateTimeText sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}>
									{event.time}
								</DateTimeText>
							</DateTimeWrapper>
						</WrapperDiv>
					</TimelineContent>
				</TimelineItem>
			))}
		</CustomTimeline>
	)
}

export default ExamListTimeLine
