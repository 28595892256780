import styled from 'styled-components'
import { StepConnector, StepLabel as MuiStepLabel } from '@material-ui/core'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'

export const StepLabel = styled(MuiStepLabel)`
	.MuiStepLabel-label {
		color: #c5c4c4;
		font-size: ${pxToRem(14)};
	}
`

export const SubStepConnector = styled(StepConnector)`
	.MuiStepConnector-lineVertical {
		min-height: 30px;
	}
`
