import React from 'react'
import clsx from 'clsx'
import type { StepIconProps } from '@material-ui/core/StepIcon'
import { CheckIcon } from 'components/icons/CheckIcon'
import { StepIconContainer } from './StepIcon.style'

export const StepIcon: React.FC<StepIconProps> = ({ active, icon, completed }) => (
	<StepIconContainer
		className={clsx({
			active: active || completed
		})}
	>
		{completed ? <CheckIcon style={{ fill: '#fff', fontSize: 32 }} /> : icon}
	</StepIconContainer>
)

export type { StepIconProps }
export default StepIcon
