import { persistState } from '@storeon/localstorage'
import { createStoreon } from 'storeon'
import { storeonDevtools } from 'storeon/devtools'
import { SettingsEvents, settingsModule, SettingsState } from './settings'
import { UserEvents, UserState, userModule } from './user'
import { LanguageEvents, languageModule, LanguageState } from './language'

export interface AppState extends UserState, SettingsState, LanguageState {}

export interface AppEvents extends UserEvents, SettingsEvents, LanguageEvents {}

const store = createStoreon<AppState, AppEvents>(
	[
		userModule,
		settingsModule,
		languageModule,
		persistState<AppState>(['token', 'theme', 'user']),
		process.env.NODE_ENV !== 'production' && storeonDevtools
	].filter(Boolean)
)

declare module 'storeon/react' {
	export function useStoreon(
		...keys: (keyof AppState)[]
	): useStoreon.StoreData<AppState, AppEvents>
}

declare module 'storeon' {
	export type IStoreonModule<State = AppState, Events = AppEvents> = (
		store: StoreonStore<State, Events>
	) => void
}

export default store
