import { Paper as MuiPaper } from '@material-ui/core'
import styled from 'styled-components'

export const Paper = styled(MuiPaper)`
	padding: 30px;
	background-color: ${props => props.theme.background.light};

	@media only screen and (max-width: 768px) {
		padding: 40px 24px;
	}
`
