import styled from 'styled-components'
import { Box, TextField } from '@material-ui/core'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'

export const FilterTextField = styled(TextField)`
	.MuiSelect-select {
		padding: 0;
	}
`

export const InputBox = styled(Box)`
	display: flex;
	align-items: center;
	border-radius: inherit;
	font-size: ${pxToRem(12)};
	margin-top: 2px;
`
