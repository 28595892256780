import React from 'react'
import {
	Grid,
	IconButton,
	Typography,
	Menu,
	Divider,
	Paper,
	Box,
	Button
} from '@material-ui/core'
import { useTheme } from 'styled-components'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import pluralize from 'pluralize'

import useIntl from 'hooks/useIntl'
import { useToggleAnchor } from 'hooks/useToggleAnchor'
import PopupMenuItem from 'components/PopupMenuItem'
import { EnumPipelineType } from 'hooks/useApollo'
import CopyIcon from 'assets/icons/CopyIcon'
import TextToolTip from 'components/TextToolTip'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
	TalentGroupCardContainer,
	LimitedTypography,
	BorderContainer
} from './TalentGroupCard.styles'
import iziToast from 'izitoast'

interface challengeLinkDetailsProps {
	link: string
	name: string
}

export interface TalentGroupCardProps extends React.ComponentPropsWithoutRef<typeof Paper> {
	/**
	 * Defines the unique id of the talent group
	 */
	id: string
	/**
	 * Defines the color code of the talent group
	 */
	color: string
	/**
	 * Defines the name the talent group
	 */
	name: string
	/**
	 * Defines the number of employees inside a talent group
	 */
	count: number
	/**
	 * Defines the talent group type
	 */
	type?: EnumPipelineType
	/**
	 * Defines if the team has only one group
	 */
	isSolo?: boolean
	/**
	 * Defines if the user is restricted to perform any actions in this component
	 */
	restricted?: boolean
	/**
	 * Callback fired when the delete menu is clicked
	 */
	onDelete: () => void
	/**
	 * Callback fired when the edit menu is clicked
	 */
	onEdit: () => void
	/**
	 * Callback fired when the send email reminder menu is clicked
	 */
	onReminder: (pipelineId: string) => void
	/**
	 * Callback fired when the copy challenge menu is clicked
	 */
	onCopy: () => void
	/**
	 * Callback fired when the ellipsis button is clicked
	 */
	onMenuOpen: () => void
	/**
	 * Defines if the talent group is inside a demo page
	 */
	demo?: boolean
	/**
	 * Callback fired when the invite challenge menu is clicked
	 */
	onInvite: (pipelineId: string) => void
	/**
	 * Object with assessment link and group name
	 */
	challengeLinkDetails?: challengeLinkDetailsProps
}

export const TalentGroupCard = ({
	color,
	name,
	count,
	type,
	id,
	onCopy,
	onDelete,
	onEdit,
	onReminder,
	onMenuOpen,
	isSolo,
	restricted,
	demo,
	onInvite,
	challengeLinkDetails,
	...props
}: TalentGroupCardProps) => {
	const intl = useIntl()
	const [{ anchorEl }, { handleOpenAnchor, handleCloseAnchor }] = useToggleAnchor()
	const isDisabled = restricted || demo
	const theme = useTheme()

	return (
		<BorderContainer>
			<TalentGroupCardContainer disabled={restricted} color={color} {...props}>
				<Grid container spacing={1}>
					<Grid item container justifyContent="space-between" alignItems="center">
						<Grid item xs={9}>
							<LimitedTypography
								variant="paragraph1"
								sx={{ color: restricted ? theme.grey.second : undefined }}
							>
								{name}
							</LimitedTypography>
						</Grid>
						<Grid item xs={2} container justifyContent="flex-end">
							<IconButton
								name="talent group menu"
								aria-label="talent group menu"
								aria-controls={`talent-group-${id}-card`}
								aria-haspopup="true"
								size="small"
								disabled={restricted}
								onClick={e => {
									e.stopPropagation()
									handleOpenAnchor(e)
									onMenuOpen()
								}}
							>
								<MoreHorizIcon
									fontSize="small"
									style={{ color: restricted ? theme.grey.second : theme.grey.main }}
								/>
							</IconButton>
							<Menu
								id={`talent-group-${id}-menu`}
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleCloseAnchor}
								MenuListProps={{
									disablePadding: true
								}}
								PaperProps={{
									elevation: 9,
									style: {
										maxWidth: 180,
										width: '100%'
									}
								}}
								getContentAnchorEl={null}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'center'
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right'
								}}
							>
								<PopupMenuItem
									primary={intl.formatMessage({ id: 'edit' })}
									onClick={() => {
										onEdit()
										handleCloseAnchor()
									}}
								/>
								{!demo && (
									<>
										<PopupMenuItem
											primary={intl.formatMessage({ id: 'talentGroup.menu.emailReminder' })}
											disabled={isDisabled}
											onClick={() => {
												onReminder(id)
												handleCloseAnchor()
											}}
										/>
										<PopupMenuItem
											primary={intl.formatMessage({ id: 'invite.challenge' })}
											disabled={isDisabled}
											onClick={() => {
												onInvite(id)
												// onCopy()
												handleCloseAnchor()
											}}
										/>
									</>
								)}
								<Divider />
								<PopupMenuItem
									primary={intl.formatMessage({ id: 'talentGroup.menu.deleteGroup' })}
									disabled={isSolo}
									danger
									onClick={() => {
										onDelete()
										handleCloseAnchor()
									}}
								/>
							</Menu>
						</Grid>
					</Grid>
					<Grid item container alignItems="center">
						<Grid item container alignItems="center" spacing={1} xs>
							<Grid item>
								<Typography
									fontWeight={500}
									sx={{
										color: restricted ? theme.grey.second : undefined,
										fontSize: '20px'
									}}
								>
									{count}
								</Typography>
							</Grid>
							{type && (
								<Grid item>
									<Typography
										variant="paragraph1"
										sx={{
											textTransform: 'capitalize',
											color: restricted ? theme.grey.second : theme.text.description
										}}
									>
										{type === 'Internal'
											? pluralize(intl.formatMessage({ id: 'employee' }), count)
											: pluralize(intl.formatMessage({ id: 'candidate' }), count)}
									</Typography>
								</Grid>
							)}
						</Grid>
						{type && (
							<Grid item>
								<CopyToClipboard
									text={challengeLinkDetails?.link || ''}
									onCopy={() => {
										iziToast.success({
											message: intl.formatMessage(
												{ id: 'invite.challenge.success' },
												{ name: challengeLinkDetails?.name }
											)
										})
									}}
								>
									<TextToolTip
										title={intl.formatMessage(
											{ id: 'invite.challengeLink' },
											{
												type:
													type === 'Internal'
														? pluralize(
																intl.formatMessage({ id: 'employee' })
														  ).toLowerCase()
														: pluralize(
																intl.formatMessage({ id: 'candidate' })
														  ).toLowerCase()
											}
										)}
										placement="top"
										arrow
									>
										<IconButton size="small">
											<CopyIcon style={{ width: 16, height: 16 }} />
										</IconButton>
									</TextToolTip>
								</CopyToClipboard>
							</Grid>
						)}
					</Grid>
					{!demo && restricted && (
						<Grid item container alignItems="center" justifyContent="space-between">
							<Grid item>
								<LockOutlinedIcon sx={{ fill: theme.grey.second, height: 16, width: 16 }} />
							</Grid>
						</Grid>
					)}
				</Grid>
			</TalentGroupCardContainer>
		</BorderContainer>
	)
}

export default TalentGroupCard
