export const enLocales = {
	// ---------- ATOMS ----------

	// atoms/sample
	'atoms.sample.text': 'sample intl text',

	// atoms/chip-role
	'atoms.chip-role.role.0': 'Owner',
	'atoms.chip-role.role.1': 'Admin',
	'atoms.chip-role.role.2': 'Editor',
	'atoms.chip-role.role.3': 'Viewer',
	'atoms.chip-role.unknown': 'Unknown',
	'atoms.chip-role.invite': 'Invite Pending',

	// atoms/skill-chip
	'atoms.skill-chip.years': '{value, plural, =1 {1 yr} other {# yrs}}',
	'atoms.skill-chip.months': '{value} mos',

	// atoms/status-indicator
	'atoms.status-indicator.active': 'Actively looking',
	'atoms.status-indicator.open': 'Open to opportunities',
	'atoms.status-indicator.inactive': 'Not looking',

	// ---------- MOLECULES ----------

	// molecules/member-card
	'molecules.member-card.you': 'You',
	'molecules.member-card.edit': 'Edit member',
	'molecules.member-card.invite': 'Resend invite',
	'molecules.member-card.delete': 'Delete member',

	// molecules/talent-card
	'molecules.talent-card.rate': '${rate}/hr',
	'molecules.talent-card.invite': 'Invite to Job',
	'molecules.talent-card.more': '+{n} more',
	'molecules.talent-card.less': 'Show less',
	'molecules.talent-card.available': 'Available after {availableDate}',
	'molecules.talent-card.someMore': '+{n} more',
	'molecules.talent-card.score': 'Match Score',

	// molecules/filter-options
	'molecules.filter-options.sort': 'Sort by:',

	// molecules/status-button
	'molecules.status-button.next': 'Next status:',

	// molecules/bar-stepper
	'molecules.bar-stepper.step': 'STEP {current} of {length}',

	// molecules/salary-range
	'molecules.salary-range.year.rate': '${rate}k/yr',
	'molecules.salary-range.hour.rate': '${rate}k/hr',
	'molecules.salary-range.per.hour': 'Per hour',
	'molecules.salary-range.per.year': 'Per year',
	'molecules.salary-range.year': '${min}K/year to ${max}K/year',
	'molecules.salary-range.hour': '${min}/hr to ${max}/hr',

	// ---------- ORGANISMS ----------

	// organisms/delete-modal
	'organisms.delete-modal.title': 'Are you sure you want to delete {title}?',
	'organisms.delete-modal.desc': 'I understand that all of my data will be deleted',
	'organisms.delete-modal.validation': 'Retype <bold>{title}</bold> below',
	'organisms.delete-modal.delete': 'Delete',

	// organisms/activity-log
	'organisms.activity-log.title': 'Activity Log',
	'organisms.activity-log.placeholder': 'Write a comment...',
	'organisms.activity-log.today': 'Today',
	'organisms.activity-log.comment': 'left a comment:'

	// ---------- TEMPLATE ----------

	// ---------- PAGES ----------
}
