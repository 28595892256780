import styled from 'styled-components'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import Timeline from '@material-ui/lab/Timeline'
import { Typography } from '@material-ui/core'

export const WrapperDiv = styled.div<{ color: string }>`
	background-color: ${({ theme }) => (theme.mode === 'dark' ? '#222222' : '#fff')};
	padding: 8px 16px;
	max-width: 400px;
	margin-top: -20px;
	border: 1px solid lightgray;
	border-radius: 4px;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	word-wrap: break-word;
	position: relative;
	z-index: 10;

	::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 7px;
		height: 100%;
		background-color: ${props => props.color};
	}

	@media (max-width: 600px) {
		padding: 8px 12px;
		width: 100%;
		height: auto;
		flex-direction: column;
		align-items: flex-start;
	}
`

export const ArrowPointer = styled.div<{ color: string }>`
	position: absolute;
	top: 50%;
	left: -7px;
	width: 12px;
	height: 13px;
	background-color: ${props => props.color};
	transform: rotate(45deg);
	transform-origin: center;
	margin-top: -6px;

	@media (max-width: 600px) {
		width: 15px;
		height: 15px;
		left: -8px;
	}
`

export const ProgressWrapper = styled.div`
	position: relative;
	width: 140px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	@media (max-width: 600px) {
		top: 26px;
		left: 100px;
		width: 60%;
	}
`

export const ProgressText = styled(Typography)`
	font-size: 0.75rem;
	color: gray;
	margin-bottom: 4px;

	@media (max-width: 600px) {
		display: none;
	}
`

export const ProgressPercentageWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 4px;
	flex-direction: row;

	@media (max-width: 600px) {
		width: 100%;
		justify-content: space-between;
	}
`

export const ProgressPercentage = styled(Typography)`
	font-size: 0.75rem;
	color: gray;
	position: absolute;
	right: 0;
	top: -22px;
	bottom: 0;

	@media (max-width: 600px) {
		font-size: 0.65rem;
		right: 30px;
		position: relative;
	}
`

export const CustomTimeline = styled(Timeline)`
	& .MuiTimelineItem-missingOppositeContent:before {
		flex: 0.2;

		@media (max-width: 600px) {
			display: none;
		}
	}
`

export const CustomTimelineDot = styled.div<{ color: string }>`
	width: 20px;
	height: 20px;
	margin-top: 6px;
	background-color: white;
	border: 2px solid ${props => props.color};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 600px) {
		display: none;
	}
`

export const CustomTimelineConnector = styled(TimelineConnector)`
	margin-top: -0.1px;
	margin-bottom: -26px;
	width: 6px;
	background-color: lightgray;

	@media (max-width: 600px) {
		display: none;
	}
`

export const DateTimeWrapper = styled.div`
	position: absolute;
	bottom: 8px;
	left: 0;
	right: 0;
	display: none;

	@media (max-width: 600px) {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: 10px;
		position: absolute;

		left: 12px;
		bottom: 22px;
		right: auto;
	}
`

export const DateTimeText = styled(Typography)`
	font-size: 0.75rem;
	color: gray;

	@media (max-width: 600px) {
		font-size: 0.65rem;
	}
`
