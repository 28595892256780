/* eslint-disable indent */
import React, { useEffect, useRef, useState } from 'react'
import {
	Button,
	Divider,
	Grid,
	Hidden,
	Theme,
	Typography,
	useMediaQuery
} from '@material-ui/core'
import { Add as PlusIcon } from '@material-ui/icons'
import { useTheme } from 'styled-components'
import useIntl from 'hooks/useIntl'
import InfoTooltip from 'components/InfoTooltip'
import TalentGroupCard, {
	TalentGroupCardProps,
	TalentGroupSkeleton
} from 'components/TalentGroupCard'
import DeleteModal from 'components/DeleteModal'
import EditGroupModal from 'components/EditGroupModal'
import SendEmailReminderModal from 'components/SendEmailReminderModal'
import CreateTalentGroupModal from 'components/CreateTalenGroupModal'
import { EnumPipelineType, GetAssessmentQuery } from 'hooks/useApollo'

import { getAssessmentLink, getAssessmentQueryLink } from 'constants/config'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class'
import { TeamContextProps, useTeamContext } from 'screens/Teams/Teams.config'
import AddTalentModal from 'components/AddTalentModal'
import { useStoreon } from 'storeon/react'
import useUserSubscriptions from 'hooks/useUserSubscriptions'
import CustomArrow from './components/CustomArrow'
import { useTalentGroups } from './useTalentGroups'
import { slideSettings, slideMobileSettings } from './slideSettings'
import TalentGroupsFilter from './components/Filter'

export interface TalentGroupsProps {
	/**
	 * Defines the list groups inside a team
	 */
	groups: GetAssessmentQuery['assessment']['pipelines']
	/**
	 * Defines the loading state of the component
	 * @default false
	 */
	loading?: boolean
}

const teamSelector = (store: TeamContextProps) => ({
	assessment: store.assessment,
	teamId: store.teamId,
	demo: store.demo
})

export const TalentGroups: React.FC<TalentGroupsProps> = ({ groups, loading }) => {
	const intl = useIntl()
	const theme = useTheme()
	const { user } = useStoreon('user')
	const { assessment, teamId, demo } = useTeamContext(teamSelector)
	const handleNextSlide = useRef<() => void>(() => {})
	const handlePrevSlide = useRef<() => void>(() => {})
	const containerRef = useRef<HTMLDivElement>(null)
	const [controlledSwiper, setControlledSwiper] = useState<Pick<
		SwiperClass,
		'isBeginning' | 'isEnd'
	> | null>(null)
	const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

	const [
		{ isOnBenchmarkingPlan, isOnHiringPlan, trialExpiredWithoutSubscription, isOnTrial }
	] = useUserSubscriptions()

	const [
		{ activeGroup, activeModal, formLoading, activeType, filteredGroups },
		{
			handleEdit,
			handleCreate,
			handleDelete,
			handleInvite,
			handleOpenEditModal,
			handleOpenDeleteModal,
			handleOpenCreateModal,
			handleOpenReminderModal,
			handleOpenInviteModal,
			handleCloseModal,
			handleCopy,
			handleActiveGroup,
			handleFilter
		}
	] = useTalentGroups(groups)

	useEffect(() => {
		if (!loading && filteredGroups) {
			handleFilter('All')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading])

	const totalTalents = assessment?.totalTalents ?? 0
	const totalGroups = assessment?.pipelinesTotal
	const attempts = assessment?.attempts ?? 0

	const swiperSlideStyles: React.CSSProperties = {
		width: 240,
		display: 'flex',
		justifyContent: 'center'
	}

	const swiperMobileStyles: React.CSSProperties = {
		width: 240,
		marginLeft: 45,
		display: 'flex',
		justifyContent: 'center'
	}

	const filters = [
		{
			label: intl.formatMessage({ id: 'teams.talentGroups.filter.all' }),
			value: 'All'
		},
		{
			label: intl.formatMessage({ id: 'teams.talentGroups.filter.internal' }),
			value: 'Internal',
			disabled: !groups?.some(group => group?.type === 'Internal')
		},
		{
			label: intl.formatMessage({ id: 'teams.talentGroups.filter.external' }),
			value: 'External',
			disabled: !groups?.some(group => group?.type === 'External')
		}
	]

	const renderTalentGroups = (
		group: typeof groups[number],
		props?: Partial<TalentGroupCardProps>
	) => {
		// @ts-ignore
		const { id, name, usersTotal, color, type } = group

		const isBlocked = demo
			? false
			: isOnTrial
			? false
			: isOnBenchmarkingPlan && type === 'Internal'
			? false
			: !(isOnHiringPlan && type === 'External')

		return (
			<div id="team-tours-talent-card">
				<TalentGroupCard
					restricted={isBlocked}
					name={name}
					id={id}
					demo={demo}
					isSolo={groups.length === 1}
					color={color || theme.background.light}
					onDelete={handleOpenDeleteModal}
					onEdit={handleOpenEditModal}
					onReminder={handleOpenReminderModal}
					onInvite={handleOpenInviteModal}
					onMenuOpen={() => {
						handleActiveGroup({
							id,
							name,
							color,
							type
						})
					}}
					onCopy={() => {
						handleCopy(
							getAssessmentLink({
								assessId: teamId,
								demo,
								userId: user?.id as string,
								pipelineId: id
							}),
							name
						)
					}}
					challengeLinkDetails={{
						link: getAssessmentQueryLink({
							assessId: teamId,
							demo,
							userId: user?.id as string,
							pipelineId: id
						}),
						name: name
					}}
					count={usersTotal || 0}
					type={type}
					{...props}
				/>
			</div>
		)
	}

	return (
		<Grid container ref={containerRef} item spacing={3}>
			<Grid container item spacing={1}>
				<Grid container item alignItems="center">
					<Grid item>
						<Typography variant="h4" fontWeight={500}>
							{intl.formatMessage({ id: 'teams.talentGroup' })}
						</Typography>
					</Grid>
					<Grid item>
						<InfoTooltip info={intl.formatMessage({ id: 'tooltip.talentGroup' })} isTouch />
					</Grid>
				</Grid>
				<Grid container item spacing={2} alignItems="center">
					<Grid item>
						<Typography variant="paragraph3">
							Challenge type:
							<span style={{ fontWeight: 500 }}>{assessment?.challengePack}</span>
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="paragraph3">
							Total attempts:
							<span style={{ fontWeight: 500 }}> {attempts}</span>
						</Typography>
					</Grid>
					<Grid item height="100%">
						<Divider orientation="vertical" />
					</Grid>
					<Grid item>
						<Typography variant="paragraph3">
							{intl.formatMessage({ id: 'teams.totalTalent' }, { total: totalTalents })}
							<span style={{ fontWeight: 500 }}>{totalTalents}</span>
						</Typography>
					</Grid>
					<Grid item height="100%">
						<Divider orientation="vertical" />
					</Grid>

					<Grid item>
						<Typography variant="paragraph3">
							{intl.formatMessage({ id: 'teams.totalGroup' }, { total: totalGroups })}
							<span style={{ fontWeight: 500 }}>{totalGroups}</span>
						</Typography>
					</Grid>
					<Hidden smDown>
						<Grid item height="100%">
							<Divider orientation="vertical" />
						</Grid>
						<Grid item xs={12} sm="auto">
							<TalentGroupsFilter
								handleChangeFilter={event =>
									// @ts-ignore
									handleFilter(event.target.value as EnumPipelineType & 'All')
								}
								field={activeType}
								filters={filters}
							/>
						</Grid>
					</Hidden>
				</Grid>
				<Hidden smUp>
					<Grid item>
						<TalentGroupsFilter
							handleChangeFilter={event =>
								// @ts-ignore
								handleFilter(event.target.value as EnumPipelineType & 'All')
							}
							field={activeType}
							filters={filters}
						/>
					</Grid>
				</Hidden>
			</Grid>
			<Grid
				container
				item
				sx={{
					maxWidth: {
						xs: '92vw',
						md: '93vw',
						lg: '95vw'
					}
				}}
			>
				<Grid
					item
					xs={12}
					md={9}
					lg={10}
					style={{
						width: 'fit-content',
						flexBasis: 'unset'
					}}
				>
					{!loading ? (
						<>
							<Hidden smDown>
								<div
									className="custom-scrollbar"
									style={{ display: 'flex', overflowX: 'auto', paddingBottom: 20 }}
								>
									<Grid container wrap="nowrap" spacing={3}>
										{filteredGroups?.map((group, index) => (
											<Grid key={index} item>
												{renderTalentGroups(group, { style: { width: 300 } })}
											</Grid>
										))}
									</Grid>
								</div>
							</Hidden>
							<Hidden smUp>
								<Swiper
									{...slideMobileSettings}
									style={{
										paddingBottom: 20,
										width: 'calc(100vw + 20px)',
										marginLeft: -35
									}}
									onSlideChange={swiper => {
										setControlledSwiper({
											isBeginning: swiper.isBeginning,
											isEnd: swiper.isEnd
										})
									}}
									onSwiper={swiper => {
										setControlledSwiper({
											isBeginning: swiper.isBeginning,
											isEnd: swiper.isEnd
										})
										handleNextSlide.current = () => swiper.slideNext()
										handlePrevSlide.current = () => swiper.slidePrev()
									}}
								>
									{filteredGroups?.map((group, index) => (
										<SwiperSlide
											key={index}
											style={{ ...swiperSlideStyles, width: undefined }}
										>
											{renderTalentGroups(group)}
										</SwiperSlide>
									))}
									<CustomArrow
										onClick={handleNextSlide.current}
										ended={controlledSwiper?.isEnd}
										className="swiper-button-next"
										name="button next"
										aria-label="button next"
									/>
									<CustomArrow
										onClick={handlePrevSlide.current}
										ended={controlledSwiper?.isBeginning}
										direction="left"
										className="swiper-button-prev"
										name="button next"
										aria-label="button next"
									/>
								</Swiper>
							</Hidden>
						</>
					) : (
						<Swiper {...slideSettings}>
							<Grid container spacing={3}>
								{Array(smDown ? 1 : 5)
									.fill(null)
									.map((_, index) => {
										return (
											<SwiperSlide
												key={index}
												style={smDown ? swiperMobileStyles : swiperSlideStyles}
											>
												<TalentGroupSkeleton />
											</SwiperSlide>
										)
									})}
							</Grid>
						</Swiper>
					)}
				</Grid>
				<Hidden mdDown>
					<Grid
						display="flex"
						item
						alignItems="center"
						justifyContent="center"
						flexDirection="column"
						sx={{
							paddingLeft: {
								xs: '24px'
							}
						}}
					>
						<Button
							name="add talent group"
							aria-label="add talent group"
							id="team-tours-talent-card-add-button"
							color="primary"
							variant="contained"
							disabled={trialExpiredWithoutSubscription}
							sx={{
								borderRadius: '50%',
								width: '36px',
								height: '36px',
								marginBottom: 2,
								minWidth: '36px'
							}}
							onClick={handleOpenCreateModal}
						>
							<PlusIcon fontSize="small" />
						</Button>
						<Typography
							style={{
								color: trialExpiredWithoutSubscription
									? theme.background.summary
									: theme.primary.main
							}}
							align="center"
							variant="paragraph1"
						>
							{intl.formatMessage({ id: 'talentGroup.createNewGroup' })}
						</Typography>
					</Grid>
				</Hidden>
			</Grid>

			{activeModal === 'edit' && (
				<EditGroupModal
					id={activeGroup.id}
					loading={formLoading}
					name={activeGroup.name as string}
					color={activeGroup.color || theme.tag.white}
					type={activeGroup.type || undefined}
					open={activeModal === 'edit'}
					onClose={handleCloseModal}
					onEdit={handleEdit}
				/>
			)}
			<DeleteModal
				id={activeGroup.id}
				title={activeGroup.name as string}
				loading={formLoading}
				open={activeModal === 'delete'}
				onClose={handleCloseModal}
				onDelete={handleDelete}
			/>
			<SendEmailReminderModal
				id={activeGroup.id}
				open={activeModal === 'reminder'}
				onClose={handleCloseModal}
			/>
			<CreateTalentGroupModal
				id={teamId}
				demo={demo}
				loading={formLoading}
				link="https://www.nugget.ai"
				open={activeModal === 'create'}
				onClose={handleCloseModal}
				onCreate={handleCreate}
			/>
			{activeModal === 'invite' && (
				<AddTalentModal
					pipelines={groups}
					teamId={teamId}
					demo={demo}
					open={activeModal === 'invite'}
					onClose={handleCloseModal}
					getLink={pipelineId => {
						return getAssessmentLink({
							assessId: teamId,
							demo,
							userId: user?.id as string,
							pipelineId
						})
					}}
					defaultPipeline={activeGroup.id}
					onInvite={handleInvite}
				/>
			)}
		</Grid>
	)
}

export default React.memo(TalentGroups)
