import React from 'react'
import { Divider, Tabs as MuiTabs, Theme } from '@material-ui/core'
import { useControlled } from '@material-ui/core/utils'
import { SxProps } from '@material-ui/system'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'
import TabPanel from './components/TabPanel'
import { Tab } from './Tabs.styles'

export interface TabsProps {
	/**
	 * Defines the tabs that will be rendered
	 * label - Defines the title of the tab
	 * component - Defines the component that will be rendered
	 */
	tabs: { label: string; component: React.ReactNode }[]
	/**
	 *  Determines additional display behavior of the tabs:
	 *
	 *  - `scrollable` will invoke scrolling properties and allow for horizontally
	 *  scrolling (or swiping) of the tab bar.
	 *  -`fullWidth` will make the tabs grow to use all the available space,
	 *  which should be used for small views, like on mobile.
	 *  - `standard` will render the default state.
	 * @default 'standard'
	 */
	variant?: 'standard' | 'scrollable' | 'fullWidth'
	/**
	 * If `true`, the tabs are centered.
	 * This prop is intended for large views.
	 * @default false
	 */
	centered?: boolean
	/**
	 * Determines the CSS styles to each tab
	 */
	tabStyle?: SxProps<Theme>
	/*
	 * Determines the CSS styles to the tab container
	 */
	tabContainerStyle?: SxProps<Theme>
	/**
	 * Value of the current tab using the index of a tab.
	 * Setting this prop enables control over the tabs.
	 */
	value?: number
	/**
	 * Callback fired when the tab is changed.
	 *
	 * @param {number} newValue The `value` of the new index.
	 */
	onChange?: (newValue: number) => void
}

const a11yProps = (index: number) => ({
	id: `tab-${index}`,
	'aria-controls': `tabpanel-${index}`
})

export const Tabs = ({
	tabs,
	variant,
	centered,
	tabStyle,
	tabContainerStyle,
	onChange,
	value: valueProp
}: TabsProps) => {
	const [value, setValue] = useControlled({
		controlled: valueProp,
		default: 0,
		name: 'Tabs',
		state: 'value'
	})

	const handleChange = (_: React.ChangeEvent<Record<string, any>>, newValue: number) => {
		setValue(newValue)
		if (onChange) {
			onChange(newValue)
		}
	}

	return (
		<>
			<MuiTabs
				value={value}
				onChange={handleChange}
				aria-label="tabs"
				variant={variant}
				scrollButtons={false}
				centered={centered}
				sx={tabContainerStyle}
			>
				{tabs.map(({ label }, idx) => (
					<Tab
						sx={{
							justifyContent: 'center',
							fontSize: pxToRem(14),
							...tabStyle
						}}
						key={idx}
						label={label}
						{...a11yProps(idx)}
					/>
				))}
			</MuiTabs>
			<Divider
				style={{
					marginTop: -1.5
				}}
			/>
			{tabs.map(({ component }, idx) => (
				<TabPanel key={idx} value={value} index={idx}>
					{component}
				</TabPanel>
			))}
		</>
	)
}

export default Tabs
