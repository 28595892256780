import React from 'react'
import { Divider, Grid, Typography } from '@material-ui/core'

import useIntl from 'hooks/useIntl'
import { Link } from 'react-router-dom'
import { createOAuthUrl } from 'utils/url'
import SocialButton from 'packages/nuggetai.ui-kit/molecules/social-button'
import GoogleIcon from 'packages/nuggetai.ui-kit/icons/google'

export const CreateAccount = () => {
	const intl = useIntl()

	return (
		<Grid container direction="column" alignItems="stretch" spacing={4}>
			<Grid item alignSelf="center">
				<Typography variant="h3" fontWeight={700}>
					{intl.formatMessage({ id: 'auth.createYourAccount' })}
				</Typography>
			</Grid>
			<Grid item container direction="column" spacing={2}>
				<Grid item>
					<SocialButton
						href={createOAuthUrl('google')}
						label={intl.formatMessage({ id: 'auth.signUpWith' }, { name: 'Google' })}
						icon={<GoogleIcon />}
					/>
				</Grid>
				{/* <Grid item>
					<SocialButton
						href={createOAuthUrl('facebook')}
						label={intl.formatMessage({ id: 'auth.signUpWith' }, { name: 'Facebook' })}
						icon={<FacebookIcon />}
					/>
				</Grid> */}
			</Grid>
			<Grid item>
				<Divider>{intl.formatMessage({ id: 'or' })}</Divider>
			</Grid>
			<Grid item>
				<Link to="create-account">
					<SocialButton label={intl.formatMessage({ id: 'auth.createAnAccount' })} />
				</Link>
			</Grid>
		</Grid>
	)
}

export default CreateAccount
