import styled, { css } from 'styled-components'
import { Paper, Link, Typography } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'
import { getRgbColor } from 'utils/color'

interface ContainerProps {
	disabled?: boolean
	color: string
}

export const TalentGroupCardContainer = styled(Paper).withConfig<ContainerProps>({
	shouldForwardProp: prop => !['color'].includes(prop)
})`
	padding: 16px;
	width: 100%;
	border-radius: 10px;
	border-left: ${props =>
		`13px solid ${props.disabled ? alpha(getRgbColor(props.color), 0.5) : props.color}`};
	max-width: 240px;
`

export const BorderContainer = styled.div`
	border: ${props => `1px solid ${props.theme.border}`};
	border-radius: 10px;

	@media (max-width: 360px) {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
`

export const LimitedTypography = styled(Typography)`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`

export const CardLink = styled(Link)<{ disabled?: boolean }>`
	font-size: ${pxToRem(12)};
	cursor: pointer;
	color: ${props => props.theme.tag.blue};
	${props =>
		props.disabled &&
		css`
			opacity: 0.38;
			cursor: default;
			&:hover {
				text-decoration: none;
			}
		`}
`
