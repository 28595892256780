export const ar = {
  switchTheme: "تبديل السمة",
  next: "التالي",
  total: "الإجمالي",
  stepOf: "الخطوة {current} من {length}",
  industryBench: "مقياس الصناعة",
  industryComingSoon: "متوسط الصناعة قريبا",
  employees: "الموظفون",
  slogan: "موثوق ومحظوظ من قبل الفرق العالمية",
  "estimated.time": "الوقت المقدر",
  "changePassword.title": "تغيير كلمة المرور",
  "auth.createAccount": "إنشاء حساب",
  "auth.createAnAccount": "إنشاء حساب",
  "auth.createYourAccount": "إنشاء حسابك",
  "auth.createProfile": "إنشاء ملفك الشخصي",
  "auth.signIn": "تسجيل الدخول",
  "auth.signUp": "التسجيل",
  "auth.login": "تسجيل الدخول",
  "auth.signInAccount": "تسجيل الدخول إلى حسابك",
  "auth.acceptTerms":
    "إنشاء حساب يعني أنك موافق على {terms} و {privacy} <red>*</red>",
  "auth.terms": "شروط الخدمة",
  "auth.privacy": "سياسة الخصوصية",
  "auth.dontHaveAnAccount": "لا تملك حسابًا؟",
  "auth.haveAccount": "هل لديك حساب؟",
  "auth.signUpWith": "اشترك مع {name}",
  "auth.signInWith": "تسجيل الدخول مع {name}",
  "auth.message.quote": `كانت النتائج مذهلة وشاركنا أحدهم.
      كنت أتمنى لو بدأت معهم بدلاً من تصفح ما يقارب 500 طلب!`,
  "validation.lowercase":
    "{length, plural, =1 {حرف واحد} other {# حروف}} صغيرة",
  "validation.uppercase":
    "{length, plural, =1 {حرف واحد} other {# حروف}} كبيرة",
  "validation.special":
    "{length, plural, =1 {حرف خاص واحد} other {# حروف خاصة}}",
  "validation.number": "{length, plural, =1 {رقم واحد} other {# أرقام}}",
  "validation.minLength":
    "{length, plural, =0 {} =1 {حد أدنى حرف واحد} other {حد أدنى # حروف}}",
  "validation.maxLength": "الحد الأقصى للطول هو {length, number}",
  "validation.emailIncluded": "تم تضمين هذا البريد الإلكتروني بالفعل",
  "auth.forgotPassword": "نسيت كلمة المرور؟",
  "nav.organizations": "المنظمات",
  "nav.addOrg": "إضافة منظمة جديدة",
  "nav.personalInformation": "الحساب",
  "nav.campaigns": "الحملات",
  "nav.integrations": "التكاملات",
  "nav.company": "الشركة",
  "nav.security": "الأمان",
  "nav.billing": "الفوترة",
  "statusButton.nextStatus": "الحالة التالية:",
  "invite.challenge": "دعوة الموهبة",
  "invite.challengeLink": "انسخ رابط التحدي وأرسله إلى {type}",
  "invite.challenge.success": "تم نسخ رابط التحدي لـ {name} بنجاح إلى الحافظة",
  "invite.challenge.uploadEmailFileDropZone": "اسحب وأفلت ملفك هنا",
  "talentGroup.menu.emailReminder": "إرسال تذكير بالبريد الإلكتروني",
  "talentGroup.menu.copyChallenge": "نسخ التحدي",
  "talentGroup.menu.deleteGroup": "حذف المجموعة",
  "talentGroup.sendNotification":
    "إرسال بريد إلكتروني لإعلام الموهوبين الجدد للانضمام إلى التحدي",
  "dashboard.welcome.hello": "مرحبًا، {name}",
  "dashboard.emailVerified": "تم التحقق من البريد الإلكتروني!",
  "dashboard.welcome.firstTime.welcome": "مرحبًا بك في لوحتك!",
  "dashboard.welcome.firstTime.createTeam": "أنشئ فريقًا للبدء",
  "dashboard.welcome.createTeam": "انقر على فريق أو أنشئ فريقًا جديدًا",
  "dashboard.welcome.box.title": ` لنبدأ!`,
  "dashboard.welcome.box.subText":
    "أنشئ فريقك الأول لتوظيف الموهبة أو قياس مهارات الموظفين.",
  "dashboard.welcome.box.buttonText": "إنشاء فريق",
  "dashboard.welcome.box.trialEnded": "انتهت تجربتك المجانية أو تم إلغاء خطتك.",
  "dashboard.welcome.firstTime.naomi.welcome": "مرحبًا بك في لوحتك!",
  "dashboard.welcome.firstTime.naomi.welcomeBalloon": "أهلًا بك!",
  "dashboard.welcome.firstTime.naomi.description": ` أنا ناومي، محرك الذكاء الاصطناعي القوي خلف المنصة وسأكون هنا لمساعدتك في إنشاء الفرق وقياس المهارات وتحليل البيانات على مر الزمن.`,
  "dashboard.welcome.firstTime.seeDashboard": "شاهد لوحتي!",
  "dashboard.welcome.firstTime.welcomeMessage": ` أنا ناومي، محرك الذكاء الاصطناعي القوي خلف المنصة وسأكون هنا لمساعدتك في إنشاء الفرق وقياس المهارات وتحليل البيانات على مر الزمن.`,
  "dashboard.trialExpired.title": "انتهت فترة التجربة المجانية لمدة 14 يومًا",
  "dashboard.trialExpired.desc":
    "لا تقلق، لا يزال بإمكانك الوصول إلى جميع بياناتك ولكن تم قفل ملفك الشخصي الآن مع بعض القيود. اختر خطة لاستعادة الوصول الكامل.",
  "teams.challengeDetails": "عرض تفاصيل التحدي",
  "teams.addTalent": "دعوة الموهبة",
  "talentGroup.createNewGroup": "إنشاء مجموعة جديدة",
  "teams.createGroup": "إنشاء مجموعة",
  "teams.talentGroup": "مجموعة الموهبة",
  "teams.attempts": "محاولات",
  "teams.totalTalent": "الإجمالي {total, plural, =1 {موهبة} other {مواهب}}",
  "teams.totalGroup": "الإجمالي {total, plural, =1 {مجموعة} other {مجموعات}}",
  "teams.searchInputPlaceholder": "ابحث عن الموهبة، مجموعة الموهبة",
  "teams.searchGroupPlaceholder": "ابحث باسم الفريق",
  "teams.profile.noTalentSelect": "لم يتم اختيار موهبة",
  "teams.profile.noTalentFound": "لم يتم العثور على موهبة",
  "teams.profile.noTalentAdded": "لم تتم إضافة أي موهبة بعد",
  "teams.profile.clickOnProfile":
    "انقر على الموهبة في {direction} لعرض ملفاتهم الشخصية هنا!",
  "teams.talent": "الموهبة",
  "teams.talent.filter.progress": "التقدم",
  "teams.talent.filter.createdat": "آخر إضافة",
  "teams.talent.filter.updatedat": "آخر تحديث",
  "teams.talent.filter.score": "الدرجة",
  "teams.talent.filter.name": "الاسم",
  "teams.talentGroups.filter": "عرض:",
  "teams.talentGroups.filter.all": "الكل",
  "teams.talentGroups.filter.internal": "الموظفون",
  "teams.talentGroups.filter.external": "المرشحون",
  "teams.talentProfile": "ملف الموهبة",
  "teams.talentDynamic": "ديناميكيات الموهبة",
  "teams.talentProfile.profile": "عرض ملف ناغيت",
  "teams.talentProfile.profileTooltip":
    "{name} لديه ملف ناغيت عام. انقر للتحقق من ذلك",
  "teams.talentDynamic.talent.description":
    "انقر على وسمة الموهبة أدناه لتثبيتها على الرسم البياني أعلاه",
  "teams.talent.all": "الكل ({total})",
  "teams.talent.employee": "الموظفون ({total})",
  "teams.talent.candidate": "المرشحون ({total})",
  "jobs.notFound": "لم يتم العثور على وظائف!",
  "jobs.assignTeamMate": "تعيين زملاء الفريق",
  "jobs.assignTeamMate.placeholder": "اكتب اسم المستخدم، ثم اضغط إدخال",
  "jobs.assignTeamMate.jobRole": "دور الوظيفة",
  "jobs.import": "استيراد الوظائف",
  "jobs.noJobs": ` يمكنك البدء في إنشاء وظائف من خلال النقر على زر 'إنشاء وظيفة جديدة' في الأعلى!`,
  "jobs.entryLevel": "مستوى دخول - {jobType}",
  "jobs.closeDate": "تاريخ الإغلاق - {closeDate}",
  "jobs.edit": "تحرير الوظيفة",
  "jobs.edit.desc":
    "في هذه القائمة يمكنك تحرير إعدادات الوظيفة وتقديمها للمراجعة مرة أخرى.",
  "jobs.assign": "تعيين",
  "jobs.assign.desc": "إدارة الزملاء الذين تم تعيينهم لهذا الإعلان الوظيفي",
  "jobs.delete": "حذف الوظيفة",
  "jobs.delete.desc": "حذف إعلان الوظيفة بشكل دائم",
  "jobs.delete.body":
    "هل أنت متأكد أنك تريد حذف <bold>{name}</bold>؟ سيتم حذف جميع محتوياته بشكل دائم ولا يمكن استعادتها",
  "jobs.public": "عام",
  "jobs.public.desc":
    "تشغيل/إيقاف للتحكم في من يمكنه عرض إعلان الوظيفة في الفريق",
  "jobs.createdBy": "تم الإنشاء بواسطة {name}",
  "jobs.draft": "مسودة",
  "jobs.active": "نشط",
  "jobs.permission.0": "محرر",
  "jobs.permission.1": "عارض",
  "jobs.status": "حالة الوظيفة",
  "jobs.type": "نوع الوظيفة",
  "jobs.creator": "منشئ الوظيفة",

  "accountSettings.title": "الإعدادات",
  "accountSettings.account": "الحساب",
  "accountSettings.billing": "الفواتير",
  "accountSettings.security": "الأمان",
  "accountSettings.notification": "الإشعارات",
  "accountSettings.emailVerified": "تم التحقق من البريد الإلكتروني",
  "accountSettings.emailNotVerified": "لم يتم التحقق من البريد الإلكتروني",
  "accountSettings.placeholder.username": "اسمك الأول",
  "accountSettings.placeholder.lastname": "اسم عائلتك",
  "accountSettings.billing.title": "الخطط المتاحة",
  "accountSettings.billing.bothPlansCancelled.title":
    "تم إلغاء خطة {plan1} و{plan2}",
  "accountSettings.billing.bothPlansCancelled.desc":
    "ستظل قادرًا على الوصول إلى ميزات خطة {plan1} و{plan2} حتى <bold>{date}</bold>.",
  "accountSettings.billing.bothPlansCancelled.subdesc":
    "<bold>ملاحظة</bold>: سيتم تحصيل الدفع النهائي لتقييم المهارات في نهاية دورة الفوترة.",
  "accountSettings.billing.hiring.title": "تم إلغاء خطة توظيفك",
  "accountSettings.billing.hiring.message":
    "ستظل قادرًا على الوصول إلى ميزات خطة التوظيف حتى <bold>{date}</bold>.",
  "accountSettings.billing.benchmarking.title":
    "تم إلغاء خطة تقييم المهارات الخاصة بك",
  "accountSettings.billing.benchmarking.message":
    "ستظل قادرًا على الوصول إلى ميزات خطة تقييم المهارات حتى <bold>{date}</bold>. سيتم تحصيل رسوم إضافية للموظفين في الدفع النهائي.",
  "accountSettings.billing.myPlans": "خططي",
  "accountSettings.billing.nextPayment": "دفعك التالي مجدول في {date}",
  "accountSettings.billing.isCancelled": "تم جدولة الإلغاء",
  "accountSettings.billing.benchmarking.no.nextPayment":
    "الدفع النهائي مجدول في {date}.",
  "accountSettings.billing.hiring.no.nextPayment": "ساري حتى {date}.",
  "accountSettings.billing.benchmarking.note":
    "* ملاحظة: نحن نفرض رسومًا فقط على الموظفين الذين يكملون التحدي بالكامل. يمكنك تقييم ذلك عندما يصل تقدمهم إلى 100%.",
  "accountSettings.billing.desc":
    "يمكنك إضافة خطة إضافية فوق الخطة الحالية لديك.",
  "companySettings.drag&DropYourLogo": "اسحب وأفلت شعارك هنا",
  "companySettings.dropYourFiles": "أفلت ملفاتك هنا",
  "org.placeholder.industry": "صناعة شركتك",
  "org.placeholder.companySize": "حجم شركتك",
  "org.placeholder.name": "اسم شركتك",
  "org.company": "الشركة",
  "org.companyName": "اسم الشركة",
  "org.companyLogo": "شعار الشركة",
  "org.yourRole": "دورك",
  "org.companySize": "حجم الشركة",
  "org.companyIndustry": "صناعة الشركة",
  "changePassword.currentPassword": "كلمة المرور الحالية",
  "changePassword.newPassword": "كلمة المرور الجديدة",
  "changePassword.confirmNewPassword": "تأكيد كلمة المرور الجديدة",
  "changePassword.passwordError": "كلمات المرور لا تتطابق",
  "changePassword.success": "تم تحديث كلمة المرور بنجاح.",
  "addPassword.success": "تم إضافة كلمة المرور بنجاح.",
  "accountSettings.security.deleteAccount.title": "حذف الحساب",
  "accountSettings.security.deleteAccount.description":
    "ستخسر الوصول إلى هذا الحساب وجميع البيانات الخاصة به. لا يمكن التراجع عن ذلك.",
  "auth.forgotPassword.0.title": "هل نسيت كلمة المرور؟",
  "auth.forgotPassword.0.desc": "أدخل عنوان البريد الإلكتروني المرتبط بحسابك",
  "auth.forgotPassword.1.title": "تحقق من بريدك الإلكتروني",
  "auth.forgotPassword.1.desc":
    "يرجى إدخال الرمز المكون من 4 أرقام المرسل إلى {email}",
  "auth.forgotPassword.2.title": "إعادة تعيين كلمة المرور",
  "auth.forgotPassword.2.desc": "يرجى إضافة كلمة المرور الجديدة",
  "auth.forgotPassword.success.title": "تهانينا!",
  "auth.forgotPassword.success.msg": "تم إعادة تعيين كلمة المرور بنجاح",
  "auth.forgotPassword.resetPassword": "إعادة تعيين كلمة المرور",
  "alert.trial.expired":
    "لا توجد خطة نشطة. يرجى تنشيط خطة للاستمرار في استخدام Supertalent",
  "alert.benchmarking.plan.canceled":
    "تم <bold>إلغاء خطة {plan}</bold> وسيتم تحصيل الدفع النهائي في {date}",
  "alert.hiring.plan.canceled": "تم إلغاء خطة {plan} في <bold>{date}</bold>",
  "alert.plans.canceled": "تم إلغاء خططي {first} و{second} في {date}",
  "alert.plan.failed":
    "فشل الدفع الأخير لخطة {plan}. يرجى تحديث بطاقة الائتمان الخاصة بك من بوابة الفواتير في الإعدادات > الفواتير. سنعيد المحاولة حتى 4 مرات خلال الأسبوعين المقبلين قبل إلغاء خطتك.",
  "alert.bothPlans.failed":
    "فشل الدفع الأخير لخطط {benchmarking} و{hiring}. يرجى تحديث بطاقة الائتمان الخاصة بك من بوابة الفواتير في الإعدادات > الفواتير. سنعيد المحاولة حتى 4 مرات خلال الأسبوعين المقبلين قبل إلغاء خططك.",
  "alert.remaining.trial.days": "تجربتك تنتهي في {value} أيام",
  "alert.trial.endToday": "تجربتك تنتهي اليوم",
  activatePlan: "تنشيط الخطة",
  reactivatePlan: "إعادة تنشيط الخطة",
  viewDashboard: "عرض لوحة القيادة",
  "header.settings": "الإعدادات",
  "header.logOut": "تسجيل الخروج",
  "mailVerification.verify": "يرجى التحقق من عنوان بريدك الإلكتروني",
  "mailVerification.emailSent":
    "تم إرسال رسالة تحقق. يرجى التحقق من صندوق الوارد الخاص بك للحصول على الرابط لإتمام العملية.",
  "mailVerification.verified": "تم التحقق من بريدك الإلكتروني!",
  "mailVerification.resendButton": "إعادة إرسال البريد الإلكتروني",
  "mailVerification.resendButtonMobile": "إعادة إرسال تحقق البريد الإلكتروني",
  "mailVerification.resendMessage": "تم إرسال البريد الإلكتروني بنجاح",
  "mailVerification.teams": "تحتاج إلى التحقق من بريدك الإلكتروني لإنشاء فريق!",
  "tooltip.teamDynamic":
    "الرسم البياني العنكبوتي أدناه يظهر مستويات مهارات مجموعات مواهبك والمواهب. انقر على مجموعة مواهب أو موهبة فردية لعرضها أو إخفائها في الرسم البياني.",
  "tooltip.talentGroup": "من الأفضل فصل المواهب إلى مجموعات",
  "tooltip.emailReminder": "إرسال تذكير عبر البريد الإلكتروني لإكمال التحدي",
  continue: "استمرار",
  pin: "ثبّت",
  shortlist: "قائمة مختصرة",
  removeShortlist: "إزالة من القائمة المختصرة",
  customFields: "حقول مخصصة",
  modifyTalent: "تعديل المجموعات",
  deleteTalent: "حذف الموهبة",
  delete: "حذف",
  settings: "الإعدادات",
  save: "حفظ",
  back: "عودة",
  backTo: "العودة إلى {place}",
  clear: "مسح",
  clearAll: "مسح الكل",
  welcome: "أهلاً وسهلاً",
  fieldName: "اسم الحقل",
  value: "القيمة",
  nodata: "لا توجد بيانات",
  activate: "تنشيط",
  "payment.startingAt": "ابتداءً من",
  "payment.simple.pricing": "تسعير بسيط",
  "payment.monthly": "شهريًا",
  "payment.yearly": "سنويًا",
  "payment.calculate.cost.desc":
    "يتم حساب تكاليفك في نهاية الشهر بناءً على خطة تسعير تدريجية تتبع عدد الملفات الشخصية للموظفين النشطين في حسابك.",
  "payment.calculate.cost.desc.0":
    "على سبيل المثال، إذا كان لديك {count} ملفًا شخصيًا للموظف، ستدفع رسومًا ثابتة قدرها 600 دولار.",
  "payment.calculate.cost.desc.1":
    "على سبيل المثال، إذا كان لديك {count} ملفًا شخصيًا للموظف، ستدفع رسومًا ثابتة قدرها 600 دولار لأول 20 موظفًا و30 دولارًا لكل موظف إضافي.",
  "payment.calculate.cost.desc.2":
    "على سبيل المثال، إذا كان لديك {count} ملفًا شخصيًا للموظف، ستدفع رسومًا ثابتة قدرها 600 دولار لأول 20 موظفًا، و30 دولارًا لكل موظف من الـ 30 موظفًا التاليين، و20 دولارًا لكل موظف من الـ {diff} الموظفين التاليين.",
  "payment.calculate.cost": "حساب التكلفة المقدرة",
  "payment.calculate.label":
    "قم بتحريك الشريط أو إدخال عدد الموظفين في مؤسستك:",
  "payment.calculate.desc": "أضف عدد الموظفين لمساعدتك في تحديد السعر الشهري",
  "payment.estimated.monthly": "التكلفة الشهرية المقدرة:",
  "payment.show.pricingTable": "عرض جدول التسعير",
  "payment.hide.pricingTable": "إخفاء جدول التسعير",
  "payment.pricing.table.numEmployee": "عدد الموظفين",
  "payment.pricing.table.perEmployee": "لكل موظف",
  "payment.pricing.table.flatFee": "رسوم ثابتة",
  "payment.plan": "الخطة",
  "payment.plan.desc":
    "اختر الخطة التي تناسب احتياجاتك أكثر. هل تحتاج إلى كليهما؟ يمكنك إضافة الخطة الثانية إلى نفس دورة الفوترة بمجرد إعدادها.",
  payment: "الدفع",
  manage: "إدارة",
  "payment.plan.contact":
    "هل تحتاج إلى المزيد؟ تواصل مع المبيعات لحلول المؤسسات",
  "plans.freeTrial": "ابدأ تجربة مجانية لمدة 14 يومًا",
  "talentGroup.filters.talentGroup": "مجموعة المواهب",
  "talentGroup.filters.Bookmark": "إشارة مرجعية",
  "talentGroup.filters.Shortlist": "القائمة المختصرة",
  "talentProfile.empty.note": "لا توجد ملاحظات",
  "talentProfile.edit.note": "تعديل الملاحظة",
  "teamCard.talent": "{count, plural, =1 {موهبة} other {مواهب}}",
  "teamCardMenu.edit.primary": "إعادة تسمية",
  "teamCardMenu.mask.primary": "إخفاء الموهبة",
  "teamCardMenu.editattempts.primary": "تعديل المحاولات",
  "teamCardMenu.editattempts.secondary": "تغيير المحاولات.",
  "teamCardMenu.delete.primary": "حذف الفريق",
  "teamCardMenu.edit.secondary": "تغيير اسم الفريق.",
  "teamCardMenu.mask.secondary":
    "إعطاء الموهبة الفرصة لإخفاء المعلومات الشخصية مثل الاسم والبريد الإلكتروني، وما إلى ذلك.",
  "teamCardMenu.delete.secondary": "سيتم حذف فريقك إلى الأبد.",
  "createableSelect.title": "اختر خيارًا أو ابدأ الكتابة لإنشاء خيار",
  "createableSelect.editWarning": "هذا الخيار موجود بالفعل",
  "createableSelect.deleteOption": "حذف الخيار",
  "createableSelect.deleteOption.body":
    "هل أنت متأكد أنك تريد إزالة هذا الخيار؟",
  "createableSelect.deleteOption.okText": "إزالة",
  addTalent: "دعوة الموهبة",
  talent: "الموهبة",
  "addTalent.placeholder": "اختر مجموعة الموهبة للاستيراد",
  "addTalent.talentGroup.error.required": "حقل مجموعة الموهبة مطلوب",
  "addTalent.bulkSuccess": "تم إضافة المستخدمين بنجاح بالجملة",
  "addTalent.toastSucess": "تمت دعوة المستخدمين بنجاح",
  "addTalent.helperText":
    "سيتم إرسال دعوة عبر البريد الإلكتروني تلقائيًا إلى الرسائل الإلكترونية التي تقدمها",
  "addTalent.uploadCount": "تم تحميل المستخدمين: {count}",
  "addTalent.remove": "إزالة",
  "talentGroup.createNew": "إنشاء مجموعة موهبة جديدة",
  "talentGroup.edit": "تعديل المجموعة",
  "talentGroup.teamName.error.required": "حقل اسم الفريق مطلوب",
  "talentGroup.type.error.required": "حقل النوع مطلوب",
  "talentGroup.color.error.required": "حقل لون الفريق مطلوب",
  "talentGroup.helperText":
    "سيتم إرسال دعوة عبر البريد الإلكتروني تلقائيًا إلى الرسائل الإلكترونية التي تقدمها",
  "sendEmailReminder.title": "إرسال تذكير عبر البريد الإلكتروني",
  "sendEmailReminder.label": "البريد الإلكتروني",
  "sendEmailReminder.placeholder": "أدخل عدة رسائل بريد إلكتروني مفصولة بمسافة",
  "sendEmailReminder.helperText":
    "يمكنك إرسال تذكيرات عبر البريد الإلكتروني فقط إلى الموهبة الموجودة في مجموعة الموهبة الخاصة بك",
  "sendEmailReminder.checkboxLabel":
    "إرسال تذكير جماعي عبر البريد الإلكتروني للجميع في مجموعة الموهبة الذين لم يكملوا التحدي",
  "sendEmailReminder.btnLabel": "إرسال",
  "sendEmailReminder.error.invalid":
    "عنوان البريد الإلكتروني غير صالح: {emails}",
  "sendEmailReminder.success":
    "تم إرسال {recipientCount} تذكير عبر البريد الإلكتروني بنجاح",
  "sendEmailReminder.success.unique":
    "تم إرسال تذكير عبر البريد الإلكتروني بنجاح",
  "sendEmailReminderModal.title":
    "هل تريد إرسال تذكير عبر البريد الإلكتروني إلى {masked}؟",
  "sendEmailReminderModal.desc":
    "بمجرد النقر على 'إرسال'، سيتم إرسال تذكير عبر البريد الإلكتروني إلى {masked} لإكمال التحدي.",
  "sendEmailReminder.maskedTalent": "الموهبة المحجوبة",
  "editTalent.label": "مجموعة الموهبة",
  "editTalent.desc": "أضف أو أزل مجموعات الموهبة لـ {masked}",
  "editTalent.helper":
    "لا يمكن أن تنتمي الموهبة إلى مجموعة الموظفين والمرشحين في نفس الوقت",
  "challengeSubmission.title": "أكمل {value} من التحديات",
  "challengeSubmission.orderedListSubmission": "قائمة مرتبة من الموهبة",
  "challengeDetails.desc": "عرض قائمة التحديات التي يجب أن تجيب عليها الموهبة",
  "challengeSubmission.instruction":
    "رتب هذه الخيارات بناءً على احتمالية مشاركتك في السلوك، من الأعلى إلى الأسفل كم هو أقل احتمالاً",
  "comment.placeholder": "اكتب تعليق...",
  answer: "إجابة",
  "challengeSubmission.prev": "الوعي البيني",
  "challengeSubmission.next": "البحث عن المعلومات",
  totalDuration: "الوقت المتوقع:",
  challengeDetails: "تفاصيل التحدي",
  preview: "معاينة",
  progress: "التقدم",
  mins: "دقائق",
  spent: "منقضية",
  outOf: "من أصل",
  "legend.consulting": "استشارات",
  "legend.Series": "سلسلة",
  completed: "مكتمل",
  incomplete: "غير مكتمل",
  filters: "الفلاتر",
  viewSubmission: "عرض التقديم",
  badges: "الشارات",
  employee: "موظف",
  candidate: "مرشح",
  "addedAt.min": "أضيف منذ {min} دقيقة",
  "addedAt.hour": "أضيف منذ {hour} ساعة",
  "addedAt.day": "أضيف منذ {day} يوم",
  "addedAt.date": "أضيف في {date}",
  profile: "الملف الشخصي",
  roleInterests: "اهتمامات الدور",
  selfReportedSkills: "المهارات المعلنة من قبل النفس",
  roleFunction: "وظيفة الدور",
  roleLevel: "مستوى الدور",
  experienceLevel: "مستوى الخبرة",
  education: "التعليم",
  softSkillScore: "درجة المهارات الناعمة",
  challengeSubmission: "تقديم التحدي",
  notes: "ملاحظات",
  challengeProgress: "تقدم التحدي",
  talentGroups: "مجموعات الموهبة",
  sendEmailReminder: "إرسال تذكير عبر البريد الإلكتروني",
  deleteFormName: "حذف {masked}؟",
  deleteFormDesc:
    "هل أنت متأكد أنك تريد حذف {masked}؟ سيتم حذف جميع محتوياته بشكل دائم ولا يمكن استعادتها",
  editForm: "تعديل {value}",
  editTalentForm: "تعديل المجموعات لـ {masked}",
  EditAttempts: "تعديل المحاولات {value}",
  edit: "تعديل",
  talentGroupName: "اسم مجموعة الموهبة",
  talentType: "نوع الموهبة",
  updatedUser: "تم تحديث الحساب بنجاح.",
  updatedOrg: "تم تحديث الشركة بنجاح.",
  copiedLink: "تم نسخ الرابط بنجاح إلى الحافظة",
  publicLink: "رابط عام",
  copyLink: "نسخ الرابط",
  link: "رابط",
  importVia: "استيراد عبر",
  importUser: "استيراد مستخدم",
  "importUser.desc": "سيتم إرسال التحدي تلقائيًا للمواهب الجديدة عند استيرادها",
  type: "نوع",
  file: "ملف",
  groupColor: "لون المجموعة",
  fileDesc:
    "سيتم إرسال دعوة عبر البريد الإلكتروني تلقائيًا للمواهب الجديدة لإكمال التحدي بمجرد استيرادها",
  import: "استيراد",
  uploadFile: "تحميل الملف",
  loading: "جارٍ التحميل",
  createOption: 'إنشاء "{value}"',
  warning: "تحذير",
  dashboard: "لوحة التحكم",
  monitor: "مراقبة",
  name: "الاسم",
  email: "البريد الإلكتروني",
  nuggetScore: "درجة النوجت",
  score: "الدرجة",
  account: "الحساب",
  password: "كلمة المرور",
  firstName: "الاسم الأول",
  lastName: "الاسم الأخير",
  finish: "إنهاء",
  newSinceLastLogin: "{count} موهبة جديدة",
  new: "جديد",
  status: "الحالة",
  skills: "المهارات",
  tags: "العلامات",
  location: "الموقع",
  or: "أو",
  challengeCopied: "تم نسخ رابط التحدي",
  linkedin: "لينكد إن",
  emailAddress: "عنوان البريد الإلكتروني",
  resume: "السيرة الذاتية",
  sortBy: "ترتيب حسب",
  createdAt: "تم الإنشاء في",
  updatedAt: "تم التحديث في",
  questions: "الأسئلة",
  // Onboarding
  "onboarding.skill.with.industry.benchmark": "مؤشر الصناعة متاح!",
  "onboarding.skill.without.industry.benchmark": "مؤشر الصناعة قادم قريباً!",
  "onboarding.show.more.skills": "عرض المزيد من المهارات!",
  "onboarding.show.less.skills": "عرض مهارات أقل!",
  "onboarding.help.guide.header.title": "دليل المساعدة",
  "onboarding.help.guide.title": "الدليل العام",
  "onboarding.help.guide.btn.label": "دليل المساعدة",

  // Onboarding - challenge selection
  "onboarding.select.challenge.creation.title": "حدد كيفية إنشاء حزمة التحديات",

  // Onboarding - challenges selection items
  "onboarding.select.role.based.challenges.title": "مبني على الدور",
  "onboarding.select.role.based.challenges.desc":
    "حدد كيفية إنشاء حزمة التحديات",
  "onboarding.select.guided.challenges.title": "موجه",
  "onboarding.select.guided.challenges.desc":
    "أجب على سلسلة من الأسئلة وسنوصي بأفضل التحديات لك",
  "onboarding.select.free.form.title": "حر",
  "onboarding.select.free.form.desc":
    "إنه موسم مفتوح! اختر من مكتبتنا الكاملة من المهارات",

  // GUIDED CHALLENGES
  // Onboarding - guided challenges steps
  "onboarding.guided.challenges.step.0": "الأسئلة",
  "onboarding.guided.challenges.step.1": "معاينة حزمة التحديات",
  "onboarding.guided.challenges.step.2": "الفريق",
  "onboarding.guided.challenges.subStep.0": "قيم الشركة",
  "onboarding.guided.challenges.subStep.1": "أفضل أداء",
  "onboarding.guided.challenges.subStep.2": "ملاءمة الثقافة",
  "onboarding.guided.challenges.subStep.3": "بطل الثقافة",

  // Onboarding - guided challenges (skill selection - root step 1)
  "onboarding.guided.challenges.skillSelection.title.0":
    "فكر في قيم وأهداف شركتك، وحدد فئات المهارات الناعمة التي تعتقد أنها الأكثر صلة.",
  "onboarding.guided.challenges.skillSelection.description.0":
    "حدد حد أدنى من 3 مهارات",
  "onboarding.guided.challenges.skillSelection.title.1":
    "الآن، نعرض المهارات الناعمة ضمن الفئات التي حددتها. أي من هذه المهارات تعتبر الأكثر أهمية لنجاح الموظف في شركتك؟",
  "onboarding.guided.challenges.skillSelection.description.1":
    "حدد حد أدنى من 3 مهارات",
  "onboarding.guided.challenges.skillSelection.title.2":
    "أحياناً حتى الموظفين الذين ليسوا الأفضل أداءً هم من يمثلون ويدعمون قيم الشركة. أي من هذه المهارات مهمة ليكون الموظف بطل ثقافة في شركتك؟",
  "onboarding.guided.challenges.skillSelection.description.2":
    "حدد حد أدنى من 3 مهارات",
  "onboarding.guided.challenges.skillSelection.title.3":
    "دعنا نغير الموضوع قليلاً عن أفضل الأداء وأبطال الثقافة. أي من هذه المهارات تعتقد أن أعضاء فريقك يمكنهم تطويرها أكثر؟",
  "onboarding.guided.challenges.skillSelection.description.3":
    "حدد حد أدنى من 3 مهارات",

  // Onboarding - guided challenges guides
  "onboarding.guided.challenges.help.guide.title.step.0": "قيم الشركة",
  "onboarding.guided.challenges.help.guide.desc.step.0":
    "على الرغم من أن جميع فئات المهارات قد تكون جزءاً من قيم شركتك، إلا أنه يجب عليك ترتيب الأولويات لتحديد أي منها يمثل القيم الأكثر أهمية لشركتك. أيضاً، ضع في اعتبارك هدفك من إنشاء حزمة التحديات عند تحديد الفئات التي تريدها.",
  "onboarding.guided.challenges.help.guide.title.step.1": "أفضل أداء",
  "onboarding.guided.challenges.help.guide.desc.step.1":
    "نريد منك التفكير في المهارات التي يجب أن يمتلكها الموظف ليتميز في بيئة العمل بشركتك. على سبيل المثال، إذا كانت شركتك تروج لبيئة عمل سريعة، فما هي المهارات التي تمكن الموظفين من الأداء بأفضل شكل في هذه البيئة؟",
  "onboarding.guided.challenges.help.guide.title.step.2": "ملاءمة الثقافة",
  "onboarding.guided.challenges.help.guide.desc.step.2":
    "نريد منك التفكير في قيم شركتك وأي المهارات ستكون مهمة ليكون الموظف مناسباً لهذه القيم.",
  "onboarding.guided.challenges.help.guide.title.step.3": "بطل الثقافة",
  "onboarding.guided.challenges.help.guide.desc.step.3":
    "بطل الثقافة هو شخص يكرس قيم شركتك. هؤلاء الموظفون قد لا يكونون بالضرورة أفضل أداء في العمل (على الرغم من أنهم قد يكونون)، ولكنهم يجسدون قيم الشركة.",
  "onboarding.guided.challenges.help.guide.title.step.4":
    "معاينة حزمة التحديات",
  "onboarding.guided.challenges.help.guide.desc.step.4":
    "المهارات المعروضة مجمعة إلى 'مهارات يجب أن تكون' و'مهارات جيدة أن تكون' حسب إجاباتك على الأسئلة. كل مهارة تمثل تحدياً يتضمن سلسلة من السيناريوهات التي يجب على المشارك في التحدي التفاعل معها.",
  "onboarding.guided.challenges.help.guide.title.step.5": "الفريق",
  "onboarding.guided.challenges.help.guide.desc.step.5":
    "تسمح لك هذه الصفحة الأخيرة بتسمية المجموعة الموهوبة التي تختبرها وإدخال بعض المعلومات الأخرى لإتمام إنشاء حزمة التحديات.",

  // Onboarding - guided challenges (root step)
  "onboarding.guided.challenges.step.1.title": "مراجعة المهارات",
  "onboarding.guided.challenges.step.1.desc":
    "إليك توصيات المهارات. استخدم المقابض على اليمين لترتيبها من الأكثر إلى الأقل أهمية.",
  "onboarding.guided.challenges.step.2.title": "تفاصيل الفريق",

  // ROLE BASED CHALLENGES
  "onboarding.role.based.challenges.step.0": "الدور",
  "onboarding.role.based.challenges.step.1": "التحديات",
  "onboarding.role.based.challenges.step.2": "معاينة حزمة التحديات",
  "onboarding.role.based.challenges.step.3": "الفريق",

  "onboarding.role.based.challenges.step.0.title": "حدد دوراً للبدء",
  "onboarding.role.based.challenges.step.1.title":
    "حدد المهارات التي ترغب في تضمينها في حزمة التحديات لـ",
  "onboarding.role.based.challenges.step.1.description":
    "حدد حد أدنى من 3 مهارات",
  "onboarding.role.based.challenges.step.2.title": "معاينة حزمة التحديات",
  "onboarding.role.based.challenges.step.2.desc":
    "إليك نظرة أخيرة على المهارات المضمنة في حزمة التحديات الخاصة بك.",
  "onboarding.role.based.challenges.step.3.title": "تفاصيل الفريق",

  "onboarding.role.based.challenges.help.guide.title.step.0": "الدور",
  "onboarding.role.based.challenges.help.guide.desc.step.0":
    "حدد دوراً يناسب المجموعة التي ترغب في اختبارها.",
  "onboarding.role.based.challenges.help.guide.title.step.1": "التحديات",
  "onboarding.role.based.challenges.help.guide.desc.step.1":
    "التحديات الافتراضية تعتمد على معايير الصناعة لأهم المهارات المطلوبة للنجاح في هذا الدور.",
  "onboarding.role.based.challenges.help.guide.title.step.2":
    "معاينة حزمة التحديات",
  "onboarding.role.based.challenges.help.guide.desc.step.2":
    "يتيح لك معاينة حزمة التحديات إلقاء نظرة أخيرة على التحديات التي اخترتها.",
  // الخطوات الحرة - التوجيه
  "onboarding.free.form.step.0": "التحديات",
  "onboarding.free.form.step.1": "معاينة حزمة التحديات",
  "onboarding.free.form.step.2": "الفريق",

  // التوجيه - الخطوات الحرة
  /* الخطوة الأولى */
  "onboarding.free.form.step.0.title":
    "اختر المهارات وقم ببناء حزمة التحديات الخاصة بك",
  "onboarding.free.form.step.0.description": "اختر ما لا يقل عن 3 مهارات",
  /* الخطوة الثانية */
  "onboarding.free.form.step.1.title": "معاينة التحدي",
  "onboarding.free.form.step.1.desc":
    "إليك نظرة أخيرة على المهارات التي تم اختيارها في حزمة التحديات الخاصة بك! استخدم المقابض (=) على اليمين لتحريك وترتيب المهارات من الأهم (أعلى) إلى الأقل أهمية (أسفل). يمكنك أيضًا حذف المهارات غير المرغوب فيها من خلال الضغط على علامة الطرح الحمراء (-).",
  /* الخطوة الرابعة */
  "onboarding.free.form.step.2.title": "تفاصيل الفريق",

  // التوجيه - الأدلة الحرة
  "onboarding.free.form.help.guide.title.step.0": "التحديات",
  "onboarding.free.form.help.guide.desc.step.0":
    "التحديات الافتراضية مبنية على المعايير الصناعية لأهم المهارات الشخصية المطلوبة للنجاح في هذا الدور. إنها تهدف إلى مساعدتك، ولكنك غير مقيد بها. يمكنك إضافة أو إزالة التحديات كما تشاء. تأكد من التحقق من المؤشر الأخضر - حيث يعرض وجود معيار صناعي، مما سيساعدك في تحديد كيفية مقارنة درجة المشارك مع المتوسط الصناعي.",
  "onboarding.free.form.help.guide.title.step.1": "معاينة حزمة التحديات",
  "onboarding.free.form.help.guide.desc.step.1":
    "تسمح لك معاينة حزمة التحديات بإلقاء نظرة أخيرة على التحديات التي اخترتها. يمكنك ضبط ترتيب ظهورها وإزالة تلك التي لم تعد ترغب في اختبارها. يمكنك أيضًا النقر على زر 'معاينة التحدي' لرؤية نوع الاختبار الذي سيخضع له المستخدمون.",
  "onboarding.free.form.help.guide.title.step.2": "الفريق",
  "onboarding.free.form.help.guide.desc.step.2":
    "تسمح لك هذه الصفحة النهائية بتسمية مجموعة المواهب التي تختبرها وملء بعض المعلومات الأخرى لإتمام إنشاء حزمة التحديات الخاصة بك. يمكنك أيضًا دعوة تحديات من هذه الصفحة، ولكن ليس من الضروري القيام بذلك الآن - بمجرد إنشاء الفريق، سيكون لديك رابط لحزمة التحديات ويمكنك مشاركته في وقت لاحق.",

  // -- قديم -- //
  // إنشاء الفريق
  "teams.onboarding.title": "إنشاء فريق جديد",
  "teams.onboarding.learnMore": "تعرف أكثر.",

  // إنشاء الفريق - الهدف/
  "teams.onboarding.objective.restricted.0":
    "يجب أن يكون لديك خطة توظيف للوصول إلى هذه الميزة.",
  "teams.onboarding.objective.restricted.1":
    "يجب أن يكون لديك خطة مرجعية للوصول إلى هذه الميزة.",
  "teams.onboarding.objective.stepTitle": "الهدف",
  "teams.onboarding.objective.nyomiMessage":
    "يستخدم nugget.ai التقييمات المعتمدة على المحاكاة (ما نسميه تحديات nugget.ai) للمساعدة في قياس مهارات المرشحين والموظفين. يمكن استخدام هذه الأدوات للمساعدة في توظيف أفضل المواهب أو قياس مهارات موظفيك الحاليين.",
  "teams.onboarding.objective.title": "اختر هدفًا للتركيز عليه",
  "teams.onboarding.objective.description":
    "سيحدد الهدف كيفية توصية المهارات لك.",
  "teams.onboarding.objective.learnMore":
    "يستخدم nugget.ai التقييمات المعتمدة على المحاكاة، التي تُسمى 'التحديات'، للمساعدة في قياس مهارات المرشحين والموظفين. يمكن استخدام هذه الأدوات للمساعدة في توظيف أفضل المواهب أو قياس مهارات موظفيك الحاليين. \n\nيمكنك اختيار هدف واحد فقط للبدء، ولكن بمجرد تنشيط الفريق، لا يوجد ما يمنعك من إنشاء مجموعات لتوظيف وقياس الأداء في نفس الوقت.",
  // إنشاء الفريق - الهدف -> الاختيارات
  "teams.onboarding.objective.hiring.tooltip":
    "يمكننا مساعدتك في قياس مهارات تجمعات المواهب لديك، مما يوفر لك توصيات حول من هو الأنسب لوظيفة ما لمساعدتك في توظيف أفضل مرشح.",
  "teams.onboarding.objective.benchmarking.tooltip":
    "يمكننا أيضًا مساعدتك في قياس مهارات الموظفين في شركتك، مما يتيح لك تحديد مجالات القوة لموظفيك بالإضافة إلى المجالات التي قد تحتاج إلى تحسين. يمكنك فحص مهارات الموظفين الفرديين، والفرق، والشركة ككل، واكتشاف ما يميز الأشخاص في شركتك.",
  // إنشاء الفريق - /الهدف

  // إنشاء الفريق - تحديد المهارات/
  "teams.onboarding.skillIdentification.stepTitle": "التفاصيل",
  "teams.onboarding.skillIdentification.nyomiMessage.initial":
    "هذه هي بداية عملية اختيار التحدي من nugget.ai! سيتم سؤالك <bold>أربعة أسئلة إجمالية تهدف إلى إعطائنا نظرة</bold> على المهارات الشخصية التي يتم تقديرها ومكافأتها وتعزيزها في شركتك.",
  "teams.onboarding.skillIdentification.title.0.tooltip":
    "المهارات الشخصية هي المهارات القابلة للتحويل والتكيف التي تدعم الأداء الفعّال في العمل، مثل التواصل وإدارة الوقت. وهي تختلف عن المهارات الفنية، وهي المهارات التقنية اللازمة للوظيفة.",
  "teams.onboarding.skillIdentification.description.0":
    "إليك مجموعة من فئات المهارات الشخصية من nugget.ai. خذ وقتك في التفكير في هذا، حيث من المهم أن تحصل على فهم دقيق لما يجعل الشخص مرشحًا جيدًا لهذه الوظيفة. ستتمكن من تحديد مهارات أكثر تحديدًا في الأسئلة التالية. يرجى اختيار كل ما ينطبق.",
  "teams.onboarding.skillIdentification.title.1":
    "الآن، نعرض المهارات الشخصية ضمن الفئات التي اخترتها. ما هي المهارات التي تعتبرها ضرورية ليتميز الموظف في هذا الدور؟",
  "teams.onboarding.skillIdentification.description.1": "اختر كل ما ينطبق.",

  // إنشاء الفريق - تحديد المهارات - المقارنة/
  "teams.onboarding.skillIdentification.benchmarking.nyomiMessage.succeeding":
    "باستخدام فئات المهارات التي حددتها في السؤال الأخير، سنطرح عليك الآن أسئلة لمساعدتنا في تحديد المهارات الشخصية المحددة التي تتعلق بشركتك.",

  all: "الكل",
  faq: "الأسئلة المتكررة",
  yes: "نعم",
  no: "لا",
  multiEmailFieldPlaceholder:
    "اكتب بريدًا إلكترونيًا واضغط إدخال لإرساله أو لإضافة آخر",
  browseFiles: "استعرض الملفات",
  fileFormat: "تنسيق الملف",
  maximumSize: "الحجم الأقصى",

  // الجولة
  "tour.title": "قم بجولة!",
  "tour.naomi": "مرحبًا، أنا نعومي، مساعدك الذكي، هنا {linebreak} للمساعدة!",
  "tour.description":
    "هل تحتاج إلى مساعدة في التنقل؟ استكشف أدلة المساعدة لدينا.",
  "tour.seeVideoDemo": "شاهد العرض التوضيحي بالفيديو",
  "tour.viewDemoData": "بيانات العرض التوضيحي",
  "tour.learnAboutOurScience": "علمنا",
  "tour.closingMessage":
    "لا داعي للقلق، يمكنك دائمًا العثور على روابط الدليل في شريط التنقل العلوي.",

  // الجولات
  "tours.team.addTalent": "دعوة المواهب",
  "tours.team.addTalent.desc":
    "انقر هنا لإضافة المواهب. يمكنك استيراد ملف CSV أو إنشاء رابط لإرساله دون الاتصال بالإنترنت.",
  "tours.team.groupCard": "بطاقة مجموعة المواهب",
  "tours.team.groupCard.desc": "نظم مواهبك في مجموعات. لقد أنشأنا لك الأولى.",
  "tours.team.addTalentGroup": "إضافة مجموعة مواهب",
  "tours.team.addTalentGroup.desc": "انقر هنا لإنشاء مجموعة مواهب جديدة.",
  "tours.team.talent": "المواهب",
  "tours.team.talent.desc":
    "أي مواهب تستوردها أو تدعوها باستخدام رابط الفريق ستظهر هنا.",
  "tours.team.talentDynamic": "ديناميكية المواهب",
  "tours.team.talentDynamic.desc":
    "بمجرد أن تكمل المواهب التحديات، ستتمكن من تصور مهاراتهم ومقارنتها مع الآخرين.",
  "tours.team.finish": "انتهى",
};
