import { Button } from '@material-ui/core'
import { lighten, rgbToHex } from '@material-ui/core/styles'
import styled from 'styled-components'
import NaomiFaceIcon from 'assets/icons/NyomiIcons/NyomiFaceIcon'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'

export const NyomiIconContainer = styled(NaomiFaceIcon)`
	width: ${() => pxToRem(81)};
	height: ${() => pxToRem(70)};
	margin-top: 10px;
	text-align: center;
`

export const BoxContainerWelcome = styled.div`
	border: 1px solid ${props => props.theme.primary.main};
	background: ${props => rgbToHex(props.theme.primary.main)}35;
	border-radius: 6px;
	padding: 48px;
	height: 467px;
	display: flex;
	align-items: center;

	@media only screen and (max-width: 400px) {
		padding: 48px 24px;
	}
`

export const TourContainer = styled.div`
	border-radius: 6px;
	padding: 48px;
	border: 1px solid ${props => props.theme.tag.blue};
	/* background: ${props => lighten(props.theme.tag.blue, 0.7)}; */
	background: ${props => rgbToHex(props.theme.tag.blue)}25;
	height: 467px;
	display: flex;
	align-items: center;
	position: relative;
`

export const TourButton = styled(Button)`
	font-weight: 500;
	color: ${props => props.theme.tag.blue};
`

export const TourCloseButton = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;
`
