import styled from 'styled-components'
import { rgbToHex } from '@material-ui/core/styles'

interface LogoUploadContainerProps {
	active: boolean
}

export const LogoUploadContainer = styled.div<LogoUploadContainerProps>`
	background-color: ${props =>
		props.active ? rgbToHex(props.theme.primary.main) : props.theme.grey.main}10;
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23${props =>
		(props.active ? rgbToHex(props.theme.primary.main) : props.theme.grey.main).split(
			'#'
		)[1]}FF' stroke-width='2' stroke-dasharray='4%2c 4' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
	border-radius: 10px;
	position: relative;
	color: ${props => (props.active ? props.theme.primary.main : props.theme.grey.main)};
	height: 168px;
	display: flex;
	align-items: center;
	outline: none;

	h4 {
		font-weight: 500;
		margin-bottom: 15px;
	}

	button {
		color: ${props => (props.theme.mode === 'dark' ? ' #fff' : '#262358')};
	}
`
