import React from 'react'
import iziToast from 'izitoast'
import { Box, Grid, Typography } from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
import { useForm } from 'react-hook-form'
import { useTheme } from 'styled-components'
import { useStoreon } from 'storeon/react'

import { Requirement } from 'components/Requirement'
import { PasswordInput } from 'components/PasswordInput'
import { useChangePasswordMutation, useAddPasswordMutation } from 'hooks/useApollo'
import { useIntl } from 'hooks/useIntl'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'
import { materialTheme } from 'packages/nuggetai.ui-kit/themes/base'
import { createStringRequirements } from 'utils/regexs'

import { Header } from '../components'
import { Paper } from '../../AccountSettings.style'

const passwordRegex = createStringRequirements()

export const Security = () => {
	const intl = useIntl()
	const {
		register,
		errors,
		watch,
		setError,
		getValues,
		setValue,
		reset,
		formState
	} = useForm({
		mode: 'all'
	})
	const theme = useTheme()
	const { dispatch, user } = useStoreon('user')

	const userHasPassword = user?.hasPassword

	const [changePassword, { loading: changingPassword }] = useChangePasswordMutation({
		onCompleted: finalData => {
			if (finalData?.changePassword) {
				iziToast.success({
					message: intl.formatMessage({ id: 'changePassword.success' })
				})
			}
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors) {
				graphQLErrors.forEach(({ message, extensions }) => {
					if (extensions?.exception?.details) {
						if (!Array.isArray(extensions?.exception?.details)) {
							const { details } = extensions?.exception
							setError(details?.key, {
								shouldFocus: true,
								message,
								type: 'validate'
							})
						}
					}
				})
			}
		}
	})

	const [addPassword, { loading: addingPassword }] = useAddPasswordMutation({
		onCompleted: finalData => {
			if (finalData?.addPassword) {
				reset()

				iziToast.success({
					message: intl.formatMessage({ id: 'addPassword.success' })
				})

				if (user) {
					dispatch('user/setUser', { ...user, hasPassword: true })
				}
			}
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors) {
				graphQLErrors.forEach(({ message, extensions }) => {
					if (extensions?.exception?.details) {
						if (!Array.isArray(extensions?.exception?.details)) {
							const { details } = extensions?.exception
							setError(details?.key, {
								shouldFocus: true,
								message,
								type: 'validate'
							})
						}
					}
				})
			}
		}
	})

	const handleChangePassword = async () => {
		const { oldPassword, newPassword } = getValues()

		if (!userHasPassword) {
			addPassword({
				variables: {
					password: newPassword
				}
			})
		} else {
			changePassword({
				variables: {
					userInput: {
						oldPassword,
						newPassword
					}
				}
			})
		}

		setValue('oldPassword', '')
		setValue('newPassword', '')
		setValue('confirmNewPassword', '')
		reset()
	}

	const allFields = watch()

	return (
		<Paper elevation={7}>
			<Header title={intl.formatMessage({ id: 'accountSettings.security' })} />
			<Grid container spacing={5}>
				<Grid item container spacing={5} xs={12}>
					{userHasPassword && (
						<Grid item xs={12}>
							<PasswordInput
								required
								label={intl.formatMessage({ id: 'changePassword.currentPassword' })}
								name="oldPassword"
								inputRef={register({ required: true })}
								error={!!errors.currentPassword}
								helperText={
									errors?.currentPassword?.type === 'custom'
										? errors?.currentPassword?.message
										: undefined
								}
							/>
						</Grid>
					)}
					<Grid item xs={12}>
						<PasswordInput
							required
							error={
								!!errors.newPassword || allFields.newPassword
									? allFields.newPassword === allFields.oldPassword
									: false
							}
							label={intl.formatMessage({ id: 'changePassword.newPassword' })}
							name="newPassword"
							inputRef={register({
								required: true,
								pattern: passwordRegex
							})}
							isCorrect={allFields.password ? !errors.password : false}
						/>
						<Grid
							item
							xs={12}
							container
							spacing={1}
							alignItems="center"
							style={{
								marginTop: materialTheme.spacing(2),
								marginBottom: materialTheme.spacing(1)
							}}
						>
							<Grid item xs={6} sm={4}>
								<Requirement pattern={/[a-z]/} value={allFields.newPassword}>
									{intl.formatMessage({ id: 'validation.lowercase' }, { length: 1 })}
								</Requirement>
							</Grid>
							<Grid item xs={6} sm={4}>
								<Requirement pattern={/[0-9]/} value={allFields.newPassword}>
									{intl.formatMessage({ id: 'validation.number' }, { length: 1 })}
								</Requirement>
							</Grid>
							<Grid item xs={6} sm={4}>
								<Requirement pattern={/.{8,}/} value={allFields.newPassword}>
									{intl.formatMessage({ id: 'validation.minLength' }, { length: 8 })}
								</Requirement>
							</Grid>
							<Grid item xs={6} sm={4}>
								<Requirement pattern={/[A-Z]/} value={allFields.newPassword}>
									{intl.formatMessage({ id: 'validation.uppercase' }, { length: 1 })}
								</Requirement>
							</Grid>
							<Grid item xs={6} sm={4}>
								<Requirement pattern={/[#?!@$%^&*-]/} value={allFields.newPassword}>
									{intl.formatMessage({ id: 'validation.special' }, { length: 1 })}
								</Requirement>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<PasswordInput
							required
							error={
								allFields.confirmNewPassword
									? allFields.confirmNewPassword !== allFields.newPassword
									: false
							}
							label={intl.formatMessage({
								id: 'changePassword.confirmNewPassword'
							})}
							name="confirmNewPassword"
							inputRef={register({
								required: true,
								pattern: passwordRegex
							})}
							isCorrect={allFields.password ? !errors.password : false}
						/>
						{allFields.confirmNewPassword !== allFields.newPassword && (
							<Grid container item alignItems="center">
								<Box sx={{ display: 'flex', mt: 1.75 }}>
									<Box
										className="check-icon"
										sx={{
											width: 20,
											height: 20,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											background: theme.tag.red,
											color: 'white',
											borderRadius: '50%',
											mr: 1.75
										}}
									>
										<i className="bx bx-x" />
									</Box>
									<Typography sx={{ color: theme.tag.red, fontSize: pxToRem(15) }}>
										{intl.formatMessage({ id: 'changePassword.passwordError' })}
									</Typography>
								</Box>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid container justifyContent="flex-end" item xs={12} mt={5}>
					<Grid item xs={12} md={2}>
						<LoadingButton
							onClick={handleChangePassword}
							pending={changingPassword || addingPassword}
							variant="contained"
							disabled={
								!formState.isValid ||
								allFields.confirmNewPassword !== allFields.newPassword ||
								allFields.newPassword === allFields.oldPassword
							}
							fullWidth
						>
							{intl.formatMessage({ id: 'save' })}
						</LoadingButton>
					</Grid>
				</Grid>
				{/* <Grid container item mb={4}>
					<Divider style={{ width: '100%' }} />
				</Grid>
				<Grid container item>
					<Box
						sx={{
							width: '100%',
							border: '1px solid',
							borderColor: theme.tag.red,
							px: '25px',
							py: '35px',
							background: alpha(theme.tag.red, 0.1),
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'flex-end',
							borderRadius: 1
						}}
					>
						<Grid container justifyContent="space-between" spacing={2}>
							<Grid item xs={12} lg={9}>
								<Typography variant="h3" fontWeight={500} sx={{ mb: 1.75 }}>
									{intl.formatMessage({
										id: 'accountSettings.security.deleteAccount.title'
									})}
								</Typography>
								<Typography color="secondary">
									{intl.formatMessage({
										id: 'accountSettings.security.deleteAccount.description'
									})}
								</Typography>
							</Grid>
							<Grid
								container
								alignItems="flex-end"
								justifyContent="center"
								item
								xs={12}
								lg={2}
							>
								<LoadingButton
									variant="contained"
									fullWidth
									sx={{ backgroundColor: 'error.main', px: 3 }}
								>
									{intl.formatMessage({ id: 'delete' })}
								</LoadingButton>
							</Grid>
						</Grid>
					</Box>
				</Grid> */}
			</Grid>
		</Paper>
	)
}

export default Security
