import React from 'react'
import {
	Button,
	ButtonProps,
	Dialog,
	DialogProps,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
	Box,
	IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { LoadingButton, LoadingButtonProps } from '@material-ui/lab'
import { useTheme } from 'styled-components'
import { Colors } from 'packages/nuggetai.ui-kit/themes/utils'
import * as S from './Modal.style'

interface FooterProps {
	finishButton?: boolean
	cancelButton?: boolean
}

export type IModalProps = {
	open: boolean
	onClose: () => void
	title?: React.ReactNode
	body?: string
	children?: React.ReactNode
	okText?: string
	cancelText?: string
	onOkDisabled?: boolean
	onOk?: ButtonProps['onClick']
	onCancel?: ButtonProps['onClick']
	/**
	 * @default 'error'
	 */
	buttonStyle?: Colors
	size?: 'normal' | 'big'
	/**
	 * @default true
	 */
	closeOnCancel?: boolean
	/**
	 * @default true
	 */
	closeOnOk?: boolean
	/**
	 * @default false
	 */
	customOnOk?: boolean
	/**
	 * @default false
	 */
	customOnCancel?: boolean
	okLoading?: boolean
	/**
	 * @default true
	 */
	footer?: boolean | FooterProps
	/**
	 * @default 'sm'
	 */
	maxWidth?: DialogProps['maxWidth']
	dialogProps?: Partial<DialogProps>
	buttonProps?: ButtonProps
	okButtonProps?: LoadingButtonProps
	cancelButtonProps?: ButtonProps
	/**
	 * @default false
	 */
	showCloseButton?: boolean
	/**
	 * @default false
	 */
	onlyContent?: boolean
	removeFinishBtnMargin?: boolean
}
// 'body' | 'children'

const notFromLabels = ['primary', 'secondary']

export const Modal = ({
	open,
	onClose,
	title,
	okText = 'Ok',
	cancelText = 'Cancel',
	buttonStyle = 'red',
	size = 'normal',
	onOk,
	onCancel,
	closeOnCancel = true,
	closeOnOk = true,
	body,
	footer = true,
	dialogProps,
	children,
	customOnCancel = false,
	customOnOk = false,
	showCloseButton = false,
	okLoading,
	buttonProps,
	maxWidth = 'sm',
	cancelButtonProps,
	okButtonProps,
	onOkDisabled,
	onlyContent = false,
	removeFinishBtnMargin
}: IModalProps) => {
	const theme = useTheme()
	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth={maxWidth}
			PaperComponent={S.Wrapper}
			PaperProps={{ elevation: 7 }}
			scroll={onlyContent ? 'body' : 'paper'}
			{...dialogProps}
		>
			{!onlyContent && (
				<DialogTitle
					disableTypography
					style={{ paddingTop: showCloseButton ? 30 : undefined }}
				>
					<Box
						sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
					>
						{size === 'big' ? (
							<Typography variant="h4" fontWeight={500}>
								{title}
							</Typography>
						) : (
							<Typography variant="h6">{title}</Typography>
						)}
						{showCloseButton && (
							<IconButton size="small" onClick={onClose}>
								<CloseIcon />
							</IconButton>
						)}
					</Box>
				</DialogTitle>
			)}
			{(children && (
				<S.ModalContent
					size={size}
					onlyContent={onlyContent}
					showCloseButton={showCloseButton}
					className="custom-scrollbar"
				>
					{children}
				</S.ModalContent>
			)) ||
				(body && (
					<DialogContent>
						<DialogContentText variant="h6" color="textPrimary">
							{body}
						</DialogContentText>
					</DialogContent>
				))}
			{footer && !onlyContent && (
				<DialogActions sx={{ mt: removeFinishBtnMargin ? 0 : 3 }}>
					{(typeof footer === 'boolean' || (footer as FooterProps).cancelButton) && (
						<Button
							fullWidth
							onClick={
								onCancel
									? customOnCancel
										? onCancel
										: e => {
												onCancel(e)
												if (closeOnCancel) onClose()
										  }
									: onClose
							}
							disabled={okLoading}
							variant="contained"
							color="secondary"
							{...buttonProps}
							{...cancelButtonProps}
							style={{
								minWidth: 100,
								width: 'fit-content',
								...buttonProps?.style,
								...cancelButtonProps?.style
							}}
						>
							{cancelText}
						</Button>
					)}
					{(typeof footer === 'boolean' || (footer as FooterProps).finishButton) && (
						<LoadingButton
							disabled={onOkDisabled}
							onClick={
								onOk
									? customOnOk
										? onOk
										: e => {
												onOk(e)
												if (closeOnOk) onClose()
										  }
									: onClose
							}
							pending={okLoading}
							fullWidth
							variant="contained"
							{...buttonProps}
							{...okButtonProps}
							color={
								notFromLabels.indexOf(buttonStyle) !== -1
									? (buttonStyle as 'primary' | 'secondary')
									: undefined
							}
							style={{
								backgroundColor:
									notFromLabels.indexOf(buttonStyle) === -1
										? theme.tag[buttonStyle]
										: undefined,
								minWidth: 100,
								width: 'fit-content',
								...buttonProps?.style,
								...okButtonProps?.style
							}}
						>
							{okText}
						</LoadingButton>
					)}
				</DialogActions>
			)}
		</Dialog>
	)
}

export default Modal
