/* eslint-disable indent */
import React from 'react'
import * as yup from 'yup'
import { useTheme } from 'styled-components'
import {
	Autocomplete,
	Box,
	FormControlLabel,
	Grid,
	InputLabel,
	ListSubheader,
	MenuItem,
	Radio,
	RadioGroup,
	TextField,
	Typography
} from '@material-ui/core'
import TextToolTip from 'components/TextToolTip'
import { Info as InfoIcon, LockOutlined as LockIcon } from '@material-ui/icons'
import { Controller, Control } from 'react-hook-form'
import { Link } from 'react-router-dom'

import WizardFooter from 'components/Wizard/WizardFooter'
import { ButtonLink } from 'components/ButtonLink'
import ColorPickerField from 'components/ColorPickerField'
import talentTypes from 'data/groupTypes.json'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'
import { CreationContent } from 'components/CreationContent'
import { useIntl } from 'hooks/useIntl'
import useColorPicker from 'hooks/useColorPicker'
import useToggleAnchor from 'hooks/useToggleAnchor'
import useUserSubscriptions from 'hooks/useUserSubscriptions'
import { ContentWrapper } from '../ContentWrapper'

export interface TeamDetailsFormProps {
	loading?: boolean
	isValid?: boolean
	control?: Control<any>
	currentStep: number
	title: string
	desc?: string
	subTitle?: string
	onContinue: () => void
	onBack: () => void
	onShowHelpGuide?: () => void
}

export const TeamDetailsForm: React.FC<TeamDetailsFormProps> = ({
	loading,
	isValid,
	control,
	currentStep,
	onBack,
	onContinue,
	title,
	desc,
	subTitle,
	onShowHelpGuide
}) => {
	const intl = useIntl()
	const theme = useTheme()
	const [{ anchorEl }, { handleOpenAnchor, handleCloseAnchor }] = useToggleAnchor()
	const [{ hex, rgba }, { handleColorChange }] = useColorPicker()
	const [{ isOnBenchmarkingPlan, isOnHiringPlan, isOnTrial }] = useUserSubscriptions()

	const handleBack = () => {
		onBack()
	}

	const handleContinue = () => {
		onContinue()
	}

	return (
		<>
			<ContentWrapper currentStep={currentStep}>
				<CreationContent title={title} desc={desc} subTitle={subTitle}>
					<Grid container spacing={7}>
						<Grid item xs={12} md={7}>
							<Grid container direction="column" alignItems="stretch">
								<Grid container item spacing={4}>
									<Grid item xs={12}>
										<Controller
											control={control}
											name="team.name"
											render={props => (
												<TextField
													fullWidth
													required
													label={intl.formatMessage({
														id: 'teams.onboarding.teamDetails.teamName'
													})}
													placeholder="ex. design team"
													{...props}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12}>
										<InputLabel>
											{intl.formatMessage({
												id: 'teams.onboarding.teamDetails.maskChallenge'
											})}
											<TextToolTip
												arrow
												title={intl.formatMessage({
													id: 'teams.onboarding.teamDetails.maskChallenge.tooltip'
												})}
												placement="top"
											>
												<InfoIcon
													style={{
														color: '#616161',
														marginLeft: 8,
														verticalAlign: 'middle'
													}}
													fontSize="small"
												/>
											</TextToolTip>
										</InputLabel>
										<Typography color="secondary">
											{intl.formatMessage({
												id: 'teams.onboarding.teamDetails.maskChallenge.description'
											})}
										</Typography>
										<Controller
											control={control}
											name="team.maskChallenge"
											render={({ value, onChange, ...props }) => (
												<RadioGroup
													row
													value={value ? 'yes' : 'no'}
													onChange={(_, value) => onChange(value === 'yes')}
													{...props}
												>
													<Grid container spacing={1}>
														<Grid item>
															<FormControlLabel
																value="yes"
																control={<Radio />}
																label={intl.formatMessage({
																	id: 'yes'
																})}
															/>
														</Grid>
														<Grid item>
															<FormControlLabel
																value="no"
																control={<Radio />}
																label={intl.formatMessage({
																	id: 'no'
																})}
															/>
														</Grid>
													</Grid>
												</RadioGroup>
											)}
										/>
									</Grid>
								</Grid>
								<Grid container item spacing={4} mt={0}>
									<Grid item>
										<Typography variant="h5">
											{intl.formatMessage({
												id: 'teams.onboarding.teamDetails.talentGroupDetails'
											})}
										</Typography>
									</Grid>
									<Grid container item>
										<Grid container spacing={4}>
											<Grid item xs={12}>
												<Controller
													control={control}
													name="talentGroup.name"
													render={props => (
														<TextField
															fullWidth
															required
															label={intl.formatMessage({
																id: 'talentGroupName'
															})}
															placeholder="ex. top performers"
															{...props}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<Controller
													control={control}
													name="team.attempts"
													render={props => (
														<TextField
															type="number"
															fullWidth
															required
															label="Attempts"
															placeholder="No of Attempts"
															{...props}
														/>
													)}
												/>
											</Grid>

											<Grid item xs={12} sm={8}>
												<Controller
													name="talentGroup.type"
													rules={{ required: true }}
													render={props => (
														<TextField
															select
															label={
																// eslint-disable-next-line react/jsx-wrap-multilines
																<>
																	{intl.formatMessage({ id: 'talentType' })}
																	<span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
																		&thinsp;*
																	</span>
																	<TextToolTip
																		arrow
																		title={intl.formatMessage({
																			id:
																				'teams.onboarding.teamDetails.talentGroupDetails.talentType.tooltip'
																		})}
																		placement="top"
																	>
																		<InfoIcon
																			style={{
																				color: '#616161',
																				marginLeft: 8,
																				verticalAlign: 'middle'
																			}}
																			fontSize="small"
																		/>
																	</TextToolTip>
																</>
															}
															{...props}
														>
															{talentTypes.map(type => {
																const isBlocked = isOnTrial
																	? false
																	: isOnBenchmarkingPlan && type.value === 'Internal'
																	? false
																	: !(isOnHiringPlan && type.value === 'External')

																return isBlocked ? (
																	<ListSubheader>
																		<Grid container alignItems="center" spacing={1}>
																			<Grid item sx={{ width: '20px' }}>
																				<LockIcon
																					fontSize="small"
																					sx={{
																						fill: theme.background.summary,
																						ml: -0.8,
																						mt: 2
																					}}
																				/>
																			</Grid>
																			<Grid item xs>
																				{type.label}
																			</Grid>
																			<Grid item>
																				<Link to="/settings/billing">
																					<ButtonLink>
																						{intl.formatMessage({ id: 'activatePlan' })}
																					</ButtonLink>
																				</Link>
																			</Grid>
																		</Grid>
																	</ListSubheader>
																) : (
																	<MenuItem value={type.value} key={type.value}>
																		<Grid container alignItems="center" spacing={1}>
																			<Grid item sx={{ width: '20px' }} />
																			<Grid item>{type.label}</Grid>
																		</Grid>
																	</MenuItem>
																)
															})}
														</TextField>
													)}
												/>
											</Grid>
											<Grid container item xs={6} sm={4} direction="column">
												<Typography
													variant="paragraph1"
													sx={{ marginTop: '2px', marginBottom: '10px' }}
												>
													{intl.formatMessage({
														id:
															'teams.onboarding.teamDetails.talentGroupDetails.talentColor'
													})}
												</Typography>
												<Controller
													name="talentGroup.color"
													render={props => (
														<ColorPickerField
															anchorEl={anchorEl}
															onClick={handleOpenAnchor}
															onClose={handleCloseAnchor}
															hex={hex}
															rgba={rgba}
															onColorChange={handleColorChange}
															onColorChangeComplete={({ hex }) => props.onChange(hex)}
															TextFieldProps={{ ...props }}
															sx={{
																height: 40
															}}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={12}>
												<InputLabel style={{ fontSize: pxToRem(15) }}>
													{intl.formatMessage({
														id:
															'teams.onboarding.teamDetails.talentGroupDetails.inviteTeammates'
													})}
												</InputLabel>
												<Box marginBottom={2}>
													<Typography variant="paragraph1" color="secondary">
														{intl.formatMessage({
															id:
																'teams.onboarding.teamDetails.talentGroupDetails.inviteTeammates.description'
														})}
													</Typography>
												</Box>
												<Controller
													name="talentGroup.teammateEmails"
													render={({ value, onChange, ...props }) => (
														<Autocomplete
															freeSolo
															multiple
															options={[]}
															value={value}
															renderInput={params => (
																<TextField
																	{...params}
																	{...props}
																	placeholder={
																		value.length
																			? undefined
																			: intl.formatMessage({
																					id: 'multiEmailFieldPlaceholder'
																			  })
																	}
																/>
															)}
															onChange={async (_, value) => {
																const valid = await yup
																	.string()
																	.email()
																	.isValid(value[value.length - 1])

																if (!valid) {
																	return
																}

																onChange(value)
															}}
														/>
													)}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</CreationContent>
			</ContentWrapper>
			<WizardFooter
				finish
				onOpenHelpGuide={onShowHelpGuide}
				continueDisabled={isValid}
				continuePending={loading}
				onBack={handleBack}
				onContinue={handleContinue}
			/>
		</>
	)
}

export default TeamDetailsForm
