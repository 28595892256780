import { ButtonBase, Toolbar } from '@material-ui/core'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

export const Container = styled(Toolbar)`
	height: 77px;
	@media (min-width: 600px) {
		&.MuiToolbar-gutters {
			padding: 0 43px;
			@media (max-width: 600px) {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
`

export const Content = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	flex: 1 1 auto;
`

export const RightContent = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`

export const Link = styled(RouterLink)`
	display: flex;
	align-items: center;
	text-decoration: none;
	color: inherit;
	& svg {
		margin-right: 4px;
		width: 20px;
		height: 20px;
	}

	& span {
		position: relative;
		top: 2px;
	}
`
export const Profile = styled(ButtonBase)`
	display: flex;
	border-left: 1px solid ${props => props.theme.grey.fourth};
	padding: 0 20px;
	padding-right: 0;
	margin-left: 10px;
	max-width: 230px;
	align-items: center;
	justify-content: initial;
	height: 100%;
	p {
		font-size: 14px;
		color: ${props => props.theme.grey.main};
	}
`

export const Name = styled.div`
	display: flex;
	align-items: center;
	min-width: 0;
	text-align: initial;
	margin-left: 18px;
	h4 {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	@media only screen and (max-width: 959px) {
		justify-content: center;
		margin-left: 0;
	}
`
