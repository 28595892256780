/* eslint-disable indent */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
	Grid,
	TextField,
	useMediaQuery,
	Theme,
	Autocomplete,
	MenuItem,
	Box,
	Button,
	Typography,
	Paper,
	IconButton,
	Tooltip
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { useTheme } from 'styled-components'
import Modal, { IModalProps } from 'components/Modal'
import { useIntl } from 'hooks/useIntl'
import { GetAssessmentQuery } from 'hooks/useApollo'

import { useStoreon } from 'storeon/react'
import { TeamsContext } from 'screens/Teams/Teams.config'
import EmailUpload from 'components/EmailUpload'
import * as yup from 'yup'
import useUserSubscriptions from 'hooks/useUserSubscriptions'
import ButtonLink from 'components/ButtonLink'
import { useAddTalentForm, AddTalentForm } from './useAddTalentForm'
import iziToast from 'izitoast'
import { LogoUploadContainer } from 'screens/AccountSettings/containers/Company/Company.style'
import { SERVER_URL } from 'constants/config'
import { FileRejection, useDropzone } from 'react-dropzone'
import googleSheetsSvg from 'assets/illustrations/google-sheets-icon.svg'
import ClearIcon from '@material-ui/icons/Clear'
export interface AddTalentModalProps extends Pick<IModalProps, 'open' | 'onClose'> {
	/**
	 * Defines the pipeline list
	 */
	pipelines: GetAssessmentQuery['assessment']['pipelines']
	/**
	 * Function to get link
	 */
	getLink(pipelineId: string): string
	teamId: string
	onInvite: (
		teamId: string,
		pipelineId: string,
		emailsToInvite: string[],
		uploadedUsers?: any[]
	) => Promise<void>
	/**
	 * Defines the loading state of the component
	 * @default false
	 */
	loading?: boolean
	/**
	 * Defines if is inside a demo page
	 */
	demo?: boolean
	/**
	 * Defines the default pipeline id in the options
	 */
	defaultPipeline?: string
}

interface Option {
	label: string
	value: string
	type: string
}

export const AddTalentModal: React.FC<AddTalentModalProps> = ({
	open,
	onClose,
	pipelines,
	getLink,
	onInvite,
	teamId,
	loading,
	demo,
	defaultPipeline: defaultPipelineProp
}) => {
	const theme = useTheme()
	const [uploadedUsers, setUploadedUsers] = useState<any[]>()
	const intl = useIntl()
	const { user } = useStoreon('user')
	const { refetchAssessment, refetchApplicants } = useContext(TeamsContext)
	const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
	const defaultPipeline = useMemo(() => {
		if (!defaultPipelineProp) {
			return undefined
		}
		const pipelineFound = pipelines.find(pipeline => pipeline?.id === defaultPipelineProp)
		if (!pipelineFound) {
			return undefined
		}
		return {
			label: pipelineFound.name,
			value: pipelineFound.id
		}
	}, [pipelines, defaultPipelineProp])
	const [{ isOnBenchmarkingPlan, isOnHiringPlan, isOnTrial }] = useUserSubscriptions()
	const options: Option[] = useMemo(
		() =>
			pipelines.map(
				(pipeline): Option => ({
					label: pipeline?.name as string,
					value: pipeline?.id as string,
					type: pipeline?.type as string
				})
			),
		[pipelines]
	)

	const [
		{ errors, watch, setValue },
		{ onSubmit, handleCopy, validateRecord, renderErrMessage },
		{ Controller }
	] = useAddTalentForm(defaultPipeline)

	const handleSubmit = async (payload: AddTalentForm) => {
		if (teammateEmails.length > 0) {
			await onInvite(
				teamId,
				talentGroup as string,
				payload.teammateEmails as string[],
				uploadedUsers
			)
		}
		if (emailFile) {
			handleUploadEmailFile()
		}
		refetchAssessment()
		refetchApplicants()
	}

	const talentGroup = watch('talentGroup')?.value
	const teammateEmails = [...(watch('teammateEmails')?.values() || [])]

	const [emailFile, setEmailFile] = useState<File | null>(null)

	const link = talentGroup ? getLink(talentGroup) : ''
	const error = !!errors?.talentGroup?.message
	// @ts-ignore
	const teammateError = errors && errors?.teammateEmails?.length > 0

	const jwt = JSON.parse(localStorage.getItem('storeon') || '')

	const onDrop = useCallback(([file]: File[], [error]: FileRejection[]) => {
		if (file) {
			setEmailFile(file)
		}

		error?.errors.forEach(({ code }) => {
			if (code === 'file-invalid-type') {
				return iziToast.error({
					message: 'File type must be csv.'
				})
			}

			if (code === 'file-too-large') {
				return iziToast.error({
					message: 'File size must not be larger than 2MB.'
				})
			}
		})
		// eslint-disable-next-line
	}, [])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: ['.csv', '.xlsx'],
		maxSize: 2000000, // 2mb
		multiple: false
	})

	const { onClick: onBrowseFileClick, ...logoUploadContainerProps } = getRootProps()

	const handleUploadEmailFile = async () => {
		if (emailFile === null) return
		const url = `${SERVER_URL}/api/v1/pipeline/challengeInviteUploadFile`
		const formData = new FormData()
		formData.append('userId', user!?.id)
		formData.append('assessId', teamId || '')
		formData.append('pipelineId', talentGroup || '')
		formData.append('file', emailFile || '')

		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: `${jwt?.token}`
				},
				body: formData
			})

			if (response.ok) {
				const result = await response.json()
				if (result.success === true && teammateEmails.length <= 0) {
					iziToast.success({
						message: intl.formatMessage({ id: 'addTalent.toastSucess' })
					})
					onClose()
				}
			} else {
				console.error('File upload failed:', response.statusText)
			}
		} catch (error) {
			console.error('Error uploading file:', error)
		}
	}

	const [modalFinishButtonDisabled, setModalFinishButtonDisabled] = useState(true)

	const handleModalFinishButtonDisabled = () => {
		setModalFinishButtonDisabled(
			!Boolean(emailFile) &&
				Boolean(
					!talentGroup || (teammateEmails.length <= 0 && (uploadedUsers || []).length <= 0)
				)
		)
	}

	useEffect(() => {
		handleModalFinishButtonDisabled()
	}, [emailFile, talentGroup, teammateEmails, uploadedUsers])

	return (
		<Modal
			open={open}
			onClose={onClose}
			buttonStyle="primary"
			okText={intl.formatMessage({ id: 'finish' })}
			onOk={onSubmit(handleSubmit)}
			okLoading={loading}
			size={mdDown ? 'normal' : 'big'}
			maxWidth="xs"
			title={intl.formatMessage({ id: 'addTalent' })}
			closeOnOk={false}
			onOkDisabled={modalFinishButtonDisabled}
			showCloseButton
			footer={{ cancelButton: false, finishButton: true }}
			removeFinishBtnMargin={true}
		>
			<Grid container spacing={4}>
				<Grid item container>
					<Controller
						name="talentGroup"
						render={({ value, onChange }) => (
							<Autocomplete
								renderInput={params => (
									<TextField
										{...params}
										label={intl.formatMessage({ id: 'addTalent.placeholder' })}
										error={error}
										helperText={error && errors?.talentGroup?.message}
									/>
								)}
								fullWidth
								options={options}
								getOptionLabel={option => option.label}
								renderOption={(props, option) => {
									const isBlocked = demo
										? false
										: isOnTrial
										? false
										: // @ts-ignore
										isOnBenchmarkingPlan && option.type === 'Internal'
										? false
										: // @ts-ignore
										  !(isOnHiringPlan && option.type === 'External')

									return isBlocked ? (
										<MenuItem disableRipple>
											<Grid container alignItems="center" spacing={1}>
												<Grid item sx={{ width: '20px' }}>
													<LockOutlinedIcon
														fontSize="small"
														sx={{
															fill: theme.background.summary,
															ml: -0.8,
															mt: 0.8
														}}
													/>
												</Grid>
												<Grid item xs>
													{option.label}
												</Grid>
												<Grid item>
													<Link to="/settings/billing">
														<ButtonLink>
															{intl.formatMessage({ id: 'activatePlan' })}
														</ButtonLink>
													</Link>
												</Grid>
											</Grid>
										</MenuItem>
									) : (
										<MenuItem {...props}>
											<Grid container alignItems="center" spacing={1}>
												<Grid item sx={{ width: '20px' }} />
												<Grid item>{option.label}</Grid>
											</Grid>
										</MenuItem>
									)
								}}
								value={value}
								onChange={(_e, newValue) => onChange(newValue)}
							/>
						)}
					/>
				</Grid>
				{talentGroup && (
					<EmailUpload
						disabledLink={demo}
						handleClearUploadedUsers={() => setUploadedUsers(undefined)}
						uploadedUsers={uploadedUsers}
						handleUploadUsers={users => {
							setValue('teammateEmails', [])
							setUploadedUsers(users)
						}}
						challengeLink={link}
						handleCopy={() => handleCopy(link)}
						pipelines={pipelines}
						talentGroup={talentGroup}
						user={user}
						validateRecord={validateRecord}
						controllerChildren={
							<Controller
								name="teammateEmails"
								render={({ value, onChange, ...props }) => (
									<>
										<Autocomplete
											freeSolo
											multiple
											options={[]}
											value={value}
											disabled={!!uploadedUsers}
											onChange={async (_, newValue) => {
												const valid = await yup
													.string()
													.email()
													.isValid(newValue[newValue.length - 1])

												if (!valid) {
													return
												}

												onChange(newValue)
											}}
											renderInput={params => (
												<TextField
													{...params}
													{...props}
													placeholder={
														value?.length
															? undefined
															: intl.formatMessage({
																	id: 'multiEmailFieldPlaceholder'
															  })
													}
													error={teammateError}
													helperText={
														teammateError
															? renderErrMessage()
															: intl.formatMessage({
																	id: 'addTalent.helperText'
															  })
													}
												/>
											)}
										/>
										<Box my={1}>
											{emailFile ? (
												<Grid item container>
													<Grid item>
														<Box>
															<Paper
																sx={{
																	padding: '2px 16px',
																	boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 1px',
																	display: 'flex',
																	alignItems: 'center',
																	gap: 1
																}}
																variant="outlined"
															>
																<Typography
																	sx={{
																		display: 'flex',
																		alignItems: 'center',
																		fontSize: '14px',
																		gap: 1
																	}}
																>
																	<img src={googleSheetsSvg} style={{ height: 14 }} />
																	{emailFile?.name || 'FileName.csv'}
																</Typography>

																<Tooltip title="Remove file">
																	<IconButton
																		sx={{
																			padding: '6px'
																		}}
																		onClick={() => {
																			setEmailFile(null)
																		}}
																	>
																		<ClearIcon sx={{ fontSize: '18px' }} />
																	</IconButton>
																</Tooltip>
															</Paper>
														</Box>
													</Grid>
												</Grid>
											) : (
												<>
													<Grid item xs={12}>
														<LogoUploadContainer
															{...logoUploadContainerProps}
															active={isDragActive}
														>
															<Grid
																container
																direction="column"
																justifyContent="center"
																alignItems="center"
																spacing={2}
															>
																{isDragActive ? (
																	<Grid item>
																		<Typography fontWeight={500}>
																			{intl.formatMessage({
																				id: 'companySettings.dropYourFiles'
																			})}
																			!
																		</Typography>
																	</Grid>
																) : (
																	<>
																		<Grid item>
																			<Typography fontWeight={500}>
																				{intl.formatMessage({
																					id: 'invite.challenge.uploadEmailFileDropZone'
																				})}
																			</Typography>
																		</Grid>
																		<Grid item>
																			<Typography fontWeight={500}>
																				{intl
																					.formatMessage({
																						id: 'or'
																					})
																					.toUpperCase()}
																			</Typography>
																		</Grid>
																		<Grid item>
																			<Button
																				type="button"
																				variant="outlined"
																				size="small"
																				color="inherit"
																				onClick={onBrowseFileClick}
																			>
																				{intl.formatMessage({ id: 'browseFiles' })}
																			</Button>
																		</Grid>
																	</>
																)}
															</Grid>
														</LogoUploadContainer>
													</Grid>
													<input {...getInputProps()} />
												</>
											)}
										</Box>
										<Typography
											sx={{
												fontSize: '13px',
												color: '#f44336'
											}}
										>
											Allowed file formats: .csv, .xlsx
										</Typography>
									</>
								)}
							/>
						}
					/>
				)}
			</Grid>
		</Modal>
	)
}

export default AddTalentModal
