import React, { useContext, useEffect, useRef } from 'react'
import { Grid, Divider, Hidden, Button } from '@material-ui/core'

import { SwiperSlide } from 'swiper/react'
import useIntl from 'hooks/useIntl'
import Tabs from 'components/Tabs'
import { ArrowBack as BackIcon } from '@material-ui/icons'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'
import { useTeamContext, TeamsContext, TeamContextProps } from 'screens/Teams/Teams.config'
import TalentFilters from '../TalentFilters'
import TalentProfile from '../TalentProfile'
import TalentSearch from '../TalentSearch'
import TalentDynamic from '../../TeamDynamic'
import TalentDynamicDrawer from '../TalentDynamicDrawer'
import { StyledSwiper, TalentSectionContainer } from './TalentSection.styles'
import { useTalentContext, unselectTalent } from '../context'

export interface TalentSectionProps {}

const teamSelector = (store: TeamContextProps) => store.teamId

export const TalentSection = () => {
	const handleNextSlide = useRef<() => void>(() => {})
	const handlePrevSlide = useRef<() => void>(() => {})
	const teamId = useTeamContext(teamSelector)
	const handleRefresh = useRef<() => void>(() => {})
	const [{ selectedTalent }, dispatch] = useTalentContext()
	const { applicantsLoading: loading } = useContext(TeamsContext)
	const intl = useIntl()

	useEffect(() => {
		if (handlePrevSlide.current) {
			handlePrevSlide.current()
		}
	}, [])

	useEffect(() => {
		if (selectedTalent.id && handleNextSlide.current) {
			handleNextSlide.current()
			handleRefresh.current()
		}
	}, [selectedTalent.id])

	useEffect(() => {
		if (handleRefresh.current && !loading) {
			handleRefresh.current()
		}
	}, [loading])

	return (
		<Grid container spacing={5}>
			{/* TalentFilters */}
			<Grid item xs={12}>
				<TalentFilters teamId={teamId} />
			</Grid>
			{/* TalentSearch */}
			<Hidden mdUp>
				<Grid item xs={12}>
					<StyledSwiper
						preventInteractionOnTransition
						slidesPerView={1}
						onSlideChange={swiper => {
							if (swiper.isBeginning) {
								dispatch(unselectTalent())
							}
						}}
						onBeforeDestroy={() => {
							handleNextSlide.current = () => {}
							handlePrevSlide.current = () => {}
							handleRefresh.current = () => {}
						}}
						onSwiper={swiper => {
							if (swiper?.slideNext && swiper?.slidePrev) {
								handleNextSlide.current = () => swiper.slideNext()
								handlePrevSlide.current = () => swiper.slidePrev()
								handleRefresh.current = () => swiper.updateAutoHeight()
							}
						}}
						allowTouchMove={false}
					>
						<SwiperSlide
							style={{
								display: 'flex',
								justifyContent: 'center',
								width: 'undefined'
							}}
						>
							<TalentSearch />
						</SwiperSlide>
						<SwiperSlide
							style={{
								display: 'flex',
								justifyContent: 'center',
								width: 'undefined'
							}}
						>
							<Grid container>
								<Grid item>
									<Button
										name="backTo"
										onClick={handlePrevSlide.current}
										startIcon={<BackIcon style={{ fontSize: pxToRem(14) }} />}
										disableFocusRipple={false}
										color="secondary"
										style={{ fontSize: pxToRem(14) }}
									>
										{intl.formatMessage({ id: 'back' })}
									</Button>
								</Grid>
								<Grid item xs={12} style={{ height: '100%', width: '100%' }}>
									<Tabs
										tabs={[
											{
												label: intl.formatMessage({ id: 'profile' }),
												component: <TalentProfile />
											},
											{
												label: intl.formatMessage({ id: 'teams.talentDynamic' }),
												component: <TalentDynamic />
											}
										]}
										variant="fullWidth"
										centered
									/>
								</Grid>
							</Grid>
						</SwiperSlide>
					</StyledSwiper>
				</Grid>
			</Hidden>
			<Hidden mdDown>
				<Grid item xs={12}>
					<TalentSectionContainer container wrap="nowrap">
						<Grid item xs={12} md={5} lg={4}>
							<TalentSearch />
						</Grid>
						<Divider orientation="vertical" />
						{/* TalentProfile */}
						<Grid item sx={{ position: 'relative' }} xs={12} md>
							<TalentProfile />
							<TalentDynamicDrawer>
								<TalentDynamic />
							</TalentDynamicDrawer>
						</Grid>
					</TalentSectionContainer>
				</Grid>
			</Hidden>
		</Grid>
	)
}

export default TalentSection
