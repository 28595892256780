import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTypedController } from '@hookform/strictly-typed'
import Modal, { IModalProps } from 'components/Modal'
import { Grid, TextField, useMediaQuery, Theme } from '@material-ui/core'
import useIntl from 'hooks/useIntl'

export interface EditAttemptsProps extends Pick<IModalProps, 'open' | 'onClose'> {
	/**
	 * Defines the unique id of the item to be edited
	 */
	id: string
	/**
	 * Defines the current value of the field to be edited
	 */
	loading?: boolean
	/**
	 * Defines the field to be edited
	 */
	field: string
	/**
	 * Callback fired when the finish button is clicked
	 */
	onFinish: (id: string, field: string, attempts: number) => void

	attempts: number
}

export interface EditForm {
	[key: string]: number
}

export const EditAttempts: React.FC<EditAttemptsProps> = ({
	id,
	open,
	onClose,
	attempts,
	field,
	onFinish,
	loading = false
}) => {
	const intl = useIntl()
	const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
	const [localLoading, setLocalLoading] = useState(false)

	const {
		control,
		errors,
		handleSubmit: onSubmit,
		formState,
		setValue
	} = useForm<EditForm>({
		reValidateMode: 'onChange',
		defaultValues: {
			[field]: attempts
		}
	})

	const Controller = useTypedController<EditForm>({ control })

	const handleSubmit = async (payload: EditForm) => {
		console.log('Submitting payload:', payload)
		const attemptsValue = payload[field]
		if (attemptsValue !== undefined && !isNaN(attemptsValue)) {
			try {
				setLocalLoading(true)
				console.log(`Attempting to update with: ${attemptsValue}`)
				await onFinish(id, field, attemptsValue)
				setTimeout(() => {
					onClose()
					setLocalLoading(false)
				}, 1000)
			} catch (error) {
				console.error('Error updating attempts:', error)
				setLocalLoading(false)
			}
		} else {
			console.error(`Invalid attempts value: ${attemptsValue}`)
		}
	}
	console.log(attempts, 'chandu')
	return (
		<Modal
			open={open}
			buttonStyle="primary"
			okText={intl.formatMessage({ id: 'finish' })}
			onClose={onClose}
			size={mdDown ? 'normal' : 'big'}
			onOk={onSubmit(handleSubmit)}
			closeOnOk={false}
			maxWidth="xs"
			onOkDisabled={!formState.isDirty && !formState.isValid}
			okLoading={localLoading || loading}
			title="Edit Attempts"
		>
			<form onSubmit={onSubmit(handleSubmit)}>
				<Grid container spacing={4}>
					<Grid item container>
						<Controller
							name={field}
							render={props => (
								<TextField
									fullWidth
									autoFocus
									type="number"
									label="Attempts"
									InputLabelProps={{
										style: { textTransform: 'capitalize' }
									}}
									error={!!errors?.[field]?.message} // Access dynamic error message
									helperText={errors?.[field]?.message} // Access dynamic helper text
									required
									{...props}
									onChange={e => {
										const value = parseInt(e.target.value, 10)
										props.onChange(isNaN(value) ? 0 : value) // Ensure attempts is stored as a number
										setValue(field, isNaN(value) ? 0 : value) // Set the value in the form state
									}}
								/>
							)}
						/>
					</Grid>
				</Grid>
			</form>
		</Modal>
	)
}

export default EditAttempts
