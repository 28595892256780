import { LocaleProvider, LocaleProviderProps } from 'packages/nuggetai.ui-kit/locales/base'
import {
	ThemeProvider,
	ThemeProviderProps
} from 'packages/nuggetai.ui-kit/themes/base/theme-provider'
import React from 'react'

export interface NuggetProviderProps
	extends Omit<ThemeProviderProps, 'children'>,
		Omit<LocaleProviderProps, 'children'> {
	/**
	 * react children prop
	 */
	children?: React.ReactNode
}

export const NuggetProvider = ({
	defaultLocale,
	locale,
	mode,
	children
}: NuggetProviderProps) => {
	return (
		<ThemeProvider mode={mode}>
			<LocaleProvider defaultLocale={defaultLocale} locale={locale}>
				{children}
			</LocaleProvider>
		</ThemeProvider>
	)
}

export default NuggetProvider
