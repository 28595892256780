import React, { useEffect } from 'react'
import useIntl from 'hooks/useIntl'
import { Grid, Typography, Button, Divider } from '@material-ui/core'
import { getNonNullKeys } from 'utils/object'
import { transformType } from 'utils'
import { FlatfileButton } from '@flatfile/react'
import { AnalyticsUser, GetAssessmentQuery } from 'hooks/useApollo'
import { IDataHookResponse } from '@flatfile/adapter/build/main/obj.validation-response'
import ButtonLink from 'components/ButtonLink'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const handleArrayFields = values =>
	values.map(([item, index]) => {
		let value = item
		let isArray = false
		try {
			const jsValue = JSON.parse(item)
			isArray = Array.isArray(jsValue)
			if (isArray) {
				value = jsValue
			}
		} catch {}
		return [{ value: isArray ? value.join(';') : value }, index]
		// return [{ value: item }, index]
	})

interface FormattedData {
	first_name: string
	last_name: string
	customFields: {
		[k: string]: any
	}
	skills: string[]
	certifications: string[]
	industries: string[]
	jobTypes: string[]
	oExperiences: string[]
	languages: string[]
	role_interests: string[]
}

interface UploadProps {
	user: AnalyticsUser | null
	uploadedUsers: FormattedData[] | undefined
	handleClearUploadedUsers: () => void
	handleUploadUsers: (users: FormattedData[]) => void
	validateRecord?: (data, talentGroup, pipelines) => IDataHookResponse
	/**
	 * Defines the pipeline list
	 */
	pipelines?: GetAssessmentQuery['assessment']['pipelines']
	talentGroup?: string
	handleCopy: () => void
	controllerChildren: React.ReactNode
	/**
	 * Defines if the copy link should be disabled
	 */
	disabledLink?: boolean
	challengeLink?: string
}

export const EmailUpload = ({
	user,
	uploadedUsers,
	handleClearUploadedUsers,
	handleUploadUsers,
	validateRecord,
	pipelines,
	talentGroup,
	handleCopy,
	controllerChildren,
	disabledLink,
	challengeLink
}: UploadProps) => {
	const intl = useIntl()

	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			handleClearUploadedUsers()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Grid container item spacing={3}>
			<Grid container item spacing={3}>
				<Grid item>
					<Typography fontWeight={500}>
						{intl.formatMessage({ id: 'invite.challenge' })}
					</Typography>
				</Grid>
				<Grid container item justifyContent="space-between" alignItems="center" spacing={1}>
					<Grid item xs>
						<Typography variant="paragraph1">
							{intl.formatMessage({ id: 'email' })}
						</Typography>
					</Grid>
					<Grid container item xs justifyContent="flex-end">
						<CopyToClipboard text={challengeLink || ''}>
							<ButtonLink
								disabled={disabledLink}
								onClick={e => {
									e.preventDefault()
								}}
								style={{ fontSize: pxToRem(14), marginBottom: '1.5px' }}
							>
								{intl.formatMessage({ id: 'copyLink' })}
							</ButtonLink>
						</CopyToClipboard>
					</Grid>
					<Grid item xs={12}>
						{controllerChildren}
					</Grid>
				</Grid>
			</Grid>
			{/* <Grid item xs={12}>
				<Divider orientation="horizontal" variant="fullWidth">
					{intl.formatMessage({ id: 'or' })}
				</Divider>
			</Grid>
			<Grid container item spacing={1}>
				
			</Grid> */}
		</Grid>
	)
}

export default EmailUpload
{
	/* {uploadedUsers ? (
						<Grid container item xs={12} alignItems="center" spacing={2}>
							<Grid item>
								<Typography variant="paragraph2">
									{intl.formatMessage(
										{ id: 'addTalent.uploadCount' },
										{ count: uploadedUsers.length }
									)}
								</Typography>
							</Grid>
							<Grid item>
								<ButtonLink onClick={handleClearUploadedUsers}>
									{intl.formatMessage({ id: 'addTalent.remove' })}
								</ButtonLink>
							</Grid>
						</Grid>
					) : null} */
}
