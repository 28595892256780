import styled from 'styled-components'
import {
	Box,
	Button,
	ToggleButton,
	ToggleButtonGroup,
	TextField,
	Divider
} from '@material-ui/core'

interface ButtonProps {
	active?: boolean
}

interface ToggleButtonProps {
	active?: boolean
}

export const ListWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px;
`

export const ListTitleIcon = styled(Box)`
	display: flex;
	align-items: center;
	gap: 8px;
`

export const StyledBox = styled(Box)`
	display: flex;
	flex-direction: column;
	padding: 16px;
	width: 100%;
	margin-right: auto;
	justify-content: flex-start;
`

export const ButtonGroupBox = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 16px;
	@media (min-width: 600px) {
		flex-direction: row;
	}
`

export const ButtonContainer = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	@media (min-width: 600px) {
		flex-direction: row;
		gap: 16px;
	}
`

export const StyledButton = styled(Button)<ButtonProps>`
	border-color: lightgrey;
	border-width: 2px;
	border-style: solid;
	margin-right: 0;
	width: 100%;

	@media (min-width: 600px) {
		width: auto;
		margin-right: 8px;
	}
	&:focus {
		outline: none;
	}
`

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
	background-color: ${({ theme }) => (theme.mode === 'dark' ? '#222222' : '#efeef0')};
	display: flex;
	flex-direction: row;

	@media (min-width: 600px) {
		flex-direction: row;
		gap: 0;
		width: auto;
	}
`

export const StyledToggleButton = styled(ToggleButton)<ToggleButtonProps>`
	flex: 1;
	margin: 0;
	border: none;
	border-radius: 5px;
	color: ${({ active }) => (active ? 'white' : 'inherit')};
	padding: 8px 10px;
	min-width: auto;
	width: 100%;

	@media (min-width: 600px) {
		width: auto;
	}
`

export const RecentActivityBox = styled(Box)`
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	@media (min-width: 600px) {
		flex-direction: row;
	}
`

export const DividerStyled = styled(Divider)`
	width: 100%;
	margin: 7px 0;
`

export const StyledTextField = styled(TextField)`
	width: 100%;
	margin: 7px 0;
`
