/* eslint-disable indent */
import React, { useEffect, useRef, useState } from 'react'
import { format } from 'date-fns'
import { Grid, useMediaQuery, Theme } from '@material-ui/core'
import { useStoreon } from 'storeon/react'
import { useNavigate } from 'react-router-dom'
import iziToast from 'izitoast'
import { Alert } from 'components/Alert'
import useIntl from 'hooks/useIntl'
import { useAccountStore } from 'hooks/useAccountStore'
import {
	UpdateUserInput,
	useResendEmailVerificationMutation,
	useUpdateUserMutation,
	useUpdateSubscriptionCancellationStatusMutation
} from 'hooks/useApollo'
import useUserSubscriptions from 'hooks/useUserSubscriptions'

export interface AlertStackProps {}

export const AlertStack: React.FC<AlertStackProps> = () => {
	const intl = useIntl()
	const { dispatch, user: _user } = useStoreon('user')
	const { loadingUser } = useStoreon('loadingUser')
	const user = _user as NonNullable<typeof _user>
	const [{ isOnBenchmarkingPlan, isOnHiringPlan, subscriptions }] = useUserSubscriptions()
	const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

	const navigate = useNavigate()

	const [updateUser] = useUpdateUserMutation()
	const [updateStatus] = useUpdateSubscriptionCancellationStatusMutation()
	const [resendEmailVerification, setResendEmailVerification] = useState(false)

	const handleCloseCancellationAlert = (id: string) => {
		if (!user) return

		const index = user.subscriptions.findIndex(sub => sub.id === id)

		if (index === -1 || user.subscriptions[index].isCancellationAlertAcknowledged) return

		const newCopy = [...user.subscriptions]
		newCopy[index] = { ...newCopy[index], isCancellationAlertAcknowledged: true }

		dispatch('user/setUser', { ...user, subscriptions: newCopy })
		updateStatus({
			variables: {
				id,
				input: {
					isCancellationAlertAcknowledged: true,
					isCancellationMessageAcknowledged:
						newCopy[index].isCancellationMessageAcknowledged
				}
			}
		})
	}

	const handleCloseTrialAlert = (
		key: keyof Pick<
			UpdateUserInput,
			'isFreeTrialAlertAcknowledged' | 'isFreeTrialDaysAlertAcknowledged'
		>
	) => {
		if (!user?.[key]) {
			dispatch('user/setUser', { ...user, [key]: true })
		}
		updateUser({ variables: { userInput: { [key]: true } } })
	}

	const { emailVerified, setEmailVerified } = useAccountStore(state => ({
		emailVerified: state.emailVerified,
		setEmailVerified: state.setEmailVerification
	}))

	useEffect(() => {
		if (!user?.isEmailVerified) {
			setEmailVerified(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (emailVerified) {
			return () => {
				setEmailVerified(null)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [resend] = useResendEmailVerificationMutation({
		onCompleted: () => {
			setResendEmailVerification(true)
			iziToast.success({
				message: intl.formatMessage({
					id: 'mailVerification.resendMessage'
				})
			})
		},
		onError: () => {
			dispatch('user/setUser', { ...user, isEmailVerified: false })
			setEmailVerified(false)
		}
	})

	const socketRef = useRef<WebSocket | null>(null)
	const [emailVerifiedSocketResponse, setEmailVerifiedSocketResponse] = useState(false)

	useEffect(() => {
		if (emailVerified === false) {
			try {
				socketRef.current = new WebSocket(process.env.REACT_APP_API_WEBSOCKET_URL as string)
				socketRef.current.onopen = () => {
					if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
						socketRef.current.send(
							JSON.stringify({ type: 'isVerified', email: user?.email })
						)
					}
				}

				socketRef.current.onmessage = event => {
					setEmailVerifiedSocketResponse(true)
					setEmailVerified(true)
					dispatch('user/setUser', { isEmailVerified: true })
					iziToast.success({
						message: intl.formatMessage({
							id: 'mailVerification.verified'
						})
					})
				}

				return () => {
					if (socketRef.current) {
						socketRef.current.close()
					}
				}
			} catch {}
		}
	})

	return (
		<Grid
			container
			direction="column"
			spacing={1}
			sx={{ mb: 5, width: '100%', marginLeft: 0 }}
		>
			{/* email verification alert */}
			{loadingUser ? null : (
				<>
					<Alert
						color="primary"
						onClick={resend}
						condition={(emailVerified && user.isEmailVerified) ?? true}
						onClose={() => setEmailVerified(null)}
						open={
							((!(emailVerified as boolean) && !user?.isEmailVerified) ||
								((emailVerified as boolean) && user?.isEmailVerified)) ??
							false
						}
						actionLabel={intl.formatMessage({
							id: !smDown
								? 'mailVerification.resendButton'
								: 'mailVerification.resendButtonMobile'
						})}
						label={intl.formatMessage({
							id:
								user.isEmailVerified === false && resendEmailVerification === true
									? 'mailVerification.emailSent'
									: !((emailVerified && user.isEmailVerified) ?? true)
									? 'mailVerification.verify'
									: 'mailVerification.verified'
						})}
					/>
					{/* trial expired alert */}
					<Alert
						onClose={() => handleCloseTrialAlert('isFreeTrialAlertAcknowledged')}
						closeable
						color="danger"
						onClick={() => navigate('/settings/billing')}
						open={
							!user.isFreeTrialAlertAcknowledged &&
							!(isOnBenchmarkingPlan || isOnHiringPlan) &&
							!!user.trialDetails?.hasExpired
						}
						actionLabel={intl.formatMessage({
							id: 'activatePlan'
						})}
						label={intl.formatMessage({
							id: 'alert.trial.expired'
						})}
					/>
					{/* trial remaining days alert */}
					<Alert
						onClose={() => handleCloseTrialAlert('isFreeTrialDaysAlertAcknowledged')}
						closeable
						color="reminder"
						onClick={() => navigate('/settings/billing')}
						actionLabel={intl.formatMessage({
							id: 'activatePlan'
						})}
						open={
							!user.isFreeTrialDaysAlertAcknowledged &&
							!(isOnBenchmarkingPlan || isOnHiringPlan) &&
							!user.trialDetails?.hasExpired
						}
						label={
							user.trialDetails?.daysRemaining === 0 && !user.trialDetails?.hasExpired
								? intl.formatMessage({
										id: 'alert.trial.endToday'
								  })
								: intl.formatMessage(
										{
											id: 'alert.remaining.trial.days'
										},
										{ value: user.trialDetails?.daysRemaining || 0 }
								  )
						}
					/>
					{/* plan canceled alert */}
					{Object.keys(subscriptions).map((key, index) => {
						return (
							<Alert
								key={index}
								onClose={() => handleCloseCancellationAlert(subscriptions[key].id)}
								closeable
								color="reminder"
								open={
									subscriptions[key].isCanceled &&
									!subscriptions[key].isCancellationAlertAcknowledged
								}
								actionLabel={intl.formatMessage({
									id: 'activatePlan'
								})}
								label={intl.formatMessage(
									{
										id: `alert.${key}.plan.canceled`
									},
									{
										plan: subscriptions[key].title,
										date: format(new Date(subscriptions[key].endsAt), 'MMMM dd, yyyy')
									}
								)}
							/>
						)
					})}
					{/* payment failed alert */}
					{Object.keys(subscriptions).map((key, index) => (
						<Alert
							key={index}
							closeable
							color="danger"
							open={subscriptions[key].isPaymentFailed}
							actionLabel={intl.formatMessage({
								id: 'activatePlan'
							})}
							label={intl.formatMessage(
								{
									id: 'alert.plan.failed'
								},
								{
									plan: subscriptions[key].title
								}
							)}
						/>
					))}
				</>
			)}
		</Grid>
	)
}

export default AlertStack
